import React, { useMemo } from 'react';
import { KeyboardLayout } from './data/keyboard_layouts';
import { Key } from './data/keys';
import { DEFAULT_KEYSET_MAP, KeysetMap } from './data/keyset_map';
import { Layout } from './data/layouts';
import { Override } from './data/overrides';
import { LayoutRenderer } from './layout_renderer';
import { KeycapWithPosition } from './data/types';

// this is also inefficient...meh
function getLayoutWithOverridesApplied(layout: Layout, overrides: Array<Override>): {derivedLayout: Layout, derivedKeysetMap: KeysetMap, } {
    const derivedLayout: Layout = [];
    const derivedKeysetMap: KeysetMap = new Map(DEFAULT_KEYSET_MAP);
    const keysToRemove: Set<Key> = new Set();

    for (const override of overrides) {
        if (override.layoutOverride) {
            for (const key of override.layoutOverride.remove) {
                keysToRemove.add(key);
            }
        }
        if (override.keysetOverride) {
            override.keysetOverride.forEach((keycap, key) => {
                derivedKeysetMap.set(key, keycap);
            });
        }
    }

    // copy over current layout 
    for (const layoutKey of layout) {
        if (!keysToRemove.has(layoutKey.key)) {
            derivedLayout.push(layoutKey);
        } 
    }

    // add extras
    for (const override of overrides) {
        if (override.layoutOverride) {
            for (const layoutKey of override.layoutOverride.add) {
                derivedLayout.push(layoutKey);
            }
        }
    }
    
    return {
        derivedLayout,
        derivedKeysetMap,
    }
}

type KeyboardLayoutRendererProps = {
    keyboardLayout: KeyboardLayout,
    overrides: Array<Override>;
}

export function KeyboardLayoutRenderer({keyboardLayout, overrides}: KeyboardLayoutRendererProps) {
    // useMemo technically not needed at this point
    const keycapWithPositions: Array<KeycapWithPosition> = useMemo(() => {
        const {derivedLayout, derivedKeysetMap} = getLayoutWithOverridesApplied(keyboardLayout.defaultLayout, overrides);
        return derivedLayout.map(layoutKey => {
            return {
                keycap: derivedKeysetMap.get(layoutKey.key)!,
                x: layoutKey.x,
                y: layoutKey.y,
                rotation: layoutKey.rotation,
            };
        })
    }, [keyboardLayout, overrides]);

    const backgroundImageUrl = `/keyboard_bg/${keyboardLayout.name.toLowerCase().replaceAll('.', '').replaceAll('%', '')}.png`;

    return (
        <div className="greyWrapper" style={{overflow: 'auto'}}>
            <div className="keyboardLayoutWrapper" style={{backgroundImage: `url(${backgroundImageUrl})`}}>
                <LayoutRenderer keycapWithPositions={keycapWithPositions} />
            </div>
        </div>
    );
};