;

// remember to update keycap_data when you add/change things here
export enum Keycap {
    /* Alphas */
    BACKTICK,
    ALPHA_1, ALPHA_2, ALPHA_3, ALPHA_4, ALPHA_5,
    ALPHA_6, ALPHA_7, ALPHA_8, ALPHA_9, ALPHA_0,
    ALPHA_DASH, ALPHA_EQUALS,
    Q, W, E, R, T, Y, U, I, O, P, LEFT_BRACKET, RIGHT_BRACKET, PIPE_B1_150,
    A, S, D, F, G, H, J, K, L, SEMICOLON, SINGLE_QUOTE, 
    Z, X, C, V, B, N, M, COMMA, PERIOD, SLASH,

    N_TILDE,

    F_HOMING_BARRED, J_HOMING_BARRED, H_HOMING_BARRED, U_HOMING_BARRED, N_HOMING_BARRED, T_HOMING_BARRED, A_HOMING_BARRED,
    F_HOMING_SCOOPED, J_HOMING_SCOOPED, H_HOMING_SCOOPED, U_HOMING_SCOOPED, N_HOMING_SCOOPED, T_HOMING_SCOOPED, A_HOMING_SCOOPED,

    /* Dvorak alphas */
    LEFT_BRACKET_Y, RIGHT_BRACKET_Y,
    ALPHA_DASH_W, ALPHA_EQUALS_W,

    /* Alpha coloured numrow */
    BACKTICK_W,
    ALPHA_1_W, ALPHA_2_W, ALPHA_3_W, ALPHA_4_W, ALPHA_5_W,
    ALPHA_6_W, ALPHA_7_W, ALPHA_8_W, ALPHA_9_W, ALPHA_0_W,
    TEXT_BACKSPACE_B1,
    SCISSORS_B1_200,

    /* Arrows */
    ARROW_UP_B3,
    ARROW_UP_B4,
    // TODO: append _B4 here
    ARROW_LEFT,
    ARROW_DOWN,
    ARROW_RIGHT,

    /* Accent Arrows */
    ARROW_UP_Y,
    ARROW_LEFT_Y,
    ARROW_DOWN_Y,
    ARROW_RIGHT_Y,

    /* Cookies of all shapes and sizes */
    COOKIE_SWIRLY_Y,
    COOKIE_RECTANGLE_Y,
    COOKIE_PRETZEL_Y,
    COOKIE_CIRCLE_Y,
    COOKIE_SWIRLY_B1,
    COOKIE_RECTANGLE_B1,
    COOKIE_PRETZEL_B1,
    COOKIE_CIRCLE_B1,
    COOKIE_SWIRLY_B2,
    COOKIE_RECTANGLE_B2,
    COOKIE_PRETZEL_B2,
    COOKIE_CIRCLE_B2,
    COOKIE_SWIRLY_B3,
    COOKIE_RECTANGLE_B3,
    COOKIE_PRETZEL_B3,
    COOKIE_CIRCLE_B3,
    COOKIE_SWIRLY_B4_100,
    COOKIE_RECTANGLE_B4_100,
    COOKIE_PRETZEL_B4_100,
    COOKIE_CIRCLE_B4_100,
    COOKIE_SWIRLY_B4_125,
    COOKIE_RECTANGLE_B4_125,
    COOKIE_PRETZEL_B4_125,
    COOKIE_CIRCLE_B4_125,
    COOKIE_SWIRLY_B4_150,
    COOKIE_PRETZEL_B4_150,
    COOKIE_CIRCLE_B4_150,

    /* Buttons */
    BUTTON_CIRCLE_Y,
    BUTTON_FLOWER_Y,
    BUTTON_HEART_Y,
    BUTTON_CIRCLE_B1,
    BUTTON_FLOWER_B1,
    BUTTON_HEART_B1,
    BUTTON_CIRCLE_B2,
    BUTTON_FLOWER_B2,
    BUTTON_HEART_B2,
    BUTTON_CIRCLE_B3,
    BUTTON_FLOWER_B3,
    BUTTON_HEART_B3,
    BUTTON_CIRCLE_B4,
    BUTTON_FLOWER_B4,
    BUTTON_HEART_B4,

    /* TKL Text mods */
    TEXT_ESC_B1, TEXT_ESC_Y,
    TEXT_F1, TEXT_F2, TEXT_F3, TEXT_F4,
    TEXT_F5, TEXT_F6, TEXT_F7, TEXT_F8,
    TEXT_F9, TEXT_F10, TEXT_F11, TEXT_F12, TEXT_F13,

    TEXT_PRT_SC, TEXT_SCROLL, TEXT_PAUSE, 
    TEXT_INSERT_B2, TEXT_HOME_B2, TEXT_PG_UP_B2,
    TEXT_DELETE_B2, TEXT_END_B2, TEXT_PG_DN_B2, 

    TEXT_BACKSPACE, TEXT_TAB, TEXT_COOKIE, TEXT_COOKIE_STEPPED, TEXT_ENTER, TEXT_SHIFT_225, TEXT_SHIFT_275,
    TEXT_CONTROL_125, TEXT_SEW_125, TEXT_ALT_125, TEXT_MENU_125,

    /* Other text mods */ 
    TEXT_SHIFT_125, TEXT_SHIFT_175, TEXT_FN_B3, 
    TEXT_CONTROL_150, TEXT_SEW_150, TEXT_SEW_100, TEXT_ALT_150, TEXT_ALT_100,
    TEXT_CONTROL_100, TEXT_FN_B4,
    TEXT_SHIFT_200,

    TEXT_HOME_Y1, TEXT_DELETE_Y1, TEXT_DELETE_B1, TEXT_END_B1, TEXT_PG_UP_B1, TEXT_END_B3, TEXT_PG_DN_B3,

    /* TKL Novelty mods */
    TIN_B1_100,
    TIN_Y_100,
    SCISSORS_Y_200,
    BOBBIN_B1_150,
    PIN_B2_175, 
    TOMATO_B2_175_STEPPED,
    NEEDLE_AND_THREAD_B2_225,
    STITCH_B3_225,
    STITCH_B3_275,
    STITCH_B3_200,

    /* Numpad */
    TEXT_NUM_LOCK, DIVIDE, MULTIPLY, NUMPAD_MINUS_Y,
    NUMPAD_7, NUMPAD_8, NUMPAD_9, NUMPAD_PLUS_200, NUMPAD_MINUS_B1,
    NUMPAD_4, NUMPAD_5, NUMPAD_6, 
    NUMPAD_1, NUMPAD_2, NUMPAD_3, TEXT_NUMPAD_ENTER,
    NUMPAD_0_200, NUMPAD_PERIOD,
    NUMPAD_0_100, NUMPAD_00, NUMPAD_PLUS_100, 
    NUMPAD_5_HOMING_BARRED,
    NUMPAD_5_HOMING_SCOOPED,

    TOMATO_Y,
    TAPE_B3_200,

    TEXT_NUM_LOCK_ALT, DIVIDE_ALT, MULTIPLY_ALT,

    /* ISO */
    TEXT_ISO_ENTER,
    NEEDLE_ISO_ENTER,
    ISO_ANGLE_BRACKET,
    UK_ISO_BACKTICK,
    UK_ISO_2,
    UK_ISO_3,
    UK_ISO_POUND,
    UK_ISO_SINGLE_QUOTE,
    UK_ISO_BACKTICK_W,
    UK_ISO_2_W,
    UK_ISO_3_W,

    /* Tsangan */
    PIPE_Y,
    TEXT_BACK,

    /* Ergo/Ortho */
    TIN_Y_150,
    // TIN_Y_100,
    // BOBBIN_B1_150,
    PIN_B1_150,
    STORK_B2_150,
    PIN_B2_150,
    STITCH_B3_150,
    STITCH_B3_100,
    BOBBIN_B3_100,

    STORK_B4_150,
    PIN_B4_150,
    STITCH_B4_150,

    STITCH_A_B4_200,
    STITCH_B_B4_200,
    STITCH_C_B4_200,
    STITCH_D_B4_200,

    BLANK_B4_200,

    ZIGZAG_B3_200,

    /* Spacebars */
    SPACEBAR_100,
    SPACEBAR_125,
    SPACEBAR_150,
    SPACEBAR_175,
    SPACEBAR_200,
    SPACEBAR_225,
    SPACEBAR_275,
    SPACEBAR_300,
    SPACEBAR_600,
    SPACEBAR_625,
    SPACEBAR_700,
    // TODO: rename ALT to W
    SPACEBAR_100_ALT,
    SPACEBAR_125_ALT,
    SPACEBAR_150_ALT,
    SPACEBAR_175_ALT,
    SPACEBAR_200_ALT,
    SPACEBAR_225_ALT,
    SPACEBAR_275_ALT,
    SPACEBAR_300_ALT,
    SPACEBAR_600_ALT,
    SPACEBAR_625_ALT,
    SPACEBAR_700_ALT,
    SPACEBAR_150_Y,
    SPACEBAR_200_Y,
    SPACEBAR_225_Y,
    SPACEBAR_275_Y,
    SPACEBAR_625_Y,
    SPACEBAR_700_Y,

    /* 40s */
    // TIN_Y_100,
    TOMATO_B1,
    STORK_Y_150,
    STORK_Y_175,
    STORK_B1_150,
    STORK_B1_175,
    BOBBIN_B2_125,
    TOMATO_B2_125, 
    // PIN_B2_150,
    // PIN_B2_175,
    // STITCH_B3_100,
    STITCH_B3_125, // TODO: place under iso?
    // STITCH_B3_150,
    STITCH_B3_175, // TODO: place under tsangan?   
    ZIGZAG_B4_175,

    PIPE_W_150,
    PIPE_W,

    NORDE_U_UMLAUT,
    NORDE_A_RING,
    NORDE_ETH,
    NORDE_O_TILDE,
    NORDE_O_UMLAUT,
    NORDE_A_UMLAUT,
    NORDE_AESH,
    NORDE_O_SLASH,
    NORDE_THORN,
    NORDE_3,
    NORDE_4,
    NORDE_6,
    NORDE_7,
    NORDE_8,
    NORDE_9,
    NORDE_0,
    NORDE_CARAT,
    NORDE_ESZETT,
    NORDE_ACUTE_ACCENT,
    NORDE_HALF,
    NORDE_PLUS_QUESTION,
    NORDE_PIPE,
    NORDE_BACKSLASH,
    NORDE_PILCROW,
    NORDE_OVERRING,
    NORDE_CARON,
    NORDE_PLUS_ASTERISK,
    NORDE_UMLAUT,
    NORDE_SINGLE_QUOTE_QUESTION,
    NORDE_POUND,
    NORDE_SINGLE_QUOTE_ASTERISK,
    NORDE_BACKTICK,
    NORDE_COMMA,
    NORDE_PERIOD,

    NORDE_3_Y,
    NORDE_4_Y,
    NORDE_6_Y,
    NORDE_7_Y,
    NORDE_8_Y,
    NORDE_9_Y,
    NORDE_0_Y,
    NORDE_CARAT_Y,
    NORDE_ESZETT_Y,
    NORDE_ACUTE_ACCENT_Y,
    NORDE_HALF_Y,
    NORDE_PLUS_QUESTION_Y,
    NORDE_PIPE_Y,
    NORDE_BACKSLASH_Y,
    NORDE_PILCROW_Y,
    NORDE_OVERRING_Y,
    NORDE_CARON_Y,
    NORDE_O_UMLAUT_Y,
};


// all stitches: 100, 125, 150, 175, 225, 275 
// 100/125 same, 150/175 same, 225/275 same?