import React from 'react';
import {Tailor} from './tailor/tailor';
import './App.css';
import './shared.css';
import { Header, Link, ColourWarning } from './shared';
import { KitsSection } from './kits_section';
import { KeycapPreview } from './keycap_preview';
import { RendersSection } from './renders_section';
import { CollaborationsSection, CABLE_LINK, BOX_LINK } from './collaborations_section';
// import { ExportKitRenderer } from './tailor/export_kit_renderer';

const IC_FORM_LINK = 'https://forms.gle/V39KKPk1PD8R4pAb7';

type NavItemProps = {
  name: string;
  url?: string;
}
function NavItem({name, url}: NavItemProps) {
  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" className="navLink">{name}</a>
    );
  }
  return (
    <a href={`#${name}`} className="navLink">{name}</a>
  );
}

function NavBar() {
  return (
    <div id="navSection">
      <div id="navSectionInner">
        <NavItem name="about" />
        <NavItem name="vendors" />
        <NavItem name="renders" />
        <NavItem name="tailor" />
        <NavItem name="kits" />
        <NavItem name="collaborations" />
        <NavItem name="updates" />
        <NavItem name="links" />
        <NavItem name="credits" />
        {/* <NavItem name="interest check form ➚" url={IC_FORM_LINK} /> */}
      </div>
    </div>
  );
}

function AboutSection() {
  return (
    <React.Fragment>
      <Header name="about" />
        <div className="halfArea subArea subAreaPaddingRight">
          <p className="emphasis mb1">
            A tin of butter cookies! You lift the lid, but there are no cookies to be found: only pins, needles, and thread...
            </p>

          <p className="mb1">
            KAM Sewing Tin is a keycap set inspired by cookie tins filled with sewing supplies. It's packed with sewing-themed novelties, plus plenty of cookies! 
          </p>

          <p className="mb1">
            The set has a blue gradient to reflect the shininess of the tin, a delicious cookie-coloured numrow, and neutral white alphas.
          </p>

          {/* <p>
            Interested? Fill out the <Link url={IC_FORM_LINK}>interest check form</Link> to help make it happen!
          </p> */}

          <p className="mb1">
            Group buy ran from November 9th to December 9th, 2021. <Link url="https://geekhack.org/index.php?topic=115240.msg3164615#msg3164615">Keycap re-make</Link> completed August 10th, 2023. See <a href="#updates">here</a> for all updates.
          </p>

        </div>
        <div className="halfArea subArea subAreaPaddingLeft">
          <KeycapPreview /> 
          {/* <p className="mb1">
            <b>Colours</b>: 7510 U, 2004 U, 2371 U, 2126 U, 2728 U, 2718 U
          </p> */}
          <p className="mb1">
            <b>Profile</b>: KAM - a uniform profile similar to DSA
          </p>
          <p className="mb1">
            <b>Material</b>: Dye-sublimated PBT with matte finish
          </p>
          <p className="mb1">
            <b>Manufacturer</b>: Keyreative
          </p>
        </div>
    </React.Fragment>
  );
}

type VendorProps = {
  name: string;
  url: string;
  region: string;
}

function Vendor({name, url, region}: VendorProps) {
  const vendorLink = url!! ? <Link url={url}>{name}</Link> : name;
  return (
    <div className="vendor">
      <div className="region">{region}</div>
      <div className="vendorName">{vendorLink}</div>
    </div>
    );
}

const VENDOR_LIST = [
  {name: 'Vala Supply', url: 'https://vala.supply/products/kam-sewing-tin', region: 'US'},
  {name: 'Ashkeebs', url: 'https://www.ashkeebs.com/product/kam-sewing-tin-keycaps/', region: 'CA'},
  {name: 'en.zFrontier', url: 'https://en.zfrontier.com/products/in-stock-kam-sewing-tin', region: 'INTL'},
  {name: 'zFrontier', url: 'https://www.zfrontier.com/app/mch/lMz8dPAnQ08o', region: 'CN'},
  {name: 'mykeyboard.eu', url: 'https://mykeyboard.eu/catalogue/category/group-buys/keycaps/kam-sewing-tin_620/', region: 'EU'},
  {name: 'Proto[Typist]', url: 'https://prototypist.net/collections/in-stock/products/in-stock-kam-sewing-tin-ketset', region: 'UK'},
  {name: 'SwitchKeys', url: 'https://www.switchkeys.com.au/products/kam-sewing-tin-group-buy/', region: 'OCE'},
  {name: 'Zion Studios', url: 'https://zionstudios.ph/product/groupbuy-kam-sewing-tin/', region: 'SEA'},
]

function VendorsSection() {
  return (
    <React.Fragment>
      <Header name="vendors" />
      <p className="mb2">
        In-stock extras are currently available at <Link url="https://en.zfrontier.com/products/in-stock-kam-sewing-tin">en.zFrontier</Link> (INTL), <Link url="https://www.zfrontier.com/app/mch/lMz8dPAnQ08o">zFrontier</Link> (CN), <Link url="https://vala.supply/products/kam-sewing-tin">Vala Supply</Link> (US), <Link url="https://prototypist.net/collections/in-stock/products/in-stock-kam-sewing-tin-ketset">Proto[Typist]</Link> (UK), and <Link url="https://www.ashkeebs.com/product/kam-sewing-tin-keycaps/">Ashkeebs</Link> (CA).
      </p>
      {/* <p className="mb2">
        From <strong>November 9th to December 9th, 2021</strong>, you can join the Sewing Tin group buy with the following vendors:
      </p> */}
      {/* <p className="mb2">
        Extra pre-orders are now live at <Link url="https://vala.supply/products/kam-sewing-tin">Vala Supply</Link> - other vendors will have extras available later, dates TBD.
      </p> */}
      <div className="vendorList">
        {VENDOR_LIST.map(vendorInfo => <Vendor {...vendorInfo} />)}
      </div>
      {/* <p className="mt2">
        Matching cables are available at <Link url={CABLE_LINK}>Cookie Cables</Link>, and the Sewing Tin artisan box is available at <Link url={BOX_LINK}>P3D Store</Link>.
      </p> */}
      </React.Fragment>
  );

}

function TailorSection() {
  return (
    <React.Fragment>
      <Header name="tailor" />
      <p className="mb2">
        Sewing Tin supports a wide range of keyboard layouts and different looks: the Tailor is here to help you explore them! Best viewed on desktop.
      </p>
      <ColourWarning />
      <Tailor />
      </React.Fragment>
  );
}

// TOOD: make url no-optional after adding GH link
function BoxLink({text, url}: {text: string, url?: string}) {
  return (
    <div className="boxLinkWrapper">
      <div className="boxLink"><Link url={url ?? "#"}>{text} ➚</Link></div>
    </div>
  );
}

function Update({date, children}: {date: string, children: React.ReactNode}) {
  return (
    <div className="update mb1">
      <div className="updateDate">{date}</div>
      <div className="updateContent">{children}</div>
    </div>
  )
}

function UpdatesSection() {
  return (
    <React.Fragment>
      <Header name="updates" />
      <Update date="2024-05-26">
        <p>Vala Supply - Extras are live</p>
      </Update>
      <Update date="2023-11-18">
        <p>Ashkeebs - GB orders have been fulfilled and extras are live</p>
        <p>Zion Studios - GB orders fulfilled, but some orders are missing B-stock kits/gifts - please contact support@zionstudios.ph with your order number for replacements if you are missing anything</p>
      </Update>
      <Update date="2023-10-29">
        <p>zFrontier - A-stock replacements for all original orders have been shipped, and A-stock extras are now live <Link url="https://en.zfrontier.com/products/in-stock-kam-sewing-tin">here</Link></p>
        <p>Proto[Typist] - ortho fix kits received and shipped to all affected customers</p>
        <p>mykeyboard.eu - keycaps being air-shipped alongside other outstanding sets from zFrontier, estimated to arrive with them in November</p>
        <p>Vala Supply - GB/preorders finished fulfilment September 22nd</p>
        <p>Ashkeebs - Container with B-stock keycaps received, fulfillment should start in the coming weeks</p>
        <p>SwitchKeys - Ortho fix kits should have been sent out to affected customers. If you ordered from SwitchKeys & are still missing any kits or the gifts (including the A-stock ortho fix kit) please contact support@switchkeys.com.au with your order number and a list of what is missing from your order</p>
     </Update>
      <Update date="2023-09-19">
        Extras are live at <Link url="https://prototypist.net/collections/live-group-buys/products/group-buy-kam-sewing-tin">Proto[Typist]</Link>. Fulfillment is underway for Vala Supply and zFrontier.
     </Update>
      <Update date="2023-09-06">
        SwitchKeys customers, please see <Link url="https://geekhack.org/index.php?topic=115240.msg3171134#msg3171134">this notice</Link> regarding issues with fulfilled GB orders. 
     </Update>
      <Update date="2023-09-02">
      Fulfillment continues, replacements for ortho zigzag keys (only remaining issue in re-made kits) on route to vendors. Full update <Link url="https://geekhack.org/index.php?topic=115240.msg3170823#msg3170823">here</Link>. 
      Have also made a guide on the differences between A vs B stock <Link url="https://www.instagram.com/p/CwtPnHUPcWq/?igshid=MWZjMTM2ODFkZg%3D%3D">here</Link>.
      </Update>
      <Update date="2023-08-26">
        Re-made keycaps arrived with US, UK, AU vendors. UK GB orders fulfilled.
      </Update>
      <Update date="2023-08-17">
        Re-made keycaps in transit to vendors. US, CA, UK, AU shipments are being sent by air and are expected to arrive in 7-12 days.
      </Update>
      <Update date="2023-08-10">
        Production of re-made keycaps is complete! Keycaps are now being packed to be sent to vendors.
      </Update>
      <Update date="2023-07-27">
        Re-make production continues and is now estimated to complete August 10th. GB/preorder thank-you gifts are complete and either with or on their way to vendors.
      </Update>
      <Update date="2023-07-06">
        R4 samples received and approve ahead of the re-make, which is still scheduled to complete July 30th. Please see the <Link url="https://geekhack.org/index.php?topic=115240.msg3166335#msg3166335">GeekHack post</Link> for more details.
      </Update>
      <Update date="2023-06-30">
        B-stock sets arrived at Vala Supply, and will be shipped to those who opted in to early shipping late next week or the week after. Asero artisan complete and shipped to vendors.
      </Update>
      <Update date="2023-06-16">
        Due to some minor but widespread issues, the keycaps are being completely re-made at no additional cost to customers. This is estimated to complete July 30th. Most customers will receive both the existing and re-made versions. Please see <Link url="https://geekhack.org/index.php?topic=115240.msg3164615#msg3164615">here</Link> for the full details/update.
      </Update>
      <Update date="2023-05-30">
        <p>Keycaps are shipping to vendors (either in transit from China, or awaiting shipment at vendor's forwarders in China). Only vendor who still needs to make arrangements is MKEU, am attempting to contact them to figure this out. </p>
        <p>Asero artisan collab is awaiting cerakote colour mix/match-ing.</p>
      </Update>
      <Update date="2023-05-05">
        <p>Keycap production complete! Orders from zFrontier have shipped. Keycaps will be shipped to other vendors soon (expected to take 1-2 months depending on vendor)</p>
        <p><Link url="https://discord.gg/gXrkuxg4gj">Discord server</Link> has been opened! It has a gallery channel for folks to share photos of their Sewing Tin builds, would love to see yours there!</p>
        <p>Asero artisan collab is awaiting cerakote and laser-engraving, and is expected to be complete this month)</p>
      </Update>
      <Update date="2023-04-02">
        Updated timeline from Keyreative - keyset is now estimated to finish production end of April / early May.
      </Update>
      <Update date="2023-03-31">
        Keyset production update - production of reverse dye-sub keys has started. 
      </Update>
      <Update date="2023-03-21">
        Due to custom delays with reverse dyesub materials, the set is now estimated to complete production at the end of May 2023.
      </Update>
      <Update date="2023-03-18">
        Keyset production update - injection moulding & regular dye-sub complete, reverse dye-sub up next. 
      </Update>
      <Update date="2023-02-22">
        Keyset in production! Estimated to complete April 2023. 
      </Update>
      <Update date="2023-02-18">
        Packaging files submitted to zFrontier.
      </Update>
      <Update date="2022-12-28">
        <p>Keycap production files submitted to zFrontier, R2 colours approved</p>
        <p>Deskmats shipped to all vendors, arrived at some</p>
      </Update>
      <Update date="2022-10-23">
        Photos of the R3 samples can be found <Link url="https://imgur.com/a/H5teB72">here</Link>. Detailed thoughts on the samples can be found in the <Link url="https://geekhack.org/index.php?topic=115240.msg3143666#msg3143666">GeekHack thread</Link>.
      </Update>
      <Update date="2022-10-16">
        <p>Keyset - R3 samples received, photos/details to come</p>
        <p>Deskmats - Production to complete within the next week, estimated 40 days shipping to the US</p>
        <p>THOK encoder knobs - Arrived at all vendors, standalone orders being fulfilled</p>
        <p>Asero Foundry metal artisans - Unfinished keycaps due to arrive with Asero in 2 weeks, no ETA on completion (ceratoke/laser)</p>
      </Update>
      <Update date="2022-09-02">
        Due to a delay with the vendor placing the order, deskmats are now estimated to arrive with vendors in November.
      </Update>
      <Update date="2022-08-13">
        <p>Keyset - R2 samples received, colours and legend alignment much improved!</p>
        <p>Deskmats - R2 samples received, now in production & estimated to arrive at US vendor in October.</p>
        <p>THOK encoder knobs - shipped to vendors.</p>
        <p>Asero Foundry metal artisans - In production, estimated completion late August / early September.</p>
        <p>Click <Link url="https://imgur.com/a/DP93Viy">here</Link> for an Imgur album with photos of the deskmat and keyset samples. Full thoughts on the samples can be found in the <Link url="https://geekhack.org/index.php?topic=115240.msg3136689#msg3136689">GeekHack thread</Link>.</p>
      </Update>
      <Update date="2022-06-11">
        <p>Deskmat samples received. Cookie Parade & Sewing Tools - Blue going for a 2nd round of samples for tone adjustments. Sewing Tools - Yellow approved.</p>
        <p>THOK encoder knobs being shipped to THOK, shipping to vendors/customers expected to start in the next few weeks</p>
      </Update>
      <Update date="2022-04-17">
      <p>New estimated shipping date is Q1 2023, based on Keyreative's latest estimated timeline.</p>
      <p>Deskmats - Samples being shipped to lead vendor, ETA May 2022. Will be shipped to me afterwards.</p>
      <p>THOK encoder knobs - Awaiting shipment. Delayed from previous estimate, now estimated to ship from THOK in May 2022.</p>
      <p>Asero Foundry metal artisans - In production, estimated to complete by July 2022 at latest.</p>
      <p><s>Extra pre-orders are now live at Vala Supply.</s> <s>As of March 2023 we advise against pre-ordering from Vala Supply at this time due to <Link url="https://geekhack.org/index.php?topic=115116.msg3157906#msg3157906">fulfillment delays with other sets</Link> & communication issues with designers and customers.</s> As of May 2023 these issues have been resolved.</p>
      </Update>
      <Update date="2022-03-15">
        Latest update from zFrontier / Keyreative: due to recent COVID outbreaks, production has slowed down. No estimate for when the R2 samples will be complete. Deskmat samples should be completed in the next few weeks, and manufacturing should start soon for the Asero artisan.
      </Update>
      <Update date="2022-01-05">
        Keyreative have released a <Link url="https://en.zfrontier.com/blogs/project-updates/keyreative-keyset-progress-report">progress report</Link> on their production queue - Sewing Tin is now estimated to enter production in October. 
      </Update>
      <Update date="2021-12-30">
        All artisan box orders have shipped from P3D Store. 
      </Update>
      <Update date="2021-12-27">
        Sales numbers finalised - everything will be made! GB sales reached 141% of MOQ, 327% with vendor extras included. Full sales numbers can be seen in these <Link url="https://imgur.com/a/C9YsnET">graphs by Dvorcol</Link>. 
      </Update>
      <Update date="2021-12-18">
        Artisan box files have been sent out to those who requested them. 
      </Update>
      <Update date="2021-12-10">
        Group buy complete! Awaiting final numbers from vendors. All keycap kits, deskmats and THOK knobs will be made: Asero artisan is TBD.
      </Update>
    </React.Fragment>
  );
}

function LinksSection() {
  return (
    <React.Fragment>
      <Header name="links" />
      <div className="mb2">
        <div className="thirdArea subArea subAreaPaddingRightPartial subAreaPaddingLeftPartial">
          <BoxLink text="GeekHack thread" url="https://geekhack.org/index.php?topic=112929" />
        </div>
        <div className="thirdArea subArea subAreaPaddingRight">
          <BoxLink text="Instagram" url="https://instagram.com/minicap.cat" />
        </div>
        <div className="thirdArea subArea subAreaPaddingLeft">
          <BoxLink text="Discord server" url="https://discord.gg/gXrkuxg4gj" />
        </div>
      </div>
      <div className="mt2">
      </div>
    </React.Fragment>
  );
}

function CreditsSection() {
  return (
    <React.Fragment>
      <Header name="credits" />
      <p className="mb1">
        Thank you for reading this far! KAM Sewing Tin is my first keyset design, and I had a lot of help to getting it to this point. 
      </p>
      <p className="mb1">
        It's a cliche, but the set really wouldn't have made it this far without you all: seriously, huge thank yous to: 
      </p>
      <ul className="creditsList">
        <li>
          the lovely <Link url="https://discord.gg/9UraJskJSd">Keycraft Collective</Link> community and members for the support, and for giving this set a space to grow: 
          there's too many amazing people to name everyone, special thanks to Rosie, Andromache, pwade and pluma for the help and advice
        </li>
        <li>
          KAMrades Therese and Hakata for the advice and summoning circles, and Purry for bringing us together 
        </li>
        <li>
          <Link url="https://www.instagram.com/craftedbyannie/">Mr. CBA & @craftedbyannie</Link> for the lovely artisan box photos
        </li>
        <li>
          <Link url="https://lucastafuri.artstation.com">Ltafuri</Link> for the sweet U80-A, ErgoDox, Nemui, Austin and Absinthe renders
        </li>
        <li>
          <Link url="https://twitter.com/minimoonbun">minimoonbun</Link> for the super cute banner and favicon, advice, proofreading, help acquiring sample keycaps, and all the other help and support along the way 🥺💙
        </li>
        <li>
          ImperfectLink and <Link url="https://gumroad.com/l/KeyboardRenderKit/">Keyboard Render Kit</Link> for the fantastic rendering kit, and for the THOK brass texture 
        </li>
        <li>
          DrHigsby for all the help with the 40s kit
        </li>
        <li>
          acitrin, Mirubere, NoPunIn10Did, matt, hali, and honorless for the kitting advice
        </li>
        <li>
          everyone who filled out the IC, i read every response and your comments warmed my heart 💙
        </li>
      </ul>
    </React.Fragment>
  );
}

function Divider() {
  return <div className="divider" />;
}

function App() {
  // return (
  //   <Tailor />
  // );
  // return (
  //   <ExportKitRenderer />
  // )

  return (
    <div>
      <div id="topWrapper"><a id="top"></a></div>
      <h1 className="bigHeader">kam sewing tin</h1>
      <div id="main"> 
        <NavBar />
        <div className="innerSection">
          <AboutSection />
          <Divider />
          <VendorsSection />
          <Divider />
          <RendersSection />
          <Divider />
          <TailorSection />
          <Divider />
          <KitsSection />
          <Divider />
          <CollaborationsSection />
          <Divider />
          <UpdatesSection />
          <Divider />
          <LinksSection />
          <Divider />
          <CreditsSection />
          <div id="topOfPage" className="mt2"><a href="#top">ꜛGo to top of page</a></div>
        </div>
      </div>
      <div id="madeWithCookies">
        <div id="madeWithCookiesInner">
          made with 
          <img src="/keycap_images/cookie_circle.png" height={32} width={32} alt='circular cookie' />
          by minicap
        </div>
        <div id="footerContact">
          hi@kamsewingtin.com
        </div>
      </div>
    </div>
  );
}

export default App;
