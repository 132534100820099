import {Keycap} from './keyset';

export enum KeycapColourNames {
    WHITE,
    YELLOW,
    BLUE_1,
    BLUE_2,
    BLUE_3,
    BLUE_4,
};

export type KeycapColour = {
    base: string;
    shadow: string;
    legend?: string;
};

export const KeycapColourInfo: Map<KeycapColourNames, KeycapColour> = new Map([
    [KeycapColourNames.WHITE, {base: '#FFFFFF', shadow: '#F5F5F5', legend: '#2E3395'}],
    // these colours need tweaking
    // [KeycapColourNames.YELLOW, {base: '#EFBE7D', shadow: '#D5A25F', legend: '#B17849'}],
    // [KeycapColourNames.YELLOW, {base: '#F3CF8E', shadow: '#EFBE7D', legend: '#B17849'}],
    // 2004
    // [KeycapColourNames.YELLOW, {base: '#FBDB8C', shadow: '#F1CF88', legend: '#B17849'}],
    // 2005
    // [KeycapColourNames.YELLOW, {base: '#FBD38C', shadow: '#F4C986', legend: '#B17849'}],
    // New 2004
    [KeycapColourNames.YELLOW, {base: '#FBDB8C', shadow: '#F2CE83', legend: '#C6915E'}],
    // [KeycapColourNames.BLUE_1, {base: '#382893', shadow: '#2D2189'}],
    // [KeycapColourNames.BLUE_2, {base: '#2D3DB1', shadow: '#2438AB'}],
    // [KeycapColourNames.BLUE_3, {base: '#3742E4', shadow: '#1937CC'}],
    // [KeycapColourNames.BLUE_4, {base: '#617CFF', shadow: '#426BE5'}],
    [KeycapColourNames.BLUE_1, {base: '#2D2076', shadow: '#241963'}],
    // Older, slightly darker
    // [KeycapColourNames.BLUE_2, {base: '#2F328E', shadow: '#272A7D'}],
    [KeycapColourNames.BLUE_2, {base: '#2E3395', shadow: '#242989'}],
    [KeycapColourNames.BLUE_3, {base: '#3049BA', shadow: '#273FA9'}],
    [KeycapColourNames.BLUE_4, {base: '#446AD5', shadow: '#385ECA'}],
]);

enum KeycapImageNames {
    /* novelties */
    COOKIE_SWIRLY,
    COOKIE_RECTANGLE,
    COOKIE_PRETZEL,
    COOKIE_CIRCLE,
    BUTTON_CIRCLE,
    BUTTON_HEART,
    BUTTON_FLOWER,
    TIN,
    TOMATO,
    BOBBIN,
    SCISSORS,
    TAPE,
    // TODO: replacement 2u needle and thread? argh
    NEEDLE_AND_THREAD_225,
    STORK,
    PIN,
    STITCH_SMALL,
    STITCH_MEDIUM,
    STITCH_LARGE,

    /* alphas */
    BACKTICK,
    ALPHA_1,
    ALPHA_2,
    ALPHA_3,
    ALPHA_4,
    ALPHA_5,
    ALPHA_6,
    ALPHA_7,
    ALPHA_8,
    ALPHA_9,
    ALPHA_0,
    PIPE,
    ALPHA_DASH,
    ALPHA_EQUALS,
    Q,
    W,
    E,
    R,
    T,
    Y,
    U,
    I,
    O,
    P,
    LEFT_BRACKET,
    RIGHT_BRACKET,
    A,
    S,
    D,
    F,
    G,
    H,
    J,
    K,
    L,
    SEMICOLON,
    SINGLE_QUOTE,
    Z,
    X,
    C,
    V,
    B,
    N,
    M,
    COMMA,
    PERIOD,
    SLASH,

    N_TILDE,

    /* iso alphas */
    ISO_BACKTICK,
    ISO_2,
    ISO_3,
    ISO_SINGLE_QUOTE,
    ISO_POUND,
    ISO_NEEDLE,
    ISO_ANGLE_BRACKET,

    /* arrow (rely on rotation?) */
    UP_ARROW,

    /* text mods */
    BACKSPACE,
    BACK, // HHKB
    ESC,
    TAB,
    TEXT_COOKIE,
    ENTER,
    ENTER_ISO,
    SHIFT,
    CTRL,
    SEW,
    ALT,
    MENU, 
    F1,
    F2,
    F3,
    F4,
    F5,
    F6,
    F7,
    F8,
    F9,
    F10,
    F11,
    F12,
    F13,
    FN,
    PRINT,
    SCROLL,
    BREAK,
    INS,
    HOME,
    UP,
    DEL,
    END,
    DOWN,

    /* numpad */
    NUMPAD_1,
    NUMPAD_2,
    NUMPAD_3,
    NUMPAD_4,
    NUMPAD_5,
    NUMPAD_6,
    NUMPAD_7,
    NUMPAD_8,
    NUMPAD_9,
    NUMPAD_0,
    NUM,
    DIVIDE,
    MULTIPLY,
    NUMPAD_MINUS,
    NUMPAD_PLUS,
    NUMPAD_PERIOD,
    NUMPAD_00,

    /* ergo/ortho thumb keys */
    // TODO: replace? cross stitch not being used
    CROSS_STITCH,
    ZIGZAG,

    // TODO: actually have isConvex instead of this hack for spacebar identification/concave blanks
    BLANK,

    /* norde */
    NORDE_U_UMLAUT,
    NORDE_A_RING,
    NORDE_ETH,
    NORDE_O_TILDE,
    NORDE_O_UMLAUT,
    NORDE_A_UMLAUT,
    NORDE_AESH,
    NORDE_O_SLASH,
    NORDE_THORN,
    NORDE_3,
    NORDE_4,
    NORDE_6,
    NORDE_7,
    NORDE_8,
    NORDE_9,
    NORDE_0,
    NORDE_CARAT,
    NORDE_ESZETT,
    NORDE_ACUTE_ACCENT,
    NORDE_HALF,
    NORDE_PLUS_QUESTION,
    NORDE_PIPE,
    NORDE_BACKSLASH,
    NORDE_PILCROW,
    NORDE_OVERRING,
    NORDE_CARON,
    NORDE_PLUS_ASTERISK,
    NORDE_UMLAUT,
    NORDE_SINGLE_QUOTE_QUESTION,
    NORDE_POUND,
    NORDE_SINGLE_QUOTE_ASTERISK,
    NORDE_BACKTICK,
    NORDE_COMMA,
    NORDE_PERIOD,
}

export enum HomingTypes {
    BARRED,
    SCOOPED,
}

export type KeycapData = {
    color: KeycapColourNames;
    imageName?: KeycapImageNames;
    // default = 1u if not specified, for brevity
    size?: 1.25 | 1.5 | 1.75 | 2 | 2.25 | 2.75 | 3 | 6 | 6.25 | 7;
    // for stepped capslock
    topSize?: 1.25;
    // clockwise? default = 0 if not specified
    // note: separate to LayoutKey rotation, this is image rotation (confusingly!!!)
    imageRotation?: 90 | 180 | 270;
    isIsoEnter?: true, // should only be set when size is not set
    homingType?: HomingTypes, // should only be set when size is not set
};

export function getKeycapImageUrl(keycapImageName: KeycapImageNames) {
    return `/keycap_images/${KeycapImageNames[keycapImageName].toLowerCase()}.png`;
}

export function preloadAllImages() {
    // hack, not sure how you're supposed to do this
    for (const enumItem of Object.keys(KeycapImageNames)) {
        const maybeNumber = Number(enumItem);
        if (!Number.isNaN(maybeNumber)) {
            // console.log('preloading', maybeNumber);
            new Image().src = getKeycapImageUrl(maybeNumber);
        }
    }
}

// this probably should've just extracted the right data from the keycap name instead. ah well
export function getKeycapData(keycap: Keycap): KeycapData {
    switch(keycap) {
        /* Alphas */
        case Keycap.BACKTICK: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.BACKTICK};
        case Keycap.ALPHA_1: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_1};
        case Keycap.ALPHA_2: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_2};
        case Keycap.ALPHA_3: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_3};
        case Keycap.ALPHA_4: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_4};
        case Keycap.ALPHA_5: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_5};
        case Keycap.ALPHA_6: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_6};
        case Keycap.ALPHA_7: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_7};
        case Keycap.ALPHA_8: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_8};
        case Keycap.ALPHA_9: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_9};
        case Keycap.ALPHA_0: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_0};
        case Keycap.ALPHA_DASH: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_DASH};
        case Keycap.ALPHA_EQUALS: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ALPHA_EQUALS};
        case Keycap.Q: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.Q};
        case Keycap.W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.W};
        case Keycap.E: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.E};
        case Keycap.R: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.R};
        case Keycap.T: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.T};
        case Keycap.Y: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.Y};
        case Keycap.U: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.U};
        case Keycap.I: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.I};
        case Keycap.O: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.O};
        case Keycap.P: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.P};
        case Keycap.LEFT_BRACKET: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.LEFT_BRACKET};
        case Keycap.RIGHT_BRACKET: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.RIGHT_BRACKET};
        case Keycap.PIPE_B1_150: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.PIPE, size: 1.5};
        case Keycap.A: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.A};
        case Keycap.S: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.S};
        case Keycap.D: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.D};
        case Keycap.F: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F};
        case Keycap.G: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.G};
        case Keycap.H: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.H};
        case Keycap.J: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.J};
        case Keycap.K: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.K};
        case Keycap.L: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.L};
        case Keycap.SEMICOLON: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.SEMICOLON};
        case Keycap.SINGLE_QUOTE: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.SINGLE_QUOTE};
        case Keycap.Z: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.Z};
        case Keycap.X: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.X};
        case Keycap.C: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.C};
        case Keycap.V: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.V};
        case Keycap.B: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.B};
        case Keycap.N: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.N};
        case Keycap.M: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.M};
        case Keycap.COMMA: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.COMMA};
        case Keycap.PERIOD: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.PERIOD};
        case Keycap.SLASH: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.SLASH};

        case Keycap.N_TILDE: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.N_TILDE};

        case Keycap.F_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F, homingType: HomingTypes.BARRED};
        case Keycap.J_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.J, homingType: HomingTypes.BARRED};
        case Keycap.H_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.H, homingType: HomingTypes.BARRED};
        case Keycap.U_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.U, homingType: HomingTypes.BARRED};
        case Keycap.N_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.N, homingType: HomingTypes.BARRED};
        case Keycap.T_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.T, homingType: HomingTypes.BARRED};
        case Keycap.A_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.A, homingType: HomingTypes.BARRED};

        case Keycap.F_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.F, homingType: HomingTypes.SCOOPED};
        case Keycap.J_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.J, homingType: HomingTypes.SCOOPED};
        case Keycap.H_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.H, homingType: HomingTypes.SCOOPED};
        case Keycap.U_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.U, homingType: HomingTypes.SCOOPED};
        case Keycap.N_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.N, homingType: HomingTypes.SCOOPED};
        case Keycap.T_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.T, homingType: HomingTypes.SCOOPED};
        case Keycap.A_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.A, homingType: HomingTypes.SCOOPED};

        /* Dvorak alphas */
        case Keycap.LEFT_BRACKET_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.LEFT_BRACKET};
        case Keycap.RIGHT_BRACKET_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.RIGHT_BRACKET};
        case Keycap.ALPHA_DASH_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_DASH};
        case Keycap.ALPHA_EQUALS_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_EQUALS};
    
        /* Alpha coloured numrow */
        case Keycap.BACKTICK_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.BACKTICK};
        case Keycap.ALPHA_1_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_1};
        case Keycap.ALPHA_2_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_2};
        case Keycap.ALPHA_3_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_3};
        case Keycap.ALPHA_4_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_4};
        case Keycap.ALPHA_5_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_5};
        case Keycap.ALPHA_6_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_6};
        case Keycap.ALPHA_7_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_7};
        case Keycap.ALPHA_8_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_8};
        case Keycap.ALPHA_9_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_9};
        case Keycap.ALPHA_0_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ALPHA_0};
        case Keycap.TEXT_BACKSPACE_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.BACKSPACE, size: 2};
        case Keycap.SCISSORS_B1_200: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.SCISSORS, size: 2};
    
        /* Arrows */
        case Keycap.ARROW_UP_B3: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.UP_ARROW};
        case Keycap.ARROW_UP_B4: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.UP_ARROW};
        case Keycap.ARROW_LEFT: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.UP_ARROW, imageRotation: 270};
        case Keycap.ARROW_DOWN: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.UP_ARROW, imageRotation: 180};
        case Keycap.ARROW_RIGHT: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.UP_ARROW, imageRotation: 90};
    
        case Keycap.ARROW_UP_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.UP_ARROW};
        case Keycap.ARROW_LEFT_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.UP_ARROW, imageRotation: 270};
        case Keycap.ARROW_DOWN_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.UP_ARROW, imageRotation: 180};
        case Keycap.ARROW_RIGHT_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.UP_ARROW, imageRotation: 90};

        /* Cookies of all shapes and sizes */
        case Keycap.COOKIE_SWIRLY_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.COOKIE_SWIRLY};
        case Keycap.COOKIE_RECTANGLE_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.COOKIE_RECTANGLE};
        case Keycap.COOKIE_PRETZEL_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.COOKIE_PRETZEL};
        case Keycap.COOKIE_CIRCLE_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.COOKIE_CIRCLE};

        case Keycap.COOKIE_SWIRLY_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.COOKIE_SWIRLY};
        case Keycap.COOKIE_RECTANGLE_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.COOKIE_RECTANGLE};
        case Keycap.COOKIE_PRETZEL_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.COOKIE_PRETZEL};
        case Keycap.COOKIE_CIRCLE_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.COOKIE_CIRCLE};

        case Keycap.COOKIE_SWIRLY_B2: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.COOKIE_SWIRLY};
        case Keycap.COOKIE_RECTANGLE_B2: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.COOKIE_RECTANGLE};
        case Keycap.COOKIE_PRETZEL_B2: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.COOKIE_PRETZEL};
        case Keycap.COOKIE_CIRCLE_B2: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.COOKIE_CIRCLE};

        case Keycap.COOKIE_SWIRLY_B3: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.COOKIE_SWIRLY};
        case Keycap.COOKIE_RECTANGLE_B3: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.COOKIE_RECTANGLE};
        case Keycap.COOKIE_PRETZEL_B3: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.COOKIE_PRETZEL};
        case Keycap.COOKIE_CIRCLE_B3: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.COOKIE_CIRCLE};

        case Keycap.COOKIE_SWIRLY_B4_100: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_SWIRLY};
        case Keycap.COOKIE_RECTANGLE_B4_100: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_RECTANGLE};
        case Keycap.COOKIE_PRETZEL_B4_100: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_PRETZEL};
        case Keycap.COOKIE_CIRCLE_B4_100: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_CIRCLE};

        case Keycap.COOKIE_SWIRLY_B4_125: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_SWIRLY, size: 1.25};
        case Keycap.COOKIE_RECTANGLE_B4_125: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_RECTANGLE, size: 1.25};
        case Keycap.COOKIE_PRETZEL_B4_125: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_PRETZEL, size: 1.25};
        case Keycap.COOKIE_CIRCLE_B4_125: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_CIRCLE, size: 1.25};

        case Keycap.COOKIE_SWIRLY_B4_150: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_SWIRLY, size: 1.5};
        case Keycap.COOKIE_PRETZEL_B4_150: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_PRETZEL, size: 1.5};
        case Keycap.COOKIE_CIRCLE_B4_150: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.COOKIE_CIRCLE, size: 1.5};
    
        /* Buttons */
        case Keycap.BUTTON_CIRCLE_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.BUTTON_CIRCLE};
        case Keycap.BUTTON_FLOWER_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.BUTTON_FLOWER};
        case Keycap.BUTTON_HEART_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.BUTTON_HEART};

        case Keycap.BUTTON_CIRCLE_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.BUTTON_CIRCLE};
        case Keycap.BUTTON_FLOWER_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.BUTTON_FLOWER};
        case Keycap.BUTTON_HEART_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.BUTTON_HEART};

        case Keycap.BUTTON_CIRCLE_B2: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.BUTTON_CIRCLE};
        case Keycap.BUTTON_FLOWER_B2: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.BUTTON_FLOWER};
        case Keycap.BUTTON_HEART_B2: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.BUTTON_HEART};

        case Keycap.BUTTON_CIRCLE_B3: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.BUTTON_CIRCLE};
        case Keycap.BUTTON_FLOWER_B3: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.BUTTON_FLOWER};
        case Keycap.BUTTON_HEART_B3: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.BUTTON_HEART};

        case Keycap.BUTTON_CIRCLE_B4: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.BUTTON_CIRCLE};
        case Keycap.BUTTON_FLOWER_B4: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.BUTTON_FLOWER};
        case Keycap.BUTTON_HEART_B4: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.BUTTON_HEART};

        /* TKL Text mods */
        case Keycap.TEXT_ESC_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.ESC};
        case Keycap.TEXT_ESC_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ESC};
        case Keycap.TEXT_F1: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.F1};
        case Keycap.TEXT_F2: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.F2};
        case Keycap.TEXT_F3: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.F3};
        case Keycap.TEXT_F4: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.F4};
        case Keycap.TEXT_F5: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.F5};
        case Keycap.TEXT_F6: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.F6};
        case Keycap.TEXT_F7: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.F7};
        case Keycap.TEXT_F8: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.F8};
        case Keycap.TEXT_F9: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.F9};
        case Keycap.TEXT_F10: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.F10};
        case Keycap.TEXT_F11: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.F11};
        case Keycap.TEXT_F12: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.F12};
        case Keycap.TEXT_F13: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.F13};
    
        case Keycap.TEXT_PRT_SC: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.PRINT};
        case Keycap.TEXT_SCROLL: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.SCROLL};
        case Keycap.TEXT_PAUSE: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.BREAK};

        case Keycap.TEXT_INSERT_B2: return {color:KeycapColourNames.BLUE_2, imageName: KeycapImageNames.INS};
        case Keycap.TEXT_HOME_B2: return {color:KeycapColourNames.BLUE_2, imageName: KeycapImageNames.HOME};
        case Keycap.TEXT_PG_UP_B2: return {color:KeycapColourNames.BLUE_2, imageName: KeycapImageNames.UP};
        case Keycap.TEXT_DELETE_B2: return {color:KeycapColourNames.BLUE_2, imageName: KeycapImageNames.DEL};
        case Keycap.TEXT_END_B2: return {color:KeycapColourNames.BLUE_2, imageName: KeycapImageNames.END};
        case Keycap.TEXT_PG_DN_B2: return {color:KeycapColourNames.BLUE_2, imageName: KeycapImageNames.DOWN};

        case Keycap.TEXT_BACKSPACE: return {color:KeycapColourNames.YELLOW, imageName: KeycapImageNames.BACKSPACE, size: 2};
        case Keycap.TEXT_TAB: return {color:KeycapColourNames.BLUE_1, imageName: KeycapImageNames.TAB, size: 1.5};
        case Keycap.TEXT_COOKIE: return {color:KeycapColourNames.BLUE_2, imageName: KeycapImageNames.TEXT_COOKIE, size: 1.75};
        case Keycap.TEXT_COOKIE_STEPPED: return {color:KeycapColourNames.BLUE_2, imageName: KeycapImageNames.TEXT_COOKIE, size: 1.75, topSize: 1.25};
        case Keycap.TEXT_ENTER: return {color:KeycapColourNames.BLUE_2, imageName: KeycapImageNames.ENTER, size: 2.25}; 
        case Keycap.TEXT_SHIFT_225: return {color:KeycapColourNames.BLUE_3, imageName: KeycapImageNames.SHIFT, size: 2.25};
        case Keycap.TEXT_SHIFT_275: return {color:KeycapColourNames.BLUE_3, imageName: KeycapImageNames.SHIFT, size: 2.75};

        case Keycap.TEXT_CONTROL_125: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.CTRL, size: 1.25};
        case Keycap.TEXT_SEW_125: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.SEW, size: 1.25};
        case Keycap.TEXT_ALT_125: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.ALT, size: 1.25};
        case Keycap.TEXT_MENU_125: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.MENU, size: 1.25};
    
        /* Other text mods */ 
        case Keycap.TEXT_SHIFT_125: return {color:KeycapColourNames.BLUE_3, imageName: KeycapImageNames.SHIFT, size: 1.25};
        case Keycap.TEXT_SHIFT_175: return {color:KeycapColourNames.BLUE_3, imageName: KeycapImageNames.SHIFT, size: 1.75};
        case Keycap.TEXT_FN_B3: return {color:KeycapColourNames.BLUE_3, imageName: KeycapImageNames.FN};
        case Keycap.TEXT_FN_B4: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.FN};
        case Keycap.TEXT_SHIFT_200: return {color:KeycapColourNames.BLUE_3, imageName: KeycapImageNames.SHIFT, size: 2};

        case Keycap.TEXT_CONTROL_150: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.CTRL, size: 1.5};
        case Keycap.TEXT_SEW_150: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.SEW, size: 1.5};
        case Keycap.TEXT_SEW_100: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.SEW};
        case Keycap.TEXT_ALT_150: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.ALT, size: 1.5};
        case Keycap.TEXT_ALT_100: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.ALT};
        case Keycap.TEXT_CONTROL_100: return {color:KeycapColourNames.BLUE_4, imageName: KeycapImageNames.CTRL};

        case Keycap.TEXT_HOME_Y1: return {color:KeycapColourNames.YELLOW, imageName: KeycapImageNames.HOME};
        case Keycap.TEXT_DELETE_Y1: return {color:KeycapColourNames.YELLOW, imageName: KeycapImageNames.DEL};
        case Keycap.TEXT_DELETE_B1: return {color:KeycapColourNames.BLUE_1, imageName: KeycapImageNames.DEL};
        case Keycap.TEXT_PG_UP_B1: return {color:KeycapColourNames.BLUE_1, imageName: KeycapImageNames.UP};
        case Keycap.TEXT_END_B1: return {color:KeycapColourNames.BLUE_1, imageName: KeycapImageNames.END};
        case Keycap.TEXT_END_B3: return {color:KeycapColourNames.BLUE_3, imageName: KeycapImageNames.END};
        case Keycap.TEXT_PG_DN_B3: return {color:KeycapColourNames.BLUE_3, imageName: KeycapImageNames.DOWN};
        case Keycap.TEXT_DELETE_Y1: return {color:KeycapColourNames.YELLOW, imageName: KeycapImageNames.DEL};
    
        /* TKL Novelty mods */
        case Keycap.TIN_B1_100: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.TIN};
        case Keycap.TIN_Y_100: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.TIN};
        case Keycap.SCISSORS_Y_200: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.SCISSORS, size: 2};
        case Keycap.BOBBIN_B1_150: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.BOBBIN, size: 1.5};
        case Keycap.PIN_B2_175: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.PIN, size: 1.75, imageRotation: 180};
        case Keycap.TOMATO_B2_175_STEPPED: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.TOMATO, size: 1.75, topSize: 1.25};
        case Keycap.NEEDLE_AND_THREAD_B2_225: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.NEEDLE_AND_THREAD_225, size: 2.25};
        case Keycap.STITCH_B3_225: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.STITCH_LARGE, size: 2.25};
        case Keycap.STITCH_B3_275: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.STITCH_LARGE, size: 2.75};
        case Keycap.STITCH_B3_200: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.STITCH_LARGE, size: 2};
    
        /* Numpad */
        case Keycap.TEXT_NUM_LOCK: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NUM};
        case Keycap.DIVIDE: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.DIVIDE};
        case Keycap.MULTIPLY: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.MULTIPLY};
        case Keycap.NUMPAD_MINUS_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NUMPAD_MINUS};

        case Keycap.NUMPAD_1: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_1};
        case Keycap.NUMPAD_2: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_2};
        case Keycap.NUMPAD_3: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_3};
        case Keycap.NUMPAD_4: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_4};
        case Keycap.NUMPAD_5: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_5};
        case Keycap.NUMPAD_6: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_6};
        case Keycap.NUMPAD_7: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_7};
        case Keycap.NUMPAD_8: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_8};
        case Keycap.NUMPAD_9: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_9};
        case Keycap.NUMPAD_0_100: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_0};
        case Keycap.NUMPAD_0_200: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_0, size: 2};
        case Keycap.NUMPAD_PERIOD: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_PERIOD};
        case Keycap.NUMPAD_00: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_00};

        case Keycap.NUMPAD_5_HOMING_BARRED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_5, homingType: HomingTypes.BARRED};
        case Keycap.NUMPAD_5_HOMING_SCOOPED: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NUMPAD_5, homingType: HomingTypes.SCOOPED};

        case Keycap.NUMPAD_PLUS_200: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.NUMPAD_PLUS, size: 2};
        case Keycap.NUMPAD_MINUS_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.NUMPAD_MINUS};
        case Keycap.NUMPAD_PLUS_100: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.NUMPAD_PLUS};
        // hack: imageRotation used to offset the rotation of the entire key since this is always displayed vertically
        case Keycap.TEXT_NUMPAD_ENTER: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.ENTER, size: 2, imageRotation: 270};

        case Keycap.TOMATO_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.TOMATO};
        case Keycap.TAPE_B3_200: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.TAPE, size: 2};

        case Keycap.TEXT_NUM_LOCK_ALT: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.NUM};
        case Keycap.DIVIDE_ALT: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.DIVIDE};
        case Keycap.MULTIPLY_ALT: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.MULTIPLY};
    
        /* ISO */
        case Keycap.TEXT_ISO_ENTER: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.ENTER_ISO, isIsoEnter: true};
        case Keycap.NEEDLE_ISO_ENTER: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.ISO_NEEDLE, isIsoEnter: true};
        case Keycap.ISO_ANGLE_BRACKET: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ISO_ANGLE_BRACKET};
        case Keycap.UK_ISO_BACKTICK: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ISO_BACKTICK};
        case Keycap.UK_ISO_2: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ISO_2};
        case Keycap.UK_ISO_3: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.ISO_3};
        case Keycap.UK_ISO_POUND: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ISO_POUND};
        case Keycap.UK_ISO_SINGLE_QUOTE: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ISO_SINGLE_QUOTE};
        case Keycap.UK_ISO_BACKTICK_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ISO_BACKTICK};
        case Keycap.UK_ISO_2_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ISO_2};
        case Keycap.UK_ISO_3_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.ISO_3};
    
        /* Tsangan */
        case Keycap.PIPE_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.PIPE};
        case Keycap.TEXT_BACK: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.BACK, size: 1.5};
    
        /* Ergo/Ortho */
        case Keycap.TIN_Y_150: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.TIN, size: 1.5};
        // TIN_Y_100,
        // BOBBIN_B1_150,
        case Keycap.PIN_B1_150: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.PIN, size: 1.5};
        case Keycap.STORK_B2_150: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.STORK, size: 1.5};
        case Keycap.PIN_B2_150: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.PIN, size: 1.5};
        case Keycap.STITCH_B3_150: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.STITCH_MEDIUM, size: 1.5};
        case Keycap.STITCH_B3_100: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.STITCH_SMALL};
        case Keycap.BOBBIN_B3_100: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.BOBBIN};

        case Keycap.STORK_B4_150: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.STORK, size: 1.5};
        case Keycap.STITCH_B4_150: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.STITCH_MEDIUM, size: 1.5};
        case Keycap.PIN_B4_150: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.PIN, size: 1.5};
    
        case Keycap.STITCH_A_B4_200: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.CROSS_STITCH, size: 2, imageRotation: 180};
        case Keycap.STITCH_B_B4_200: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.ZIGZAG, size: 2};
        case Keycap.STITCH_C_B4_200: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.ZIGZAG, size: 2};
        case Keycap.STITCH_D_B4_200: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.CROSS_STITCH, size: 2, imageRotation: 180};
        case Keycap.BLANK_B4_200: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.BLANK, size: 2};
    
        case Keycap.ZIGZAG_B3_200: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.ZIGZAG, size: 2};

        /* Spacebars */
        case Keycap.SPACEBAR_100: return {color: KeycapColourNames.BLUE_4};
        case Keycap.SPACEBAR_125: return {color: KeycapColourNames.BLUE_4, size: 1.25};
        case Keycap.SPACEBAR_150: return {color: KeycapColourNames.BLUE_4, size: 1.50};
        case Keycap.SPACEBAR_175: return {color: KeycapColourNames.BLUE_4, size: 1.75};
        case Keycap.SPACEBAR_200: return {color: KeycapColourNames.BLUE_4, size: 2};
        case Keycap.SPACEBAR_225: return {color: KeycapColourNames.BLUE_4, size: 2.25};
        case Keycap.SPACEBAR_275: return {color: KeycapColourNames.BLUE_4, size: 2.75};
        case Keycap.SPACEBAR_300: return {color: KeycapColourNames.BLUE_4, size: 3};
        case Keycap.SPACEBAR_600: return {color: KeycapColourNames.BLUE_4, size: 6};
        case Keycap.SPACEBAR_625: return {color: KeycapColourNames.BLUE_4, size: 6.25};
        case Keycap.SPACEBAR_700: return {color: KeycapColourNames.BLUE_4, size: 7}
        case Keycap.SPACEBAR_100_ALT: return {color: KeycapColourNames.WHITE};
        case Keycap.SPACEBAR_125_ALT: return {color: KeycapColourNames.WHITE, size: 1.25};
        case Keycap.SPACEBAR_150_ALT: return {color: KeycapColourNames.WHITE, size: 1.50};
        case Keycap.SPACEBAR_175_ALT: return {color: KeycapColourNames.WHITE, size: 1.75};
        case Keycap.SPACEBAR_200_ALT: return {color: KeycapColourNames.WHITE, size: 2};
        case Keycap.SPACEBAR_225_ALT: return {color: KeycapColourNames.WHITE, size: 2.25};
        case Keycap.SPACEBAR_275_ALT: return {color: KeycapColourNames.WHITE, size: 2.75};
        case Keycap.SPACEBAR_300_ALT: return {color: KeycapColourNames.WHITE, size: 3};
        case Keycap.SPACEBAR_600_ALT: return {color: KeycapColourNames.WHITE, size: 6};
        case Keycap.SPACEBAR_625_ALT: return {color: KeycapColourNames.WHITE, size: 6.25};
        case Keycap.SPACEBAR_700_ALT: return {color: KeycapColourNames.WHITE, size: 7};;
        // case Keycap.SPACEBAR_100_Y: return {color: KeycapColourNames.YELLOW};
        // case Keycap.SPACEBAR_125_Y: return {color: KeycapColourNames.YELLOW, size: 1.25};
        case Keycap.SPACEBAR_150_Y: return {color: KeycapColourNames.YELLOW, size: 1.50};
        // case Keycap.SPACEBAR_175_Y: return {color: KeycapColourNames.YELLOW, size: 1.75};
        case Keycap.SPACEBAR_200_Y: return {color: KeycapColourNames.YELLOW, size: 2};
        case Keycap.SPACEBAR_225_Y: return {color: KeycapColourNames.YELLOW, size: 2.25};
        case Keycap.SPACEBAR_275_Y: return {color: KeycapColourNames.YELLOW, size: 2.75};
        // case Keycap.SPACEBAR_300_Y: return {color: KeycapColourNames.YELLOW, size: 3};
        // case Keycap.SPACEBAR_600_Y: return {color: KeycapColourNames.YELLOW, size: 6};
        case Keycap.SPACEBAR_625_Y: return {color: KeycapColourNames.YELLOW, size: 6.25};
        case Keycap.SPACEBAR_700_Y: return {color: KeycapColourNames.YELLOW, size: 7};;
    
        /* 40s */
        // TIN_Y_100,
        case Keycap.TOMATO_B1: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.TOMATO};
        case Keycap.STORK_Y_150: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.STORK, size: 1.5};
        case Keycap.STORK_Y_175: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.STORK, size: 1.75};
        case Keycap.STORK_B1_150: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.STORK, size: 1.5};
        case Keycap.STORK_B1_175: return {color: KeycapColourNames.BLUE_1, imageName: KeycapImageNames.STORK, size: 1.75};
        case Keycap.BOBBIN_B2_125: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.BOBBIN, size: 1.25};
        case Keycap.TOMATO_B2_125: return {color: KeycapColourNames.BLUE_2, imageName: KeycapImageNames.TOMATO, size: 1.25};
        // PIN_B2_150,
        // PIN_B2_175,
        // STITCH_B3_100,
        case Keycap.STITCH_B3_125: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.STITCH_SMALL, size: 1.25};
        // STITCH_B3_150,
        case Keycap.STITCH_B3_175: return {color: KeycapColourNames.BLUE_3, imageName: KeycapImageNames.STITCH_MEDIUM, size: 1.75};
        case Keycap.ZIGZAG_B4_175: return {color: KeycapColourNames.BLUE_4, imageName: KeycapImageNames.ZIGZAG, size: 1.75};
    
        case Keycap.PIPE_W_150: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.PIPE, size: 1.5};
        case Keycap.PIPE_W: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.PIPE};



        /* Norde */
        case Keycap.NORDE_U_UMLAUT: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_U_UMLAUT};
        case Keycap.NORDE_A_RING: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_A_RING};
        case Keycap.NORDE_ETH: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_ETH};
        case Keycap.NORDE_O_TILDE: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_O_TILDE};
        case Keycap.NORDE_O_UMLAUT: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_O_UMLAUT};
        case Keycap.NORDE_A_UMLAUT: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_A_UMLAUT};
        case Keycap.NORDE_AESH: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_AESH};
        case Keycap.NORDE_O_SLASH: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_O_SLASH};
        case Keycap.NORDE_THORN: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_THORN};
        case Keycap.NORDE_3: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_3};
        case Keycap.NORDE_4: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_4};
        case Keycap.NORDE_6: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_6};
        case Keycap.NORDE_7: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_7};
        case Keycap.NORDE_8: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_8};
        case Keycap.NORDE_9: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_9};
        case Keycap.NORDE_0: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_0};
        case Keycap.NORDE_CARAT: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_CARAT};
        case Keycap.NORDE_ESZETT: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_ESZETT};
        case Keycap.NORDE_ACUTE_ACCENT: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_ACUTE_ACCENT};
        case Keycap.NORDE_HALF: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_HALF};
        case Keycap.NORDE_PLUS_QUESTION: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_PLUS_QUESTION};
        case Keycap.NORDE_PIPE: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_PIPE};
        case Keycap.NORDE_BACKSLASH: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_BACKSLASH};
        case Keycap.NORDE_PILCROW: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_PILCROW};
        case Keycap.NORDE_OVERRING: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_OVERRING};
        case Keycap.NORDE_CARON: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_CARON};
        case Keycap.NORDE_PLUS_ASTERISK: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_PLUS_ASTERISK};
        case Keycap.NORDE_UMLAUT: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_UMLAUT};
        case Keycap.NORDE_SINGLE_QUOTE_QUESTION: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_SINGLE_QUOTE_QUESTION};
        case Keycap.NORDE_POUND: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_POUND};
        case Keycap.NORDE_SINGLE_QUOTE_ASTERISK: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_SINGLE_QUOTE_ASTERISK};
        case Keycap.NORDE_BACKTICK: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_BACKTICK};
        case Keycap.NORDE_COMMA: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_COMMA};
        case Keycap.NORDE_PERIOD: return {color: KeycapColourNames.WHITE, imageName: KeycapImageNames.NORDE_PERIOD};

        case Keycap.NORDE_3_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_3};
        case Keycap.NORDE_4_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_4};
        case Keycap.NORDE_6_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_6};
        case Keycap.NORDE_7_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_7};
        case Keycap.NORDE_8_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_8};
        case Keycap.NORDE_9_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_9};
        case Keycap.NORDE_0_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_0};
        case Keycap.NORDE_CARAT_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_CARAT};
        case Keycap.NORDE_ESZETT_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_ESZETT};
        case Keycap.NORDE_ACUTE_ACCENT_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_ACUTE_ACCENT};
        case Keycap.NORDE_HALF_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_HALF};
        case Keycap.NORDE_PLUS_QUESTION_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_PLUS_QUESTION};
        case Keycap.NORDE_PIPE_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_PIPE};
        case Keycap.NORDE_BACKSLASH_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_BACKSLASH};
        case Keycap.NORDE_PILCROW_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_PILCROW};
        case Keycap.NORDE_OVERRING_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_OVERRING};
        case Keycap.NORDE_CARON_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_CARON};
        case Keycap.NORDE_O_UMLAUT_Y: return {color: KeycapColourNames.YELLOW, imageName: KeycapImageNames.NORDE_O_UMLAUT};

        default: 
            throw new Error(`missing KeycapData for ${Keycap[keycap]}`);
    }
}