export enum Key {
    /* Alphas */
    BACKTICK,
    ALPHA_1, ALPHA_2, ALPHA_3, ALPHA_4, ALPHA_5,
    ALPHA_6, ALPHA_7, ALPHA_8, ALPHA_9, ALPHA_0,
    ALPHA_DASH, ALPHA_EQUALS,
    Q, W, E, R, T, Y, U, I, O, P, LEFT_BRACKET, RIGHT_BRACKET, PIPE_150,
    A, S, D, F, G, H, J, K, L, SEMICOLON, SINGLE_QUOTE, 
    Z, X, C, V, B, N, M, COMMA, PERIOD, SLASH,

    N_TILDE,

    /* Arrows */    
    ARROW_UP, ARROW_UP_ALT, ARROW_LEFT, ARROW_DOWN, ARROW_RIGHT,

    /* TKL */
    ESC, F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12,
    PRT_SC, SCROLL, PAUSE,
    /* Hack for scuffed 75% base kits */
    PRT_SC_75, SCROLL_75, PAUSE_75,
    INSERT, HOME, PG_UP,
    DELETE, END, PG_DN,
    BACKSPACE_200,
    TAB_150,
    CAPSLOCK_175, ENTER_225,
    SHIFT_225, SHIFT_275,
    CONTROL_125, CODE_125, ALT_125, MENU_125,
    STEPPED_CAPSLOCK_175,

    /* Hack for alternate numrow */
    BACKSPACE_200_ALT,
    NUM_LOCK_ALT,

    /* 60/65 */
    NUMROW_ESC,

    /* 65/75 macro column */
    MACRO_1,
    MACRO_2,
    MACRO_3,
    MACRO_4,

    /* Numpad */
    NUM_LOCK, DIVIDE, MULTIPLY, NUMPAD_MINUS,
    NUMPAD_7, NUMPAD_8, NUMPAD_9, NUMPAD_PLUS_200,
    NUMPAD_4, NUMPAD_5, NUMPAD_6,
    NUMPAD_1, NUMPAD_2, NUMPAD_3, NUMPAD_ENTER,
    NUMPAD_0_200, NUMPAD_PERIOD,
    NUMPAD_0_100, NUMPAD_00, NUMPAD_PLUS_100, 

    /* ISO */
    ISO_ENTER,
    ISO_PIPE, // TODO: merge ISO, UK_ISO and TSANGAN pipe?
    ISO_ANGLE_BRACKET,
    UK_ISO_BACKTICK,
    UK_ISO_2,
    UK_ISO_3,
    UK_ISO_SINGLE_QUOTE,
    UK_ISO_POUND,
    SHIFT_125,

    /* Tsangan/HHKB/WKL */
    TSANGAN_PIPE,
    DELETE_150, // R2
    CONTROL_175, // R3
    SHIFT_175,
    FN,
    CONTROL_150, CODE_100, ALT_150, ALT_100, CONTROL_100, CODE_150,

    /* Ergo */
    R1_150,
    R2_150_A,
    R2_150_B, // vertical
    R3_150_A,
    R3_150_B, // vertical
    R4_150,
    R1_A, R1_B, R1_C, R1_D, // only used in cookie kit
    R1_E, R1_F,
    R2_A, R2_B, R2_C, R2_D, 
    R3_A, R3_B, R3_C, R3_D, 
    R4_A, R4_B, R4_C, R4_D, 
    R4_E, R4_F,
    R5_A, R5_B, R5_C, R5_D, R5_E, R5_F, R5_G,
    R4_200_A, R4_200_B,
    R5_200_A, R5_200_B, R5_200_C, R5_200_D,
    R5_150_A, R5_150_B, R5_150_C,
    // SPACEBAR_200,

    /* Spacebars / Alice */
    SPACEBAR_100,
    SPACEBAR_125,
    SPACEBAR_150,
    SPACEBAR_175,
    SPACEBAR_200,
    SPACEBAR_225,
    SPACEBAR_275,
    SPACEBAR_300,
    SPACEBAR_600,
    SPACEBAR_625,
    SPACEBAR_700,

    /* 40s */
    DELETE_100,
    // DELETE_150,
    DELETE_175,
    TAB_125,
    ENTER_125,
    ENTER_150,
    ENTER_175,
    SHIFT_100,
    // SHIFT_125,
    SHIFT_150,
    // SHIFT_175,
    LOCK_175,
    // also uses bottom row 1us, 1.25us and 1.5us

    /* Alice - hack for gradient side keys. and novelty-in-mixed fn */
    DELETE_ALICE,
    PG_UP_ALICE,
    FN_ALICE,
};