import React from 'react';
import { Header, Link} from './shared';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carousel.css';

const RAMA_U80_NAME = 'Rama Works U80-A';
const ERGODOX_NAME = 'ErgoDox';
const CYPHER_NAME = 'Cypher by Cable Car Designs';
const MAJA_NAME = 'Maja by Vulcan';
const NEMUI_NAME = 'Nemui by Bachoo';
const AUSTIN_NAME = 'Austin by driftingbunnies';
const J02_NAME = 'J-02 by proto[Typist]';
const PORTICO_NAME = 'Portico by TKC';
const MINIVAN_NAME = 'MiniVan by Trash Man (Case by The Royal)';
const ORTHO_DUO_NAME = 'Planck and Preonic by OLKB';
const FUJI_NAME = 'Fuji65 by CMM.Studio';
const BEPOVAN_NAME = 'Bepovan by KnoblesseOblige/Aeternus Company';
const ABSINTHE_NAME = 'Absinthe by velvetjaguar and reggatronics'
const WHIMSY_NAME = 'Whimsy by Fourteen';
const BLOQ_NAME = 'BLOQ by THOK';

export function RendersSection() {
  return (
    <div className="rendersSection">
        <Header name="renders" />
        {/* <p className="mb2">
            Shown on the <Link url="https://ramaworks.store/products/u80-a">Rama Works U80-A</Link>, <Link url="https://www.ergodox.io/">ErgoDox</Link>, <Link url="https://geekhack.org/index.php?topic=110000.0">Nemui by Bachoo</Link>, and <Link url="https://geekhack.org/index.php?topic=100883.0">Austin by driftingbunnies</Link>.
        </p> */}
        <Carousel showArrows={true} showStatus={false} showThumbs={true}>
            {[
                {imageName: 'j02_A_fixed', keyboardName: J02_NAME},
                {imageName: 'j02_D_fixed', keyboardName: J02_NAME},
                {imageName: 'fuji_A_fixed', keyboardName: FUJI_NAME},
                {imageName: 'fuji_B_fixed', keyboardName: FUJI_NAME},
                {imageName: 'planck_A_fixed', keyboardName: ORTHO_DUO_NAME}, 
                {imageName: 'absinthe_A', keyboardName: ABSINTHE_NAME},
                {imageName: 'absinthe_C', keyboardName: ABSINTHE_NAME},
                {imageName: 'whimsy_B', keyboardName: WHIMSY_NAME},
                {imageName: 'whimsy_C', keyboardName: WHIMSY_NAME},
                {imageName: 'minivan_A', keyboardName: MINIVAN_NAME},
                {imageName: 'u80_F', keyboardName: RAMA_U80_NAME},
                // {imageName: 'u80_E', keyboardName: RAMA_U80_NAME}, // file still present
                {imageName: 'u80_C', keyboardName: RAMA_U80_NAME},
                // {imageName: 'u80_A', keyboardName: RAMA_U80_NAME},
                // {imageName: 'u80_D', keyboardName: RAMA_U80_NAME},
                // {imageName: 'u80_B', keyboardName: RAMA_U80_NAME}, // file still present
                {imageName: 'ergodox_A', keyboardName: ERGODOX_NAME},
                // {imageName: 'ergodox_B', keyboardName: ERGODOX_NAME},
                {imageName: 'ergodox_C', keyboardName: ERGODOX_NAME},
                {imageName: 'ergodox_D', keyboardName: ERGODOX_NAME},
                {imageName: 'maja_A_fixed', keyboardName: MAJA_NAME},
                {imageName: 'maja_B', keyboardName: MAJA_NAME},
                // {imageName: 'portico_B_fix', keyboardName: PORTICO_NAME},
                {imageName: 'portico_blue_C_fixed', keyboardName: PORTICO_NAME},
                {imageName: 'portico_blue_D_fixed', keyboardName: PORTICO_NAME},
                {imageName: 'cypher_C_fixed', keyboardName: CYPHER_NAME},
                {imageName: 'cypher_D', keyboardName: CYPHER_NAME},
                {imageName: 'bepovan_C', keyboardName: BEPOVAN_NAME},
                // {imageName: 'cypher_E_fixed', keyboardName: CYPHER_NAME}, // file still present
                // {imageName: 'nemui_A', keyboardName: NEMUI_NAME}, // file still present
                // {imageName: 'nemui_E', keyboardName: NEMUI_NAME}, // file still present
                // {imageName: 'nemui_B', keyboardName: NEMUI_NAME}, // file still present
                // {imageName: 'nemui_C', keyboardName: NEMUI_NAME},
                // {imageName: 'nemui_D', keyboardName: NEMUI_NAME},
                // {imageName: 'austin_A', keyboardName: AUSTIN_NAME}, // file still present
                {imageName: 'austin_E', keyboardName: AUSTIN_NAME},
                // {imageName: 'austin_B', keyboardName: AUSTIN_NAME},
                {imageName: 'austin_C', keyboardName: AUSTIN_NAME},
                // {imageName: 'austin_D', keyboardName: AUSTIN_NAME},
            ].map((renderInfo) => {
                // to get thumbnails, can't use custom component 
                // TODO: implement legends outside of the carousel instead, or improve styling?
                const {imageName, keyboardName} = renderInfo;
                const imageUrl = `renders/${imageName}.jpg`;
                return (
                    <div>
                        <img src={imageUrl} className="kitThumbnail" key={`${imageName}-${keyboardName}`} alt={`${keyboardName}`} />
                        <p className="legend">{keyboardName}</p>
                    </div>
                )
            })}
        </Carousel>
    </div>
  );
};