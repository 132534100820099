import React from 'react';
import { getKeycapData } from './data/keycap_data';
import { KeycapRenderer, KEYCAP_SIZE } from './keycap_renderer';
import { KeycapWithPosition } from './data/types';
import './layout_renderer.css'

// fix coordinates to 0,0
function normalisePositions(keycapWithPositions: Array<KeycapWithPosition>): Array<KeycapWithPosition> {
    let min_x = keycapWithPositions[0].x;
    let min_y = keycapWithPositions[0].y;

    for (const kwp of keycapWithPositions) {
        if (min_x > kwp.x) min_x = kwp.x;
        if (min_y > kwp.y) min_y = kwp.y;
    }

    let newPositions: Array<KeycapWithPosition> = [];

    for (const kwp of keycapWithPositions) {
        newPositions.push({
            ...kwp,
            x: kwp.x - min_x,
            y: kwp.y - min_y,
        })
    }

    return newPositions;
}

type LayoutRendererProps = {
    keycapWithPositions: Array<KeycapWithPosition>,
    shouldShowKeycapLabels?: boolean,
}

export function LayoutRenderer({keycapWithPositions, shouldShowKeycapLabels}: LayoutRendererProps) {
    const normalisedKeycapWithPositions = normalisePositions(keycapWithPositions);

    let max_x = 0;
    let max_y = 0;

    // todo: can technically be computed in same loop above
    for (const keycapWithPosition of normalisedKeycapWithPositions) {
        const keycapData = getKeycapData(keycapWithPosition.keycap);
        // hack: doesn't work for iso keys or rotated keys, meh
        const layoutX = keycapWithPosition.x + (keycapData?.size && keycapWithPosition.rotation === undefined ? keycapData.size : 1); 
        const layoutY = keycapWithPosition.y + 1; 
        if (layoutX > max_x) max_x = layoutX;
        if (layoutY > max_y) max_y = layoutY;
    }

    return <div className="layoutRendererContainer" style={{
        width: KEYCAP_SIZE * max_x,
        height: KEYCAP_SIZE * max_y,
    }}>
        {normalisedKeycapWithPositions.map((keycapWithPosition, i) => {
            return <KeycapRenderer 
                keycap={keycapWithPosition.keycap}
                x={keycapWithPosition.x}
                y={keycapWithPosition.y}
                rotation={keycapWithPosition.rotation}
                shouldShowLabel={shouldShowKeycapLabels}
                key={`${keycapWithPosition.keycap}-${i}`}
            />
        })}
    </div>
}