import React from 'react';
import { Header, ColourWarning, Link} from './shared';
import { AllKitDefinitions, KitName } from './tailor/data/kits';
import { KitRenderer } from './tailor/kit_renderer';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carousel.css';

const PRICING_IMAGE_LINK = 'https://i.imgur.com/UMEfKd8.png';
// TODO: clean up unused code here 

function KitThumbnail({kitName}: {kitName: KitName}) {
    // hack: we assume image name is the kitName capitalised plus _KIT but we actually use the names in AllKitDefinitions when generating/saving these
    // so they have to stay in sync
    // @ts-ignore
    const kitEnumName = Object.keys(KitName).filter(key => KitName[key] === kitName)[0];
    const imageUrl = `kit_images/${kitEnumName.toUpperCase()}_KIT.png`;
    return <img src={imageUrl} className="kitThumbnail" alt={`${kitName} kit`} />
}

function KitRow({kitNames, shouldOmitTopPadding}: {kitNames: Array<KitName>, shouldOmitTopPadding?: boolean}) {
    // hack: for whatever reason the images already have 8px bottom margin on them and i really cant work it out
    // this is to make the margins all match up
    const wrapperStyles = {marginBottom: '24px'};
    if (kitNames.length === 2) {
        return (
            <div style={wrapperStyles}>
                <div className="halfArea subArea subAreaPaddingRight">
                    <KitThumbnail kitName={kitNames[0]} />
                </div>
                <div className="halfArea subArea subAreaPaddingLeft">
                    <KitThumbnail kitName={kitNames[1]} />
                </div>
            </div>
        )
    } else if (kitNames.length === 3) {
        return (
            <div style={wrapperStyles}>
                <div className="thirdArea subArea subAreaPaddingRightPartial">
                    <KitThumbnail kitName={kitNames[0]} />
                </div>
                <div className="thirdArea subArea subAreaPaddingMiddle">
                    <KitThumbnail kitName={kitNames[1]} />
                </div>
                <div className="thirdArea subArea subAreaPaddingLeftPartial">
                    <KitThumbnail kitName={kitNames[2]} />
                </div>
            </div>
        )
    } else if (kitNames.length === 4) {
        return (
            <div style={wrapperStyles}>
                <div className="fourthArea subArea subAreaPaddingRightPartial">
                    <KitThumbnail kitName={kitNames[0]} />
                </div>
                <div className="fourthArea subArea subAreaPaddingMiddle">
                    <KitThumbnail kitName={kitNames[1]} />
                </div>
                <div className="fourthArea subArea subAreaPaddingMiddle">
                    <KitThumbnail kitName={kitNames[2]} />
                </div>
                <div className="fourthArea subArea subAreaPaddingLeftPartial">
                    <KitThumbnail kitName={kitNames[3]} />
                </div>
            </div>
        )
    }

    throw new Error(`unexpected length ${kitNames.length} in KitRow`)
}

export function KitsSection() {
  return (
    <div className="kitsSection">
        <Header name="kits" />
        <p className="mb2"> 
            Sewing Tin provides flexible kitting with separate alphas and modifiers kits (TKL text, TKL mixed, TKL novelty, ergodox, ortho, 40s), as well as two all-in-one base kit options.
            Spacebars, numpad and ISO can be combined with either a base kit or alphas + modifiers. You can view kit pricing <Link url={PRICING_IMAGE_LINK}>here</Link>.
        </p>
        {/* <ColourWarning /> */}
        
        {/* {Object.values(AllKitDefinitions).map(kitDefinition => <KitRenderer kitDefinition={kitDefinition} />)} */}

        {/* <KitRow kitNames={[KitName.MIXED_BASE, KitName.NOVELTY_BASE]} / >
        <KitRow kitNames={[KitName.TEXT_MODS, KitName.MIXED_MODS, KitName.NOVELTY_MODS]} / >
        <KitRow kitNames={[KitName.ALPHAS, KitName.ALPHA_NUMROW, KitName.NUMPAD, KitName.SPACEBARS]} / >
        <KitRow kitNames={[KitName.ERGODOX, KitName.ORTHO, KitName.FOURTIES]} / >
        <KitRow kitNames={[KitName.ISO, KitName.COOKIES, KitName.BUTTONS]} / > */}
        <Carousel showArrows={true} showStatus={false} showThumbs={true}>
            {[
                KitName.MIXED_BASE, KitName.NOVELTY_BASE, 
                KitName.ALPHAS, KitName.ALPHA_NUMROW, KitName.COLEVRAK,
                KitName.TEXT_MODS, KitName.MIXED_MODS, KitName.NOVELTY_MODS, 
                KitName.SPACEBARS, KitName.NUMPAD, KitName.ISO,
                KitName.NORDE, KitName.YELLOW_ACCENTS,
                KitName.ERGO, KitName.ORTHO, KitName.FOURTIES,
                KitName.COOKIES, KitName.BUTTONS,
            ].map(kitName => {
                // to get thumbnails, can't use custom component 
                // @ts-ignore
                const kitEnumName = Object.keys(KitName).filter(key => KitName[key] === kitName)[0];
                const imageUrl = `kit_images/${kitEnumName.toUpperCase()}_KIT.png`;
                return <img src={imageUrl} className="kitThumbnail" key={kitName} alt={`${kitName} kit`} />
                // return (
                //     <div key={kitName}>
                //         <KitThumbnail kitName={kitName} />
                //     </div>
                // )
            })}
        </Carousel>
    </div>
  );
};