import React, {useState} from 'react';
import { LayoutRenderer } from './tailor/layout_renderer';
import { Keycap } from './tailor/data/keyset';

let KEYCAP_ARRAYS = [
    [Keycap.TIN_Y_100, Keycap.COOKIE_SWIRLY_B1, Keycap.COOKIE_RECTANGLE_B2, Keycap.COOKIE_PRETZEL_B3, Keycap.COOKIE_CIRCLE_B4_100, Keycap.C],
    [Keycap.TIN_Y_100, Keycap.COOKIE_RECTANGLE_B1, Keycap.COOKIE_PRETZEL_B2, Keycap.COOKIE_CIRCLE_B3, Keycap.COOKIE_SWIRLY_B4_100, Keycap.O],
    [Keycap.TIN_Y_100, Keycap.COOKIE_PRETZEL_B1, Keycap.COOKIE_CIRCLE_B2, Keycap.COOKIE_SWIRLY_B3, Keycap.COOKIE_RECTANGLE_B4_100, Keycap.O],
    [Keycap.TIN_Y_100, Keycap.COOKIE_CIRCLE_B1, Keycap.COOKIE_SWIRLY_B2, Keycap.COOKIE_RECTANGLE_B3, Keycap.COOKIE_PRETZEL_B4_100, Keycap.K],
    [Keycap.TIN_Y_100, Keycap.COOKIE_SWIRLY_B1, Keycap.COOKIE_RECTANGLE_B2, Keycap.COOKIE_PRETZEL_B3, Keycap.COOKIE_CIRCLE_B4_100, Keycap.I],
    [Keycap.TIN_Y_100, Keycap.COOKIE_RECTANGLE_B1, Keycap.COOKIE_PRETZEL_B2, Keycap.COOKIE_CIRCLE_B3, Keycap.COOKIE_SWIRLY_B4_100, Keycap.E],
    [Keycap.TIN_Y_100, Keycap.COOKIE_PRETZEL_B1, Keycap.COOKIE_CIRCLE_B2, Keycap.COOKIE_SWIRLY_B3, Keycap.COOKIE_RECTANGLE_B4_100, Keycap.S],
    [Keycap.TIN_Y_100, Keycap.COOKIE_CIRCLE_B1, Keycap.COOKIE_SWIRLY_B2, Keycap.COOKIE_RECTANGLE_B3, Keycap.COOKIE_PRETZEL_B4_100, Keycap.ALPHA_1_W],
];

export function KeycapPreview() {
    const [arrayIndex, setArrayIndex] = useState(0);

    const keycapArray = KEYCAP_ARRAYS[arrayIndex];

    return (
        <div 
            className="keycapWrapper greyWrapper mb2"
            style={{overflow: 'auto'}}
            onClick={() => setArrayIndex((arrayIndex+ 1) % KEYCAP_ARRAYS.length)}
        >
            <LayoutRenderer keycapWithPositions={[
                {keycap: keycapArray[0], x: 0, y: 0},
                {keycap: keycapArray[1], x: 1, y: 0},
                {keycap: keycapArray[2], x: 2, y: 0},
                {keycap: keycapArray[3], x: 3, y: 0},
                {keycap: keycapArray[4], x: 4, y: 0},
                {keycap: keycapArray[5], x: 5, y: 0},
            ]} />
        </div>
    )
}