import { Keycap } from './keyset';
import { KeycapWithPosition } from './types';

export enum KitName {
    NOVELTY_BASE = 'Novelty base',
    MIXED_BASE = 'Mixed base',
    ALPHAS = 'Alphas',
    NOVELTY_MODS = 'TKL novelty mods',
    MIXED_MODS = 'TKL mixed mods',
    TEXT_MODS = 'TKL text mods',
    NUMPAD = 'Numpad',
    ERGO = 'Ergo',
    ORTHO = 'Ortho',
    FOURTIES = '40s',
    ALPHA_NUMROW = 'Alternate numrow',
    SPACEBARS = 'Spacebars',
    COLEVRAK = 'Colevrak',
    COOKIES = 'Cookies',
    BUTTONS = 'Buttons',
    ISO = 'ISO',
    YELLOW_ACCENTS = 'Yellow accents',
    NORDE = 'NorDe',
}

export type KitDefinition = {
    name: KitName,
    description?: string;
    keycapWithPositions: Array<KeycapWithPosition>;
};

// TODO: helper to count kit size :^)

const alphas: Array<KeycapWithPosition> = [
    {keycap: Keycap.BACKTICK, x: 0, y: 1.5},
    {keycap: Keycap.ALPHA_1, x: 1, y: 1.5},
    {keycap: Keycap.ALPHA_2, x: 2, y: 1.5},
    {keycap: Keycap.ALPHA_3, x: 3, y: 1.5},
    {keycap: Keycap.ALPHA_4, x: 4, y: 1.5},
    {keycap: Keycap.ALPHA_5, x: 5, y: 1.5},
    {keycap: Keycap.ALPHA_6, x: 6, y: 1.5},
    {keycap: Keycap.ALPHA_7, x: 7, y: 1.5},
    {keycap: Keycap.ALPHA_8, x: 8, y: 1.5},
    {keycap: Keycap.ALPHA_9, x: 9, y: 1.5},
    {keycap: Keycap.ALPHA_0, x: 10, y: 1.5},
    {keycap: Keycap.ALPHA_DASH, x: 11, y: 1.5},
    {keycap: Keycap.ALPHA_EQUALS, x: 12, y: 1.5},
    // {keycap: Keycap.BACKSPACE_200, x: 13, y: 1.5},

    // {keycap: Keycap.TAB_150, x: 0, y: 2.5},
    {keycap: Keycap.Q, x: 1.5, y: 2.5},
    {keycap: Keycap.W, x: 2.5, y: 2.5},
    {keycap: Keycap.E, x: 3.5, y: 2.5},
    {keycap: Keycap.R, x: 4.5, y: 2.5},
    {keycap: Keycap.T, x: 5.5, y: 2.5},
    {keycap: Keycap.Y, x: 6.5, y: 2.5},
    {keycap: Keycap.U, x: 7.5, y: 2.5},
    {keycap: Keycap.I, x: 8.5, y: 2.5},
    {keycap: Keycap.O, x: 9.5, y: 2.5},
    {keycap: Keycap.P, x: 10.5, y: 2.5},
    {keycap: Keycap.LEFT_BRACKET, x: 11.5, y: 2.5},
    {keycap: Keycap.RIGHT_BRACKET, x: 12.5, y: 2.5},
    // {keycap: Keycap.PIPE_150, x: 13.5, y: 2.5},

    // {keycap: Keycap.CAPSLOCK_175, x: 0, y: 3.5},
    {keycap: Keycap.A, x: 1.75, y: 3.5},
    {keycap: Keycap.S, x: 2.75, y: 3.5},
    {keycap: Keycap.D, x: 3.75, y: 3.5},
    {keycap: Keycap.F_HOMING_BARRED, x: 4.75, y: 3.5},
    {keycap: Keycap.G, x: 5.75, y: 3.5},
    {keycap: Keycap.H, x: 6.75, y: 3.5},
    {keycap: Keycap.J_HOMING_BARRED, x: 7.75, y: 3.5},
    {keycap: Keycap.K, x: 8.75, y: 3.5},
    {keycap: Keycap.L, x: 9.75, y: 3.5},
    {keycap: Keycap.SEMICOLON, x: 10.75, y: 3.5},
    {keycap: Keycap.SINGLE_QUOTE, x: 11.75, y: 3.5},
    // {keycap: Keycap.ENTER_225, x: 12.75, y: 3.5},

    // {keycap: Keycap.SHIFT_225, x: 0, y: 4.5},
    {keycap: Keycap.Z, x: 2.25, y: 4.5},
    {keycap: Keycap.X, x: 3.25, y: 4.5},
    {keycap: Keycap.C, x: 4.25, y: 4.5},
    {keycap: Keycap.V, x: 5.25, y: 4.5},
    {keycap: Keycap.B, x: 6.25, y: 4.5},
    {keycap: Keycap.N, x: 7.25, y: 4.5},
    {keycap: Keycap.M, x: 8.25, y: 4.5},
    {keycap: Keycap.COMMA, x: 9.25, y: 4.5},
    {keycap: Keycap.PERIOD, x: 10.25, y: 4.5},
    {keycap: Keycap.SLASH, x: 11.25, y: 4.5},
    // {keycap: Keycap.SHIFT_275, x: 12.25, y: 4.5},
];

const commonArrows: Array<KeycapWithPosition> = [
    {keycap: Keycap.ARROW_UP_B3, x: 16.25, y: 4.5},
    {keycap: Keycap.ARROW_LEFT, x: 15.25, y: 5.5},
    {keycap: Keycap.ARROW_DOWN, x: 16.25, y: 5.5},
    {keycap: Keycap.ARROW_RIGHT, x: 17.25, y: 5.5},
];

// DONE
const textMod60: Array<KeycapWithPosition> = [
    {keycap: Keycap.TEXT_BACKSPACE, x: 13, y: 1.5},

    {keycap: Keycap.TEXT_TAB, x: 0, y: 2.5},

    {keycap: Keycap.TEXT_SHIFT_225, x: 0, y: 4.5}, 
    {keycap: Keycap.TEXT_SHIFT_275, x: 12.25, y: 4.5},

    {keycap: Keycap.TEXT_COOKIE, x: 0, y: 3.5},
    {keycap: Keycap.TEXT_ENTER, x: 12.75, y: 3.5},

    {keycap: Keycap.TEXT_CONTROL_125, x: 0, y: 5.5},
    {keycap: Keycap.TEXT_SEW_125, x: 1.25, y: 5.5},
    {keycap: Keycap.TEXT_ALT_125, x: 2.5, y: 5.5},
    // {keycap: Keycap.SPACEBAR_625, x: 3.75, y: 5.5},
    {keycap: Keycap.TEXT_ALT_125, x: 10, y: 5.5},
    {keycap: Keycap.TEXT_SEW_125, x: 11.25, y: 5.5},
    {keycap: Keycap.TEXT_MENU_125, x: 12.5, y: 5.5},
    {keycap: Keycap.TEXT_CONTROL_125, x: 13.75, y: 5.5},

    {keycap: Keycap.TEXT_SHIFT_175, x: 12.25, y: 10},

    {keycap: Keycap.TEXT_CONTROL_150, x: 0, y: 11},
    {keycap: Keycap.TEXT_SEW_100, x: 1.5, y: 11},
    {keycap: Keycap.TEXT_ALT_150, x: 2.5, y: 11},
    {keycap: Keycap.SPACEBAR_700, x: 4, y: 11},
    {keycap: Keycap.TEXT_ALT_150, x: 11, y: 11},
    {keycap: Keycap.TEXT_SEW_100, x: 12.5, y: 11},
    {keycap: Keycap.TEXT_CONTROL_150, x: 13.5, y: 11},

    ...commonArrows,
];

// DONE
const noveltyMod60: Array<KeycapWithPosition> = [
    {keycap: Keycap.SCISSORS_Y_200, x: 13, y: 1.5},

    {keycap: Keycap.BOBBIN_B1_150, x: 0, y: 2.5},

    {keycap: Keycap.STITCH_B3_225, x: 0, y: 4.5}, 
    {keycap: Keycap.STITCH_B3_275, x: 12.25, y: 4.5},

    {keycap: Keycap.PIN_B2_175, x: 0, y: 3.5},
    {keycap: Keycap.NEEDLE_AND_THREAD_B2_225, x: 12.75, y: 3.5},

    {keycap: Keycap.COOKIE_SWIRLY_B4_125, x: 0, y: 5.5},
    {keycap: Keycap.COOKIE_CIRCLE_B4_125, x: 1.25, y: 5.5},
    {keycap: Keycap.COOKIE_PRETZEL_B4_125, x: 2.5, y: 5.5},
    // {keycap: Keycap.SPACEBAR_625, x: 3.75, y: 5.5},
    {keycap: Keycap.COOKIE_PRETZEL_B4_125, x: 10, y: 5.5},
    {keycap: Keycap.COOKIE_CIRCLE_B4_125, x: 11.25, y: 5.5},
    {keycap: Keycap.COOKIE_RECTANGLE_B4_125, x: 12.5, y: 5.5},
    {keycap: Keycap.COOKIE_SWIRLY_B4_125, x: 13.75, y: 5.5},

    {keycap: Keycap.STITCH_B3_175, x: 12.25, y: 10},

    {keycap: Keycap.COOKIE_SWIRLY_B4_150, x: 0, y: 11},
    {keycap: Keycap.COOKIE_CIRCLE_B4_100, x: 1.5, y: 11},
    {keycap: Keycap.COOKIE_PRETZEL_B4_150, x: 2.5, y: 11},
    {keycap: Keycap.SPACEBAR_700, x: 4, y: 11},
    {keycap: Keycap.COOKIE_PRETZEL_B4_150, x: 11, y: 11},
    {keycap: Keycap.COOKIE_CIRCLE_B4_100, x: 12.5, y: 11},
    {keycap: Keycap.COOKIE_SWIRLY_B4_150, x: 13.5, y: 11},

    ...commonArrows,
];

// DONE
const textModTKL: Array<KeycapWithPosition> = [
    {keycap: Keycap.TEXT_ESC_B1, x: 0, y: 0},
    {keycap: Keycap.TEXT_F1, x: 2, y: 0},
    {keycap: Keycap.TEXT_F2, x: 3, y: 0},
    {keycap: Keycap.TEXT_F3, x: 4, y: 0},
    {keycap: Keycap.TEXT_F4, x: 5, y: 0},
    {keycap: Keycap.TEXT_F5, x: 6.5, y: 0},
    {keycap: Keycap.TEXT_F6, x: 7.5, y: 0},
    {keycap: Keycap.TEXT_F7, x: 8.5, y: 0},
    {keycap: Keycap.TEXT_F8, x: 9.5, y: 0},
    {keycap: Keycap.TEXT_F9, x: 11, y: 0},
    {keycap: Keycap.TEXT_F10, x: 12, y: 0},
    {keycap: Keycap.TEXT_F11, x: 13, y: 0},
    {keycap: Keycap.TEXT_F12, x: 14, y: 0},

    {keycap: Keycap.TEXT_PRT_SC, x: 15.25, y: 0},
    {keycap: Keycap.TEXT_SCROLL, x: 16.25, y: 0},
    {keycap: Keycap.TEXT_PAUSE, x: 17.25, y: 0},

    {keycap: Keycap.TEXT_INSERT_B2, x: 15.25, y: 1.5},
    {keycap: Keycap.TEXT_HOME_B2, x: 16.25, y: 1.5},
    {keycap: Keycap.TEXT_PG_UP_B2, x: 17.25, y: 1.5},
    {keycap: Keycap.TEXT_DELETE_B2, x: 15.25, y: 2.5},
    {keycap: Keycap.TEXT_END_B2, x: 16.25, y: 2.5},
    {keycap: Keycap.TEXT_PG_DN_B2, x: 17.25, y: 2.5},

    ...commonArrows,
];

// DONE
const noveltyModTKL: Array<KeycapWithPosition> = [
    {keycap: Keycap.TIN_B1_100, x: 0, y: 0},
    {keycap: Keycap.COOKIE_SWIRLY_B2, x: 2, y: 0},
    {keycap: Keycap.COOKIE_RECTANGLE_B2, x: 3, y: 0},
    {keycap: Keycap.COOKIE_PRETZEL_B2, x: 4, y: 0},
    {keycap: Keycap.COOKIE_CIRCLE_B2, x: 5, y: 0},
    {keycap: Keycap.COOKIE_SWIRLY_B3, x: 6.5, y: 0},
    {keycap: Keycap.COOKIE_RECTANGLE_B3, x: 7.5, y: 0},
    {keycap: Keycap.COOKIE_PRETZEL_B3, x: 8.5, y: 0},
    {keycap: Keycap.COOKIE_CIRCLE_B3, x: 9.5, y: 0},
    {keycap: Keycap.COOKIE_SWIRLY_B4_100, x: 11, y: 0},
    {keycap: Keycap.COOKIE_RECTANGLE_B4_100, x: 12, y: 0},
    {keycap: Keycap.COOKIE_PRETZEL_B4_100, x: 13, y: 0},
    {keycap: Keycap.COOKIE_CIRCLE_B4_100, x: 14, y: 0},

    {keycap: Keycap.BUTTON_FLOWER_B1, x: 15.25, y: 0},
    {keycap: Keycap.BUTTON_CIRCLE_B1, x: 16.25, y: 0},
    {keycap: Keycap.BUTTON_HEART_B1, x: 17.25, y: 0},

    {keycap: Keycap.BUTTON_CIRCLE_B2, x: 15.25, y: 1.5},
    {keycap: Keycap.BUTTON_HEART_B2, x: 16.25, y: 1.5},
    {keycap: Keycap.BUTTON_FLOWER_B2, x: 17.25, y: 1.5},
    {keycap: Keycap.BUTTON_HEART_B2, x: 15.25, y: 2.5},
    {keycap: Keycap.BUTTON_FLOWER_B2, x: 16.25, y: 2.5},
    {keycap: Keycap.BUTTON_CIRCLE_B2, x: 17.25, y: 2.5},

    {keycap: Keycap.TIN_Y_100, x: 0, y: 7},
    {keycap: Keycap.TOMATO_Y, x: 15.25, y: 7},

    {keycap: Keycap.BUTTON_FLOWER_B3, x: 15.25, y: 10},
    {keycap: Keycap.BUTTON_CIRCLE_B3, x: 16.25, y: 10},
    {keycap: Keycap.BUTTON_HEART_B3, x: 17.25, y: 10},
    {keycap: Keycap.BUTTON_CIRCLE_B4, x: 15.25, y: 11},
    {keycap: Keycap.BUTTON_HEART_B4, x: 16.25, y: 11},
    {keycap: Keycap.BUTTON_FLOWER_B4, x: 17.25, y: 11},
];

// DONE
const baseKitShared: Array<KeycapWithPosition> = [
    {keycap: Keycap.F_HOMING_SCOOPED, x: 4.75, y: 9}, 
    {keycap: Keycap.J_HOMING_SCOOPED, x: 7.75, y: 9}, 
    {keycap: Keycap.PIPE_B1_150, x: 13.5, y: 2.5},
    {keycap: Keycap.SPACEBAR_625, x: 3.75, y: 5.5},
];

// DONE
const textModStandalone60: Array<KeycapWithPosition> = [
    {keycap: Keycap.TEXT_BACK, x: 13.5, y: 8}, 

    {keycap: Keycap.TEXT_COOKIE_STEPPED, x: 0, y: 9}, 

    {keycap: Keycap.TEXT_FN_B3, x: 14, y: 10},

    {keycap: Keycap.TEXT_CONTROL_100, x: 0.5, y: 12},
    {keycap: Keycap.TEXT_ALT_100, x: 1.5, y: 12},
    {keycap: Keycap.TEXT_SEW_150, x: 2.5, y: 12},
    {keycap: Keycap.SPACEBAR_700_ALT, x: 4, y: 12},
    {keycap: Keycap.TEXT_SEW_150, x: 11, y: 12},
    {keycap: Keycap.TEXT_ALT_100, x: 12.5, y: 12},
    {keycap: Keycap.TEXT_CONTROL_100, x: 13.5, y: 12},

    {keycap: Keycap.SPACEBAR_625_ALT, x: 3.75, y: 5.5},
];

const textModStandaloneTKL: Array<KeycapWithPosition> = [
    {keycap: Keycap.TEXT_ESC_Y, x: 0, y: 7},
    {keycap: Keycap.TEXT_F13, x: 15.25, y: 7},
    {keycap: Keycap.TEXT_HOME_Y1, x: 16.25, y: 7},
    {keycap: Keycap.TEXT_DELETE_Y1, x: 17.25, y: 7},
    {keycap: Keycap.TEXT_DELETE_B1, x: 15.25, y: 8},
    {keycap: Keycap.TEXT_END_B1, x: 16.25, y: 8},
    {keycap: Keycap.TEXT_PG_UP_B1, x: 17.25, y: 8},
    {keycap: Keycap.TEXT_END_B3, x: 15.25, y: 10},
    {keycap: Keycap.TEXT_PG_DN_B3, x: 16.25, y: 10},
    {keycap: Keycap.TEXT_FN_B4, x: 15.25, y: 11},
    {keycap: Keycap.ARROW_UP_B4, x: 16.25, y: 11},
];

const noveltyModStandalone60: Array<KeycapWithPosition> = [
    {keycap: Keycap.STORK_B1_150, x: 13.5, y: 8}, 

    {keycap: Keycap.TOMATO_B2_175_STEPPED, x: 0, y: 9}, 

    {keycap: Keycap.COOKIE_CIRCLE_B4_150, x: 2.5, y: 12},
    {keycap: Keycap.SPACEBAR_700_ALT, x: 4, y: 12},
    {keycap: Keycap.COOKIE_CIRCLE_B4_150, x: 11, y: 12},

    {keycap: Keycap.SPACEBAR_625_ALT, x: 3.75, y: 5.5},
];

const noveltyModStandaloneTKL: Array<KeycapWithPosition> = [
    {keycap: Keycap.BUTTON_HEART_B1, x: 15.25, y: 8},
    {keycap: Keycap.BUTTON_FLOWER_B1, x: 16.25, y: 8},
    {keycap: Keycap.BUTTON_CIRCLE_B1, x: 17.25, y: 8},

    {keycap: Keycap.ARROW_UP_B4, x: 18.25, y: 11},
];

const textModExtraShifts: Array<KeycapWithPosition> = [
    {keycap: Keycap.TEXT_SHIFT_200, x: 0, y: 10},
    {keycap: Keycap.TEXT_SHIFT_225, x: 10, y: 10},
];

const noveltyModExtraShifts: Array<KeycapWithPosition> = [
    {keycap: Keycap.STITCH_B3_200, x: 0, y: 10},
    {keycap: Keycap.STITCH_B3_225, x: 10, y: 10},
];

// TODO: move 6.25u spacebars to alphas?

const BASE_DESCRIPTION = 'Supports common TKL, 75%, 65% and 60% layouts';
// const TKL_MODS_DESCRIPTION = 'With alphas, supports TKL, 75%, 65%, 60% and HHKB.\n Includes alpha-coloured spacebars, F13 and stepped "cookie"';
const TKL_MODS_DESCRIPTION = undefined; // Looks better without?
// not sure about this wording
// const ISO_DESCRIPTION = 'Contains support for UK and terminal ISO, and ñ for Spanish keyboard layouts.';
const ERGO_DESCRIPTION = 'With alphas or base, supports Ergodox, Boardwalk, Sol';
const ORTHO_DESCRIPTION = 'With alphas or base, supports Moonlander, Planck, Preonic, Lily58, Iris, Sofle, Corne, Ergodash';
const FOURTIES_DESCRIPTION = 'With alphas or base, supports Minivan, JD40, JD45, UT47.2, prime_e, Dimple, Whimsy';
const NORDE_DESCRIPTION = 'With alphas+mods or base, supports German, Danish, Norwegian, Swedish, Finnish, \nIcelandic, Estonian and Faroese layouts. Can be combined with alternate numrow kit.';

const MIXED_BASE_KIT: KitDefinition = {
    name: KitName.MIXED_BASE,
    description: BASE_DESCRIPTION,
    keycapWithPositions: [
        ...alphas,
        ...textMod60,
        ...noveltyModTKL,
        ...baseKitShared,
    ],
};

const NOVELTY_BASE_KIT: KitDefinition = {
    name: KitName.NOVELTY_BASE,
    description: BASE_DESCRIPTION,
    keycapWithPositions: [
        ...alphas,
        ...noveltyMod60,
        ...noveltyModTKL,
        ...baseKitShared,
    ],
};

const TEXT_MODS_KIT: KitDefinition = {
    name: KitName.TEXT_MODS,
    description: TKL_MODS_DESCRIPTION,
    keycapWithPositions: [
        ...textMod60,
        ...textModTKL,
        ...textModStandalone60,
        ...textModStandaloneTKL,
        ...textModExtraShifts,
    ],
};

const MIXED_MODS_KIT: KitDefinition = {
    name: KitName.MIXED_MODS,
    description: TKL_MODS_DESCRIPTION,
    keycapWithPositions: [
        ...textMod60,
        ...noveltyModTKL,
        ...textModStandalone60,
        ...noveltyModStandaloneTKL,
        ...textModExtraShifts,
    ],
};

const NOVELTY_MODS_KIT: KitDefinition = {
    name: KitName.NOVELTY_MODS,
    description: TKL_MODS_DESCRIPTION,
    keycapWithPositions: [
        ...noveltyMod60,
        ...noveltyModTKL,
        ...noveltyModStandalone60,
        ...noveltyModStandaloneTKL,
        ...noveltyModExtraShifts,
    ],
};

// TODO: add blue 7u spacebar? 
const ALPHAS_KIT: KitDefinition = {
    name: KitName.ALPHAS,
    keycapWithPositions: [
        ...alphas,
        {keycap: Keycap.PIPE_Y, x: 13, y: 1.5},
        {keycap: Keycap.PIPE_W_150, x: 13.5, y: 2.5},
        {keycap: Keycap.PIPE_B1_150, x: 15, y: 2.5},
        {keycap: Keycap.SPACEBAR_625, x: 3.75, y: 5.5},
        {keycap: Keycap.F_HOMING_SCOOPED, x: 4.75, y: 7}, 
        // {keycap: Keycap.B, x: 6.25, y: 6},
        {keycap: Keycap.J_HOMING_SCOOPED, x: 7.75, y: 7}, 
    ],
};

const ALPHA_NUMROW_KIT: KitDefinition = {
    name: KitName.ALPHA_NUMROW,
    keycapWithPositions: [
        {keycap: Keycap.BACKTICK_W, x: 0, y: 1.5},
        {keycap: Keycap.ALPHA_1_W, x: 1, y: 1.5},
        {keycap: Keycap.ALPHA_2_W, x: 2, y: 1.5},
        {keycap: Keycap.ALPHA_3_W, x: 3, y: 1.5},
        {keycap: Keycap.ALPHA_4_W, x: 4, y: 1.5},
        {keycap: Keycap.ALPHA_5_W, x: 5, y: 1.5},
        {keycap: Keycap.ALPHA_6_W, x: 6, y: 1.5},
        {keycap: Keycap.ALPHA_7_W, x: 7, y: 1.5},
        {keycap: Keycap.ALPHA_8_W, x: 8, y: 1.5},
        {keycap: Keycap.ALPHA_9_W, x: 9, y: 1.5},
        {keycap: Keycap.ALPHA_0_W, x: 10, y: 1.5},
        {keycap: Keycap.ALPHA_DASH_W, x: 11, y: 1.5},
        {keycap: Keycap.ALPHA_EQUALS_W, x: 12, y: 1.5},
        {keycap: Keycap.PIPE_W, x: 13, y: 1.5},
        {keycap: Keycap.TEXT_BACKSPACE_B1, x: 14, y: 1.5},
        {keycap: Keycap.SCISSORS_B1_200, x: 16, y: 1.5},
    ],
};

const SPACEBARS_KIT: KitDefinition = {
    name: KitName.SPACEBARS,
    keycapWithPositions: [
        {keycap: Keycap.SPACEBAR_600, x: 0, y: 0},
        {keycap: Keycap.B, x: 6, y: 0},
        {keycap: Keycap.SPACEBAR_125, x: 0, y: 1},
        {keycap: Keycap.SPACEBAR_200, x: 1.25, y: 1},
        {keycap: Keycap.SPACEBAR_225, x: 3.25, y: 1},
        {keycap: Keycap.SPACEBAR_275, x: 5.5, y: 1},
        {keycap: Keycap.SPACEBAR_300, x: 0, y: 2},
        {keycap: Keycap.SPACEBAR_100, x: 3, y: 2},
        {keycap: Keycap.SPACEBAR_300, x: 4, y: 2},

        {keycap: Keycap.SPACEBAR_600_ALT, x: 0, y: 3},
        {keycap: Keycap.SPACEBAR_125_ALT, x: 0, y: 4},
        {keycap: Keycap.SPACEBAR_200_ALT, x: 1.25, y: 4},
        {keycap: Keycap.SPACEBAR_225_ALT, x: 3.25, y: 4},
        {keycap: Keycap.SPACEBAR_275_ALT, x: 5.5, y: 4},
        {keycap: Keycap.SPACEBAR_300_ALT, x: 0, y: 5},
        {keycap: Keycap.SPACEBAR_100_ALT, x: 3, y: 5},
        {keycap: Keycap.SPACEBAR_300_ALT, x: 4, y: 5},
    ],
};

const NUMPAD_KIT: KitDefinition = {
    name: KitName.NUMPAD,
    keycapWithPositions: [
        {keycap: Keycap.TEXT_NUM_LOCK_ALT, x: 23, y: 1.5},
        {keycap: Keycap.DIVIDE_ALT, x: 24, y: 1.5},
        {keycap: Keycap.MULTIPLY_ALT, x: 25, y: 1.5},
        {keycap: Keycap.TOMATO_B1, x: 26, y: 1.5},

        {keycap: Keycap.TOMATO_Y, x: 17, y: 1.5},
        
        {keycap: Keycap.TEXT_NUM_LOCK, x: 18.5, y: 1.5},
        {keycap: Keycap.DIVIDE, x: 19.5, y: 1.5},
        {keycap: Keycap.MULTIPLY, x: 20.5, y: 1.5},
        {keycap: Keycap.NUMPAD_MINUS_Y, x: 21.5, y: 1.5},

        {keycap: Keycap.NUMPAD_7, x: 18.5, y: 2.5},
        {keycap: Keycap.NUMPAD_8, x: 19.5, y: 2.5},
        {keycap: Keycap.NUMPAD_9, x: 20.5, y: 2.5},
        {keycap: Keycap.NUMPAD_PLUS_200, x: 21.5, y: 2.5, rotation: 90},

        {keycap: Keycap.NUMPAD_4, x: 18.5, y: 3.5},
        {keycap: Keycap.NUMPAD_5_HOMING_BARRED, x: 19.5, y: 3.5},
        {keycap: Keycap.NUMPAD_6, x: 20.5, y: 3.5},

        {keycap: Keycap.NUMPAD_1, x: 18.5, y: 4.5},
        {keycap: Keycap.NUMPAD_2, x: 19.5, y: 4.5},
        {keycap: Keycap.NUMPAD_3, x: 20.5, y: 4.5},
        {keycap: Keycap.TEXT_NUMPAD_ENTER, x: 21.5, y: 4.5, rotation: 90},

        {keycap: Keycap.NUMPAD_0_200, x: 18.5, y: 5.5},
        {keycap: Keycap.NUMPAD_PERIOD, x: 20.5, y: 5.5},

        {keycap: Keycap.NUMPAD_5_HOMING_SCOOPED, x: 24, y: 3.5}, 
        {keycap: Keycap.NUMPAD_0_100, x: 23, y: 5.5},
        {keycap: Keycap.NUMPAD_00, x: 24, y: 5.5},

        {keycap: Keycap.NUMPAD_MINUS_B1, x: 26, y: 2.5},
        {keycap: Keycap.NUMPAD_PLUS_100, x: 26, y: 3.5},
        {keycap: Keycap.TAPE_B3_200, x: 26, y: 4.5, rotation: 90},
    ],
};

const COLEVRAK_KIT: KitDefinition = {
    name: KitName.COLEVRAK,
    keycapWithPositions: [
        {keycap: Keycap.H_HOMING_BARRED, x: 0, y: 0},
        {keycap: Keycap.U_HOMING_BARRED, x: 1, y: 0},
        {keycap: Keycap.N_HOMING_BARRED, x: 2, y: 0},
        {keycap: Keycap.T_HOMING_BARRED, x: 3, y: 0},
        {keycap: Keycap.A_HOMING_BARRED, x: 4, y: 0},
        {keycap: Keycap.H_HOMING_SCOOPED, x: 0, y: 1},
        {keycap: Keycap.U_HOMING_SCOOPED, x: 1, y: 1},
        {keycap: Keycap.N_HOMING_SCOOPED, x: 2, y: 1},
        {keycap: Keycap.T_HOMING_SCOOPED, x: 3, y: 1},
        {keycap: Keycap.A_HOMING_SCOOPED, x: 4, y: 1},
        {keycap: Keycap.ALPHA_DASH_W, x: 0, y: 2},
        {keycap: Keycap.ALPHA_EQUALS_W, x: 1, y: 2},
        {keycap: Keycap.LEFT_BRACKET_Y, x: 2, y: 2},
        {keycap: Keycap.RIGHT_BRACKET_Y, x: 3, y: 2},
        {keycap: Keycap.F, x: 0, y: 3},
        {keycap: Keycap.J, x: 1, y: 3},
    ],
};

const ISO_KIT: KitDefinition = {
    name: KitName.ISO, 
    // description: ISO_DESCRIPTION,
    keycapWithPositions: [
        {keycap: Keycap.UK_ISO_BACKTICK, x: 0, y: 0},
        {keycap: Keycap.UK_ISO_2, x: 1, y: 0},
        {keycap: Keycap.UK_ISO_3, x: 2, y: 0},
        {keycap: Keycap.UK_ISO_BACKTICK_W, x: 3, y: 0},
        {keycap: Keycap.UK_ISO_2_W, x: 4, y: 0},
        {keycap: Keycap.UK_ISO_3_W, x: 5, y: 0},

        {keycap: Keycap.UK_ISO_SINGLE_QUOTE, x: 2.25, y: 2},
        {keycap: Keycap.UK_ISO_POUND, x: 3.25, y: 2},
        {keycap: Keycap.NEEDLE_ISO_ENTER, x: 4, y: 1},

        {keycap: Keycap.TEXT_ISO_ENTER, x: 8.75, y: 1},

        {keycap: Keycap.STITCH_B3_125, x: 0, y: 3},
        {keycap: Keycap.PIPE_W, x: 1.25, y: 3},
        
        {keycap: Keycap.N_TILDE, x: 2.75, y: 3},

        {keycap: Keycap.TEXT_SHIFT_125, x: 6, y: 3},
        {keycap: Keycap.ISO_ANGLE_BRACKET, x: 7.25, y: 3},
    ],
};

const ERGO_KIT: KitDefinition = {
    name: KitName.ERGO,
    description: ERGO_DESCRIPTION,
    keycapWithPositions: [
        {keycap: Keycap.TIN_Y_150, x: 0, y: 0},
        {keycap: Keycap.TOMATO_Y, x: 6.5, y: 0},
        {keycap: Keycap.TOMATO_Y, x: 11, y: 0},
        {keycap: Keycap.TIN_Y_150, x: 17, y: 0},

        {keycap: Keycap.BOBBIN_B1_150, x: 0, y: 1},
        {keycap: Keycap.PIN_B1_150, x: 6.5, y: 1, rotation: 270},
        {keycap: Keycap.PIN_B1_150, x: 11, y: 1, rotation: 270},
        {keycap: Keycap.BOBBIN_B1_150, x: 17, y: 1},

        {keycap: Keycap.COOKIE_SWIRLY_B1, x: 8, y: 1},
        {keycap: Keycap.COOKIE_RECTANGLE_B1, x: 9.5, y: 1},
        {keycap: Keycap.COOKIE_CIRCLE_B2, x: 8, y: 2},
        {keycap: Keycap.COOKIE_PRETZEL_B2, x: 9.5, y: 2},

        {keycap: Keycap.STORK_B2_150, x: 0, y: 2, rotation: 180},
        {keycap: Keycap.STORK_B2_150, x: 17, y: 2},

        {keycap: Keycap.PIN_B2_150, x: 6.5, y: 2.5, rotation: 90},
        {keycap: Keycap.PIN_B2_150, x: 11, y: 2.5, rotation: 90},

        {keycap: Keycap.STITCH_B3_150, x: 0, y: 3},
        {keycap: Keycap.STITCH_B3_150, x: 17, y: 3},

        {keycap: Keycap.PIN_B4_150, x: 0, y: 5.5, rotation: 180},
        {keycap: Keycap.PIN_B4_150, x: 17, y: 5.5},

        {keycap: Keycap.BUTTON_CIRCLE_B4, x: 0.5, y: 4},
        {keycap: Keycap.BUTTON_FLOWER_B4, x: 1.5, y: 4},
        {keycap: Keycap.BUTTON_HEART_B4, x: 2.5, y: 4},
        {keycap: Keycap.ARROW_LEFT, x: 3.5, y: 4},
        {keycap: Keycap.ARROW_RIGHT, x: 4.5, y: 4},

        {keycap: Keycap.ARROW_UP_B4, x: 13, y: 4},
        {keycap: Keycap.ARROW_DOWN, x: 14, y: 4},
        {keycap: Keycap.BUTTON_HEART_B4, x: 15, y: 4},
        {keycap: Keycap.BUTTON_FLOWER_B4, x: 16, y: 4},
        {keycap: Keycap.BUTTON_CIRCLE_B4, x: 17, y: 4},

        {keycap: Keycap.BLANK_B4_200, x: 2, y: 5.5, rotation: 90},

        {keycap: Keycap.SPACEBAR_200, x: 3, y: 5.5, rotation: 90},
        {keycap: Keycap.SPACEBAR_200, x: 4, y: 5.5, rotation: 90},

        {keycap: Keycap.SPACEBAR_200, x: 13.5, y: 5.5, rotation: 90},
        {keycap: Keycap.SPACEBAR_200, x: 14.5, y: 5.5, rotation: 90},

        {keycap: Keycap.BLANK_B4_200, x: 15.5, y: 5.5, rotation: 90},

        {keycap: Keycap.STITCH_A_B4_200, x: 5.5, y: 5.5, rotation: 90},
        {keycap: Keycap.STITCH_B_B4_200, x: 6.5, y: 5.5, rotation: 90},
        {keycap: Keycap.COOKIE_SWIRLY_B3, x: 6.5, y: 4.5},
        {keycap: Keycap.COOKIE_RECTANGLE_B3, x: 7.5, y: 4.5},
        {keycap: Keycap.COOKIE_PRETZEL_B4_100, x: 7.5, y: 5.5},
        {keycap: Keycap.COOKIE_CIRCLE_B4_100, x: 7.5, y: 6.5},

        {keycap: Keycap.COOKIE_SWIRLY_B4_100, x: 10, y: 6.5},
        {keycap: Keycap.COOKIE_RECTANGLE_B4_100, x: 10, y: 5.5},
        {keycap: Keycap.COOKIE_PRETZEL_B3, x: 10, y: 4.5},
        {keycap: Keycap.COOKIE_CIRCLE_B3, x: 11, y: 4.5},
        {keycap: Keycap.STITCH_C_B4_200, x: 11, y: 5.5, rotation: 90},
        {keycap: Keycap.STITCH_D_B4_200, x: 12, y: 5.5, rotation: 90},
    ],
};

const ORTHO_KIT: KitDefinition = {
    name: KitName.ORTHO,
    description: ORTHO_DESCRIPTION,
    keycapWithPositions: [
        {keycap: Keycap.TIN_Y_100, x: 0, y: 0},
        {keycap: Keycap.TOMATO_Y, x: 11, y: 0},
        {keycap: Keycap.TIN_Y_100, x: 12.5, y: 0},
        {keycap: Keycap.TOMATO_Y, x: 13.5, y: 0},

        {keycap: Keycap.COOKIE_SWIRLY_B1, x: 0, y: 1},
        {keycap: Keycap.COOKIE_RECTANGLE_B1, x: 11, y: 1},
        {keycap: Keycap.COOKIE_PRETZEL_B1, x: 12.5, y: 1},
        {keycap: Keycap.COOKIE_CIRCLE_B1, x: 13.5, y: 1},

        {keycap: Keycap.COOKIE_PRETZEL_B2, x: 0, y: 2},
        {keycap: Keycap.COOKIE_CIRCLE_B2, x: 11, y: 2},
        {keycap: Keycap.COOKIE_SWIRLY_B2, x: 12.5, y: 2},
        {keycap: Keycap.COOKIE_RECTANGLE_B2, x: 13.5, y: 2},

        {keycap: Keycap.STITCH_B3_100, x: 0, y: 3},
        {keycap: Keycap.BOBBIN_B3_100, x: 11, y: 3},
        {keycap: Keycap.STITCH_B3_100, x: 12.5, y: 3},
        {keycap: Keycap.BOBBIN_B3_100, x: 13.5, y: 3},

        {keycap: Keycap.BUTTON_CIRCLE_B4, x: 0, y: 4},
        {keycap: Keycap.BUTTON_HEART_B4, x: 1, y: 4},
        {keycap: Keycap.BUTTON_FLOWER_B4, x: 2, y: 4},
        {keycap: Keycap.BUTTON_CIRCLE_B4, x: 3, y: 4},
        {keycap: Keycap.BUTTON_HEART_B4, x: 4, y: 4},
        {keycap: Keycap.SPACEBAR_200, x: 5, y: 4},
        {keycap: Keycap.BUTTON_FLOWER_B4, x: 7, y: 4},
        {keycap: Keycap.ARROW_LEFT, x: 8, y: 4},
        {keycap: Keycap.ARROW_RIGHT, x: 9, y: 4},
        {keycap: Keycap.ARROW_UP_B4, x: 10, y: 4},
        {keycap: Keycap.ARROW_DOWN, x: 11, y: 4},

        {keycap: Keycap.COOKIE_SWIRLY_B4_100, x: 12.5, y: 4},
        {keycap: Keycap.COOKIE_RECTANGLE_B4_100, x: 13.5, y: 4},
        {keycap: Keycap.COOKIE_PRETZEL_B4_100, x: 14.5, y: 4},
        {keycap: Keycap.COOKIE_CIRCLE_B4_100, x: 15.5, y: 4},
        {keycap: Keycap.STITCH_A_B4_200, x: 16.5, y: 4},
        {keycap: Keycap.STITCH_D_B4_200, x: 18.5, y: 4},

        {keycap: Keycap.SPACEBAR_100, x: 3, y: 5},
        {keycap: Keycap.SPACEBAR_100, x: 4, y: 5},
        {keycap: Keycap.SPACEBAR_200, x: 5, y: 5},
        {keycap: Keycap.SPACEBAR_200_ALT, x: 7, y: 6},
        {keycap: Keycap.SPACEBAR_200_ALT, x: 5, y: 6},
        {keycap: Keycap.SPACEBAR_100_ALT, x: 3, y: 6},
        {keycap: Keycap.SPACEBAR_100_ALT, x: 4, y: 6},

        {keycap: Keycap.ZIGZAG_B3_200, x: 14.5, y: 3},
        {keycap: Keycap.ZIGZAG_B3_200, x: 16.5, y: 3},

        {keycap: Keycap.STORK_B4_150, x: 12.5, y: 5, rotation: 270},
        {keycap: Keycap.STORK_B4_150, x: 13.5, y: 5, rotation: 270},

        {keycap: Keycap.STITCH_B4_150, x: 14.5, y: 5, rotation: 270},
        {keycap: Keycap.STITCH_B4_150, x: 15.5, y: 5, rotation: 270},

        {keycap: Keycap.PIN_B4_150, x: 16.5, y: 5, rotation: 270},
        {keycap: Keycap.PIN_B4_150, x: 17.5, y: 5, rotation: 270},

        {keycap: Keycap.SPACEBAR_150, x: 18.5, y: 5, rotation: 270},
        {keycap: Keycap.SPACEBAR_150, x: 19.5, y: 5, rotation: 270},
    ],
};

const FOURTIES_KIT: KitDefinition = {
    name: KitName.FOURTIES,
    description: FOURTIES_DESCRIPTION,
    keycapWithPositions: [
        // frankly im amazed the negative x actually workedj
        {keycap: Keycap.TIN_Y_100, x: -1, y: 2.5},
        {keycap: Keycap.STORK_Y_150, x: 17.75, y: 2.5},
        {keycap: Keycap.STORK_Y_175, x: 19.25, y: 2.5},

        {keycap: Keycap.TIN_B1_100, x: 0.5, y: 2.5},
        {keycap: Keycap.STORK_B1_150, x: 11.5, y: 2.5},

        {keycap: Keycap.STORK_B1_175, x: 13.5, y: 2.5},
        {keycap: Keycap.TOMATO_B1, x: 15.25, y: 2.5},
        {keycap: Keycap.COOKIE_CIRCLE_B1, x: 16.25, y: 2.5},

        {keycap: Keycap.BOBBIN_B2_125, x: 0.5, y: 3.5},
        {keycap: Keycap.TOMATO_B2_125, x: 11.75, y: 3.5},

        {keycap: Keycap.PIN_B2_175, x: 13.5, y: 3.5, rotation: 180}, // make it the same direction as the other pin
        {keycap: Keycap.PIN_B2_150, x: 15.25, y: 3.5},

        {keycap: Keycap.STITCH_B3_150, x: 0.5, y: 4.5},
        {keycap: Keycap.STITCH_B3_100, x: 12, y: 4.5},

        {keycap: Keycap.STITCH_B3_175, x: 13.5, y: 4.5},
        {keycap: Keycap.STITCH_B3_175, x: 15.25, y: 4.5},
        {keycap: Keycap.STITCH_B3_125, x: 17, y: 4.5},
        {keycap: Keycap.ARROW_UP_B3, x: 18.75, y: 4.5},


        {keycap: Keycap.BUTTON_CIRCLE_B4, x: 0.5, y: 5.5},
        {keycap: Keycap.BUTTON_FLOWER_B4, x: 1.5, y: 5.5},
        {keycap: Keycap.BUTTON_HEART_B4, x: 2.5, y: 5.5},
        {keycap: Keycap.BUTTON_CIRCLE_B4, x: 3.5, y: 5.5},
        {keycap: Keycap.COOKIE_CIRCLE_B4_125, x: 4.5, y: 5.5},
        {keycap: Keycap.SPACEBAR_200, x: 5.75, y: 5.5},
        {keycap: Keycap.COOKIE_CIRCLE_B4_125, x: 7.75, y: 5.5},
        {keycap: Keycap.ARROW_LEFT, x: 9, y: 5.5},
        {keycap: Keycap.ARROW_DOWN, x: 10, y: 5.5},
        {keycap: Keycap.ARROW_UP_B4, x: 11, y: 5.5},
        {keycap: Keycap.ARROW_RIGHT, x: 12, y: 5.5},

        {keycap: Keycap.ZIGZAG_B4_175, x: 13.5, y: 5.5},

        {keycap: Keycap.BUTTON_FLOWER_B4, x: 0.5, y: 6.5},
        {keycap: Keycap.BUTTON_HEART_B4, x: 1.5, y: 6.5},
        {keycap: Keycap.COOKIE_SWIRLY_B4_125, x: 2.5, y: 6.5},
        {keycap: Keycap.COOKIE_SWIRLY_B4_125, x: 3.75, y: 6.5},
        {keycap: Keycap.COOKIE_RECTANGLE_B4_125, x: 5, y: 6.5},
        {keycap: Keycap.COOKIE_RECTANGLE_B4_125, x: 6.25, y: 6.5},
        {keycap: Keycap.COOKIE_PRETZEL_B4_125, x: 7.5, y: 6.5},
        {keycap: Keycap.COOKIE_PRETZEL_B4_125, x: 8.75, y: 6.5},
        {keycap: Keycap.COOKIE_CIRCLE_B4_150, x: 10, y: 6.5},
        {keycap: Keycap.COOKIE_CIRCLE_B4_150, x: 11.5, y: 6.5},

        {keycap: Keycap.SPACEBAR_175, x: 0.5, y: 8},
        {keycap: Keycap.SPACEBAR_175, x: 2.25, y: 8},
        {keycap: Keycap.SPACEBAR_225, x: 6, y: 8},
        {keycap: Keycap.SPACEBAR_225, x: 8.25, y: 8},
        {keycap: Keycap.SPACEBAR_275, x: 10.5, y: 8},
        // {keycap: Keycap.SPACEBAR_625, x: 13.25, y: 8},

        {keycap: Keycap.SPACEBAR_175_ALT, x: 0.5, y: 9},
        {keycap: Keycap.SPACEBAR_175_ALT, x: 2.25, y: 9},
        {keycap: Keycap.SPACEBAR_200_ALT, x: 4, y: 9},
        {keycap: Keycap.SPACEBAR_225_ALT, x: 6, y: 9},
        {keycap: Keycap.SPACEBAR_225_ALT, x: 8.25, y: 9},
        {keycap: Keycap.SPACEBAR_275_ALT, x: 10.5, y: 9},
        {keycap: Keycap.SPACEBAR_625_ALT, x: 13.25, y: 9},
    ],
};

const COOKIES_KIT: KitDefinition = {
    name: KitName.COOKIES,
    keycapWithPositions: [
        {keycap: Keycap.COOKIE_SWIRLY_Y, x: 0, y: 0},
        {keycap: Keycap.COOKIE_RECTANGLE_Y, x: 1, y: 0},
        {keycap: Keycap.COOKIE_PRETZEL_Y, x: 2, y: 0},
        {keycap: Keycap.COOKIE_CIRCLE_Y, x: 3, y: 0},
        {keycap: Keycap.COOKIE_SWIRLY_Y, x: 0, y: 1},
        {keycap: Keycap.COOKIE_RECTANGLE_Y, x: 1, y: 1},
        {keycap: Keycap.COOKIE_PRETZEL_Y, x: 2, y: 1},
        {keycap: Keycap.COOKIE_CIRCLE_Y, x: 3, y: 1},
        {keycap: Keycap.COOKIE_SWIRLY_B1, x: 0, y: 2},
        {keycap: Keycap.COOKIE_RECTANGLE_B1, x: 1, y: 2},
        {keycap: Keycap.COOKIE_PRETZEL_B1, x: 2, y: 2},
        {keycap: Keycap.COOKIE_CIRCLE_B1, x: 3, y: 2},
        {keycap: Keycap.COOKIE_SWIRLY_B2, x: 0, y: 3},
        {keycap: Keycap.COOKIE_RECTANGLE_B2, x: 1, y: 3},
        {keycap: Keycap.COOKIE_PRETZEL_B2, x: 2, y: 3},
        {keycap: Keycap.COOKIE_CIRCLE_B2, x: 3, y: 3},
        {keycap: Keycap.COOKIE_SWIRLY_B3, x: 0, y: 4},
        {keycap: Keycap.COOKIE_RECTANGLE_B3, x: 1, y: 4},
        {keycap: Keycap.COOKIE_PRETZEL_B3, x: 2, y: 4},
        {keycap: Keycap.COOKIE_CIRCLE_B3, x: 3, y: 4},
        {keycap: Keycap.COOKIE_SWIRLY_B4_100, x: 0, y: 5},
        {keycap: Keycap.COOKIE_RECTANGLE_B4_100, x: 1, y: 5},
        {keycap: Keycap.COOKIE_PRETZEL_B4_100, x: 2, y: 5},
        {keycap: Keycap.COOKIE_CIRCLE_B4_100, x: 3, y: 5},
    ],
};

const BUTTONS_KIT: KitDefinition = {
    name: KitName.BUTTONS,
    keycapWithPositions: [
        {keycap: Keycap.BUTTON_CIRCLE_Y, x: 0, y: 0},
        {keycap: Keycap.BUTTON_HEART_Y, x: 1, y: 0},
        {keycap: Keycap.BUTTON_FLOWER_Y, x: 2, y: 0},
        {keycap: Keycap.BUTTON_CIRCLE_Y, x: 0, y: 1},
        {keycap: Keycap.BUTTON_HEART_Y, x: 1, y: 1},
        {keycap: Keycap.BUTTON_FLOWER_Y, x: 2, y: 1},
        {keycap: Keycap.BUTTON_CIRCLE_B1, x: 0, y: 2},
        {keycap: Keycap.BUTTON_HEART_B1, x: 1, y: 2},
        {keycap: Keycap.BUTTON_FLOWER_B1, x: 2, y: 2},
        {keycap: Keycap.BUTTON_CIRCLE_B2, x: 0, y: 3},
        {keycap: Keycap.BUTTON_HEART_B2, x: 1, y: 3},
        {keycap: Keycap.BUTTON_FLOWER_B2, x: 2, y: 3},
        {keycap: Keycap.BUTTON_CIRCLE_B3, x: 0, y: 4},
        {keycap: Keycap.BUTTON_HEART_B3, x: 1, y: 4},
        {keycap: Keycap.BUTTON_FLOWER_B3, x: 2, y: 4},
        {keycap: Keycap.BUTTON_CIRCLE_B4, x: 0, y: 5},
        {keycap: Keycap.BUTTON_HEART_B4, x: 1, y: 5},
        {keycap: Keycap.BUTTON_FLOWER_B4, x: 2, y: 5},
    ],
};

const YELLOW_ACCENTS_KIT: KitDefinition = {
    name: KitName.YELLOW_ACCENTS,
    keycapWithPositions: [
        {keycap: Keycap.SPACEBAR_625_Y, x: 0, y: 0},
        {keycap: Keycap.SPACEBAR_700_Y, x: 0, y: 1},
        {keycap: Keycap.SPACEBAR_200_Y, x: 0, y: 2},
        {keycap: Keycap.SPACEBAR_225_Y, x: 2, y: 2},
        {keycap: Keycap.SPACEBAR_275_Y, x: 4.25, y: 2},
        {keycap: Keycap.SPACEBAR_200_Y, x: 0, y: 3},
        {keycap: Keycap.SPACEBAR_225_Y, x: 2, y: 3},
        {keycap: Keycap.SPACEBAR_150_Y, x: 4.25, y: 3},
        {keycap: Keycap.SPACEBAR_150_Y, x: 5.75, y: 3},

        {keycap: Keycap.ARROW_UP_Y, x: 9, y: 1},
        {keycap: Keycap.ARROW_LEFT_Y, x: 8, y: 2},
        {keycap: Keycap.ARROW_DOWN_Y, x: 9, y: 2},
        {keycap: Keycap.ARROW_RIGHT_Y, x: 10, y: 2},

    ],
}

const NORDE_KIT: KitDefinition = {
    name: KitName.NORDE,
    description: NORDE_DESCRIPTION,
    keycapWithPositions: [
        {keycap: Keycap.NORDE_CARAT, x: 0, y: 0},
        {keycap: Keycap.NORDE_HALF, x: 1, y: 0},
        {keycap: Keycap.NORDE_PIPE, x: 2, y: 0},
        {keycap: Keycap.NORDE_PILCROW, x: 3, y: 0},
        {keycap: Keycap.NORDE_OVERRING, x: 4, y: 0},
        {keycap: Keycap.NORDE_CARON, x: 5, y: 0},
        {keycap: Keycap.NORDE_3, x: 7, y: 0},
        {keycap: Keycap.NORDE_4, x: 8, y: 0},
        {keycap: Keycap.NORDE_6, x: 9, y: 0},
        {keycap: Keycap.NORDE_7, x: 10, y: 0},
        {keycap: Keycap.NORDE_8, x: 11, y: 0},
        {keycap: Keycap.NORDE_9, x: 12, y: 0},
        {keycap: Keycap.NORDE_0, x: 13, y: 0},
        {keycap: Keycap.NORDE_ESZETT, x: 14, y: 0},
        {keycap: Keycap.NORDE_ACUTE_ACCENT, x: 15, y: 0},
        {keycap: Keycap.NORDE_PLUS_QUESTION, x: 16, y: 0},
        {keycap: Keycap.NORDE_BACKSLASH, x: 17, y: 0},

        {keycap: Keycap.NORDE_CARAT_Y, x: 0, y: 1},
        {keycap: Keycap.NORDE_HALF_Y, x: 1, y: 1},
        {keycap: Keycap.NORDE_PIPE_Y, x: 2, y: 1},
        {keycap: Keycap.NORDE_PILCROW_Y, x: 3, y: 1},
        {keycap: Keycap.NORDE_OVERRING_Y, x: 4, y: 1},
        {keycap: Keycap.NORDE_CARON_Y, x: 5, y: 1}, 
        {keycap: Keycap.NORDE_3_Y, x: 7, y: 1},
        {keycap: Keycap.NORDE_4_Y, x: 8, y: 1},
        {keycap: Keycap.NORDE_6_Y, x: 9, y: 1},
        {keycap: Keycap.NORDE_7_Y, x: 10, y: 1},
        {keycap: Keycap.NORDE_8_Y, x: 11, y: 1},
        {keycap: Keycap.NORDE_9_Y, x: 12, y: 1},
        {keycap: Keycap.NORDE_0_Y, x: 13, y: 1},
        {keycap: Keycap.NORDE_ESZETT_Y, x: 14, y: 1},
        {keycap: Keycap.NORDE_ACUTE_ACCENT_Y, x: 15, y: 1},
        {keycap: Keycap.NORDE_PLUS_QUESTION_Y, x: 16, y: 1},
        {keycap: Keycap.NORDE_BACKSLASH_Y, x: 17, y: 1},
        {keycap: Keycap.NORDE_O_UMLAUT_Y, x: 18, y: 1},

        {keycap: Keycap.NORDE_U_UMLAUT, x: 0, y: 2},
        {keycap: Keycap.NORDE_PLUS_ASTERISK, x: 1, y: 2},
        {keycap: Keycap.NORDE_A_RING, x: 2, y: 2},
        {keycap: Keycap.NORDE_UMLAUT, x: 3, y: 2},
        {keycap: Keycap.NORDE_ETH, x: 4, y: 2},
        {keycap: Keycap.NORDE_SINGLE_QUOTE_QUESTION, x: 5, y: 2},
        {keycap: Keycap.NORDE_O_TILDE, x: 6, y: 2},

        {keycap: Keycap.NORDE_O_UMLAUT, x: 0, y: 3},
        {keycap: Keycap.NORDE_A_UMLAUT, x: 1, y: 3},
        {keycap: Keycap.NORDE_POUND, x: 2, y: 3},
        {keycap: Keycap.NORDE_AESH, x: 3, y: 3},
        {keycap: Keycap.NORDE_O_SLASH, x: 4, y: 3},
        {keycap: Keycap.NORDE_SINGLE_QUOTE_ASTERISK, x: 5, y: 3},
        {keycap: Keycap.NORDE_BACKTICK, x: 6, y: 3},

        {keycap: Keycap.NORDE_COMMA, x: 4, y: 4},
        {keycap: Keycap.NORDE_PERIOD, x: 5, y: 4},
        {keycap: Keycap.ALPHA_DASH_W, x: 6, y: 4},
        {keycap: Keycap.NORDE_THORN, x: 7, y: 4},

        {keycap: Keycap.UK_ISO_2, x: 6, y: 1},
        {keycap: Keycap.UK_ISO_2_W, x: 6, y: 0},

        {keycap: Keycap.NEEDLE_ISO_ENTER, x: 16, y: 2},
        {keycap: Keycap.TEXT_ISO_ENTER, x: 17.5, y: 2},

        {keycap: Keycap.STITCH_B3_125, x: 0, y: 4},
        {keycap: Keycap.TEXT_SHIFT_125, x: 1.25, y: 4},
        {keycap: Keycap.ISO_ANGLE_BRACKET, x: 3, y: 4},
    ],
}

export const AllKitDefinitions = {
    MIXED_BASE_KIT,
    NOVELTY_BASE_KIT,
    TEXT_MODS_KIT,
    MIXED_MODS_KIT,
    NOVELTY_MODS_KIT,
    ALPHAS_KIT,
    ALPHA_NUMROW_KIT,
    SPACEBARS_KIT,
    NUMPAD_KIT,
    COLEVRAK_KIT,
    ISO_KIT,
    NORDE_KIT,
    YELLOW_ACCENTS_KIT,
    ERGO_KIT,
    ORTHO_KIT,
    FOURTIES_KIT,
    COOKIES_KIT,
    BUTTONS_KIT,
};
