import { Key } from "./keys";
import { Keycap } from "./keyset";

export type KeysetMap = Map<Key, Keycap>;

// would be nice to have auto matching for matched names
export const DEFAULT_KEYSET_MAP: KeysetMap = new Map([
    [Key.NUMROW_ESC, Keycap.TIN_Y_100],
    [Key.BACKTICK, Keycap.BACKTICK],
    [Key.ALPHA_1, Keycap.ALPHA_1],
    [Key.ALPHA_2, Keycap.ALPHA_2],
    [Key.ALPHA_3, Keycap.ALPHA_3],
    [Key.ALPHA_4, Keycap.ALPHA_4],
    [Key.ALPHA_5, Keycap.ALPHA_5],
    [Key.ALPHA_6, Keycap.ALPHA_6],
    [Key.ALPHA_7, Keycap.ALPHA_7],
    [Key.ALPHA_8, Keycap.ALPHA_8],
    [Key.ALPHA_9, Keycap.ALPHA_9],
    [Key.ALPHA_0, Keycap.ALPHA_0],
    [Key.ALPHA_DASH, Keycap.ALPHA_DASH],
    [Key.ALPHA_EQUALS, Keycap.ALPHA_EQUALS],

    [Key.Q, Keycap.Q],
    [Key.W, Keycap.W],
    [Key.E, Keycap.E],
    [Key.R, Keycap.R],
    [Key.T, Keycap.T],
    [Key.Y, Keycap.Y],
    [Key.U, Keycap.U],
    [Key.I, Keycap.I],
    [Key.O, Keycap.O],
    [Key.P, Keycap.P],
    [Key.LEFT_BRACKET, Keycap.LEFT_BRACKET],
    [Key.RIGHT_BRACKET, Keycap.RIGHT_BRACKET],
    [Key.PIPE_150, Keycap.PIPE_B1_150],

    [Key.A, Keycap.A],
    [Key.S, Keycap.S],
    [Key.D, Keycap.D],
    [Key.F, Keycap.F_HOMING_BARRED],
    [Key.G, Keycap.G],
    [Key.H, Keycap.H],
    [Key.J, Keycap.J_HOMING_BARRED],
    [Key.K, Keycap.K],
    [Key.L, Keycap.L],
    [Key.SEMICOLON, Keycap.SEMICOLON],
    [Key.SINGLE_QUOTE, Keycap.SINGLE_QUOTE],

    [Key.Z, Keycap.Z],
    [Key.X, Keycap.X],
    [Key.C, Keycap.C],
    [Key.V, Keycap.V],
    [Key.B, Keycap.B],
    [Key.N, Keycap.N],
    [Key.M, Keycap.M],
    [Key.COMMA, Keycap.COMMA],
    [Key.PERIOD, Keycap.PERIOD],
    [Key.SLASH, Keycap.SLASH],

    /* Arrows */
    [Key.ARROW_UP, Keycap.ARROW_UP_B3],
    [Key.ARROW_UP_ALT, Keycap.ARROW_UP_B4],
    [Key.ARROW_LEFT, Keycap.ARROW_LEFT],
    [Key.ARROW_DOWN, Keycap.ARROW_DOWN],
    [Key.ARROW_RIGHT, Keycap.ARROW_RIGHT],

    /* TKL */
    [Key.ESC, Keycap.TIN_B1_100],
    [Key.F1, Keycap.COOKIE_SWIRLY_B2],
    [Key.F2, Keycap.COOKIE_RECTANGLE_B2],
    [Key.F3, Keycap.COOKIE_PRETZEL_B2],
    [Key.F4, Keycap.COOKIE_CIRCLE_B2],
    [Key.F5, Keycap.COOKIE_SWIRLY_B3],
    [Key.F6, Keycap.COOKIE_RECTANGLE_B3],
    [Key.F7, Keycap.COOKIE_PRETZEL_B3],
    [Key.F8, Keycap.COOKIE_CIRCLE_B3],
    [Key.F9, Keycap.COOKIE_SWIRLY_B4_100],
    [Key.F10, Keycap.COOKIE_RECTANGLE_B4_100],
    [Key.F11, Keycap.COOKIE_PRETZEL_B4_100],
    [Key.F12, Keycap.COOKIE_CIRCLE_B4_100],
    [Key.PRT_SC, Keycap.BUTTON_FLOWER_B1],
    [Key.SCROLL, Keycap.BUTTON_CIRCLE_B1],
    [Key.PAUSE, Keycap.BUTTON_HEART_B1],
    [Key.INSERT, Keycap.BUTTON_CIRCLE_B2],
    [Key.HOME, Keycap.BUTTON_HEART_B2],
    [Key.PG_UP, Keycap.BUTTON_FLOWER_B2],
    [Key.DELETE, Keycap.BUTTON_HEART_B2],
    [Key.END, Keycap.BUTTON_FLOWER_B2],
    [Key.PG_DN, Keycap.BUTTON_CIRCLE_B2],

    /* base kit lacks enough B1 for this and the macro column - use the B2 */
    [Key.PRT_SC_75, Keycap.BUTTON_FLOWER_B2],
    [Key.SCROLL_75, Keycap.BUTTON_CIRCLE_B2],
    [Key.PAUSE_75, Keycap.BUTTON_HEART_B2],

    [Key.BACKSPACE_200, Keycap.SCISSORS_Y_200],
    [Key.BACKSPACE_200_ALT, Keycap.SCISSORS_B1_200],
    [Key.TAB_150, Keycap.BOBBIN_B1_150],
    [Key.CAPSLOCK_175, Keycap.PIN_B2_175],
    [Key.STEPPED_CAPSLOCK_175, Keycap.TOMATO_B2_175_STEPPED],
    [Key.ENTER_225, Keycap.NEEDLE_AND_THREAD_B2_225],
    [Key.SHIFT_225, Keycap.STITCH_B3_225],
    [Key.SHIFT_275, Keycap.STITCH_B3_275],
    [Key.CONTROL_125, Keycap.COOKIE_SWIRLY_B4_125],
    [Key.CODE_125, Keycap.COOKIE_CIRCLE_B4_125],
    [Key.ALT_125, Keycap.COOKIE_PRETZEL_B4_125],
    [Key.MENU_125, Keycap.COOKIE_RECTANGLE_B4_125],

    /* 65/75 */
    [Key.MACRO_1, Keycap.TOMATO_Y],
    [Key.MACRO_2, Keycap.BUTTON_FLOWER_B1],
    [Key.MACRO_3, Keycap.BUTTON_CIRCLE_B2],
    [Key.MACRO_4, Keycap.BUTTON_HEART_B3],

    /* Numpad */
    [Key.NUM_LOCK, Keycap.TOMATO_Y],
    [Key.NUM_LOCK_ALT, Keycap.TOMATO_B1],
    [Key.DIVIDE, Keycap.DIVIDE],
    [Key.MULTIPLY, Keycap.MULTIPLY],
    [Key.NUMPAD_MINUS, Keycap.NUMPAD_MINUS_Y],
    [Key.NUMPAD_9, Keycap.NUMPAD_9], 
    [Key.NUMPAD_8, Keycap.NUMPAD_8], 
    [Key.NUMPAD_7, Keycap.NUMPAD_7], 
    [Key.NUMPAD_6, Keycap.NUMPAD_6], 
    [Key.NUMPAD_5, Keycap.NUMPAD_5], 
    [Key.NUMPAD_4, Keycap.NUMPAD_4], 
    [Key.NUMPAD_3, Keycap.NUMPAD_3], 
    [Key.NUMPAD_2, Keycap.NUMPAD_2], 
    [Key.NUMPAD_1, Keycap.NUMPAD_1], 
    [Key.NUMPAD_0_200, Keycap.NUMPAD_0_200], 
    [Key.NUMPAD_0_100, Keycap.NUMPAD_0_100], 
    [Key.NUMPAD_00, Keycap.NUMPAD_00], 
    [Key.NUMPAD_PERIOD, Keycap.NUMPAD_PERIOD], 
    [Key.NUMPAD_ENTER, Keycap.TAPE_B3_200], 
    [Key.NUMPAD_PLUS_200, Keycap.NUMPAD_PLUS_200], 
    [Key.NUMPAD_PLUS_100, Keycap.NUMPAD_PLUS_100], 

    /* ISO */
    [Key.ISO_ENTER, Keycap.NEEDLE_ISO_ENTER],
    [Key.ISO_PIPE, Keycap.PIPE_W], 
    [Key.ISO_ANGLE_BRACKET, Keycap.ISO_ANGLE_BRACKET],
    [Key.UK_ISO_BACKTICK, Keycap.UK_ISO_BACKTICK],
    [Key.UK_ISO_2, Keycap.UK_ISO_2],
    [Key.UK_ISO_3, Keycap.UK_ISO_3],
    [Key.UK_ISO_SINGLE_QUOTE, Keycap.UK_ISO_SINGLE_QUOTE],
    [Key.UK_ISO_POUND, Keycap.UK_ISO_POUND],
    [Key.SHIFT_125, Keycap.STITCH_B3_125],

    /* Tsangan/HHKB/WKL */
    [Key.TSANGAN_PIPE, Keycap.PIPE_Y],
    [Key.DELETE_150, Keycap.STORK_B1_150], // R2
    [Key.CONTROL_175, Keycap.PIN_B2_175], // R3
    [Key.SHIFT_175, Keycap.STITCH_B3_175],
    [Key.FN, Keycap.BUTTON_CIRCLE_B3],
    [Key.CONTROL_150, Keycap.COOKIE_SWIRLY_B4_150], 
    [Key.CONTROL_100, Keycap.COOKIE_SWIRLY_B4_100],
    [Key.CODE_100, Keycap.COOKIE_CIRCLE_B4_100],
    [Key.CODE_150, Keycap.COOKIE_CIRCLE_B4_150], 
    [Key.ALT_150, Keycap.COOKIE_PRETZEL_B4_150], 
    [Key.ALT_100, Keycap.COOKIE_PRETZEL_B4_100],

    /* Ergo/Ortho */
    [Key.R1_150, Keycap.TIN_Y_150],
    [Key.R2_150_A, Keycap.BOBBIN_B1_150],
    [Key.R2_150_B, Keycap.PIN_B1_150], // vertical
    [Key.R3_150_A, Keycap.STORK_B2_150],
    [Key.R3_150_B, Keycap.PIN_B2_150], // vertical
    [Key.R4_150, Keycap.STITCH_B3_150],
    [Key.R1_A, Keycap.COOKIE_SWIRLY_Y], [Key.R1_B, Keycap.COOKIE_RECTANGLE_Y], [Key.R1_C, Keycap.COOKIE_PRETZEL_Y], [Key.R1_D, Keycap.COOKIE_CIRCLE_Y], // only used in cookie kit
    [Key.R1_E, Keycap.TIN_Y_100], [Key.R1_F, Keycap.TOMATO_Y], 
    [Key.R2_A, Keycap.COOKIE_SWIRLY_B1], [Key.R2_B, Keycap.COOKIE_RECTANGLE_B1], [Key.R2_C, Keycap.COOKIE_PRETZEL_B1], [Key.R2_D, Keycap.COOKIE_CIRCLE_B1], 
    [Key.R3_A, Keycap.COOKIE_SWIRLY_B2], [Key.R3_B, Keycap.COOKIE_RECTANGLE_B2], [Key.R3_C, Keycap.COOKIE_PRETZEL_B2], [Key.R3_D, Keycap.COOKIE_CIRCLE_B2], 
    [Key.R4_E, Keycap.STITCH_B3_100], [Key.R4_F, Keycap.BOBBIN_B3_100], 
    [Key.R4_A, Keycap.COOKIE_SWIRLY_B3], [Key.R4_B, Keycap.COOKIE_RECTANGLE_B3], [Key.R4_C, Keycap.COOKIE_PRETZEL_B3], [Key.R4_D, Keycap.COOKIE_CIRCLE_B3], 
    [Key.R5_A, Keycap.COOKIE_SWIRLY_B4_100], [Key.R5_B, Keycap.COOKIE_RECTANGLE_B4_100], [Key.R5_C, Keycap.COOKIE_PRETZEL_B4_100], [Key.R5_D, Keycap.COOKIE_CIRCLE_B4_100], 
    [Key.R5_E, Keycap.BUTTON_CIRCLE_B4], [Key.R5_F, Keycap.BUTTON_FLOWER_B4], [Key.R5_G, Keycap.BUTTON_HEART_B4],
    [Key.R4_200_A, Keycap.ZIGZAG_B3_200], 
    [Key.R4_200_B, Keycap.ZIGZAG_B3_200],
    [Key.R5_200_A, Keycap.SPACEBAR_200], 
    [Key.R5_200_B, Keycap.SPACEBAR_200], 
    [Key.R5_200_C, Keycap.SPACEBAR_200], 
    [Key.R5_200_D, Keycap.SPACEBAR_200], 
    [Key.R5_150_A, Keycap.STORK_B4_150],
    [Key.R5_150_B, Keycap.STITCH_B4_150],
    [Key.R5_150_C, Keycap.PIN_B4_150],
    // SPACEBAR_200,

    /* Spacebars / Alice */
    [Key.SPACEBAR_100, Keycap.SPACEBAR_100],
    [Key.SPACEBAR_125, Keycap.SPACEBAR_125],
    [Key.SPACEBAR_175, Keycap.SPACEBAR_175],
    [Key.SPACEBAR_200, Keycap.SPACEBAR_200],
    [Key.SPACEBAR_225, Keycap.SPACEBAR_225],
    [Key.SPACEBAR_275, Keycap.SPACEBAR_275],
    [Key.SPACEBAR_300, Keycap.SPACEBAR_300],
    [Key.SPACEBAR_600, Keycap.SPACEBAR_600],
    [Key.SPACEBAR_625, Keycap.SPACEBAR_625],
    [Key.SPACEBAR_700, Keycap.SPACEBAR_700],

    /* 40s */
    [Key.DELETE_100, Keycap.TOMATO_B1],
    // DELETE_150,
    [Key.DELETE_175, Keycap.STORK_B1_175],
    [Key.TAB_125, Keycap.BOBBIN_B2_125],
    [Key.ENTER_125, Keycap.TOMATO_B2_125],
    [Key.ENTER_150, Keycap.PIN_B2_150],
    [Key.ENTER_175, Keycap.PIN_B2_175],
    [Key.SHIFT_100, Keycap.STITCH_B3_100],
    // SHIFT_125,
    [Key.SHIFT_150, Keycap.STITCH_B3_150],
    // SHIFT_175,
    [Key.LOCK_175, Keycap.ZIGZAG_B4_175], 
    // also uses bottom row 1us, 1.25us and 1.5us
    
    /* Alice */
    [Key.PG_UP_ALICE, Keycap.BUTTON_FLOWER_B1],
    [Key.DELETE_ALICE, Keycap.TOMATO_Y],
    [Key.FN_ALICE, Keycap.COOKIE_CIRCLE_B3], // unlike normal fn, this is novelty in mixed mods, and is also a cookie
]);

export const MIXED_MODS_MAP: KeysetMap = new Map([
    [Key.BACKSPACE_200, Keycap.TEXT_BACKSPACE],
    [Key.BACKSPACE_200_ALT, Keycap.TEXT_BACKSPACE_B1],
    [Key.TAB_150, Keycap.TEXT_TAB],
    [Key.CAPSLOCK_175, Keycap.TEXT_COOKIE],
    [Key.STEPPED_CAPSLOCK_175, Keycap.TEXT_COOKIE_STEPPED],
    [Key.ENTER_225, Keycap.TEXT_ENTER], 
    [Key.SHIFT_225, Keycap.TEXT_SHIFT_225],
    [Key.SHIFT_275, Keycap.TEXT_SHIFT_275],
    [Key.CONTROL_125, Keycap.TEXT_CONTROL_125],
    [Key.CODE_125, Keycap.TEXT_SEW_125],
    [Key.ALT_125, Keycap.TEXT_ALT_125],
    [Key.MENU_125, Keycap.TEXT_MENU_125],

    /* tsangan */
    [Key.DELETE_150, Keycap.TEXT_BACK], // R2
    [Key.CONTROL_175, Keycap.TEXT_COOKIE], // R3
    [Key.SHIFT_175, Keycap.TEXT_SHIFT_175],
    [Key.FN, Keycap.TEXT_FN_B3],
    [Key.CONTROL_150, Keycap.TEXT_CONTROL_150], 
    [Key.CODE_150, Keycap.TEXT_SEW_150], 
    [Key.CODE_100, Keycap.TEXT_SEW_100],
    [Key.ALT_150, Keycap.TEXT_ALT_150], 
    [Key.ALT_100, Keycap.TEXT_ALT_100],

    /* numpad */
    [Key.NUM_LOCK, Keycap.TEXT_NUM_LOCK],
    [Key.NUM_LOCK_ALT, Keycap.TEXT_NUM_LOCK_ALT],
    [Key.NUMPAD_ENTER, Keycap.TEXT_NUMPAD_ENTER], 

    /* iso */
    [Key.ISO_ENTER, Keycap.TEXT_ISO_ENTER],
    [Key.SHIFT_125, Keycap.TEXT_SHIFT_125],
]);

export const TEXT_MODS_MAP: KeysetMap = new Map([
    ...MIXED_MODS_MAP,
    [Key.NUMROW_ESC, Keycap.TEXT_ESC_Y],
    [Key.ESC, Keycap.TEXT_ESC_B1],
    [Key.F1, Keycap.TEXT_F1],
    [Key.F2, Keycap.TEXT_F2],
    [Key.F3, Keycap.TEXT_F3],
    [Key.F4, Keycap.TEXT_F4],
    [Key.F5, Keycap.TEXT_F5],
    [Key.F6, Keycap.TEXT_F6],
    [Key.F7, Keycap.TEXT_F7],
    [Key.F8, Keycap.TEXT_F8],
    [Key.F9, Keycap.TEXT_F9],
    [Key.F10, Keycap.TEXT_F10],
    [Key.F11, Keycap.TEXT_F11],
    [Key.F12, Keycap.TEXT_F12],
    [Key.PRT_SC, Keycap.TEXT_PRT_SC],
    [Key.SCROLL, Keycap.TEXT_SCROLL],
    [Key.PAUSE, Keycap.TEXT_PAUSE],
    [Key.INSERT, Keycap.TEXT_INSERT_B2],
    [Key.HOME, Keycap.TEXT_HOME_B2],
    [Key.PG_UP, Keycap.TEXT_PG_UP_B2],
    [Key.DELETE, Keycap.TEXT_DELETE_B2],
    [Key.END, Keycap.TEXT_END_B2],
    [Key.PG_DN, Keycap.TEXT_PG_DN_B2],
    
    /* 65/75 */
    [Key.MACRO_1, Keycap.TEXT_HOME_Y1],
    [Key.MACRO_2, Keycap.TEXT_DELETE_B1],
    [Key.MACRO_3, Keycap.TEXT_PG_UP_B2],
    [Key.MACRO_4, Keycap.TEXT_PG_DN_B3],
    [Key.PRT_SC_75, Keycap.TEXT_PRT_SC],
    [Key.SCROLL_75, Keycap.TEXT_SCROLL],
    [Key.PAUSE_75, Keycap.TEXT_PAUSE],

    /* Alice */
    [Key.PG_UP_ALICE, Keycap.TEXT_PG_UP_B1],
    [Key.DELETE_ALICE, Keycap.TEXT_DELETE_Y1],
    [Key.FN_ALICE, Keycap.TEXT_FN_B3],
]);