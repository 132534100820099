import { KitName } from "./kits";
import { OverrideChoiceSet, OverrideToggle, OverrideChoiceSetName, OverrideToggleName, OverrideToggles, OverrideChoiceSets, OverrideDisplayName} from "./overrides";
import { Layout, DefaultLayouts} from "./layouts";

export type KeyboardLayout = {
    name: string;
    defaultLayout: Layout,
    overrideChoiceSets: Array<OverrideChoiceSet>,
    overrideToggles: Array<OverrideToggle>,
    // easier to implement as fn than as part of overrides, since it could depend on the combo of active overrides
    getKits: (choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) => Set<KitName>,
}

// For TKL etc
const STANDARD_CHOICE_SETS = [
    OverrideChoiceSets.MODIFIER_CHOICE_SET,
    OverrideChoiceSets.PHYSICAL_LAYOUT_CHOICE_SET,
    OverrideChoiceSets.ALPHA_LAYOUT_CHOICE_SET,
    OverrideChoiceSets.SPACEBAR_COLOUR_CHOICE_SET,
];
const STANDARD_TOGGLES = [
    OverrideToggles.ALPHA_NUMROW_OVERRIDE_TOGGLE,
];

// TODO: rename so its less confusing
const STANDARD_TOGGLES_TKL = [
    OverrideToggles.ACCENT_ARROWS_OVERRIDE_TOGGLE,
    ...STANDARD_TOGGLES,
    // temporarily commented out since it doesn't play nicely with ISO (you can toggle it on and it does nothing)
    // should hide it and fix the kitting helpers to ignore it
    // OverrideToggles.ALPHA_PIPE_OVERRIDE_TOGGLE,
    OverrideToggles.STEPPED_CAPSLOCK_OVERRIDE_TOGGLE,
];

const STANDARD_TOGGLES_TKL_TRUE = [
    OverrideToggles.ACCENT_ARROWS_OVERRIDE_TOGGLE,
    ...STANDARD_TOGGLES,
    OverrideToggles.MOD_NUMROW_TKL_OVERRIDE_TOGGLE,
    // temporarily commented out since it doesn't play nicely with ISO (you can toggle it on and it does nothing)
    // should hide it and fix the kitting helpers to ignore it
    // OverrideToggles.ALPHA_PIPE_OVERRIDE_TOGGLE,
    OverrideToggles.STEPPED_CAPSLOCK_OVERRIDE_TOGGLE,
];

const STANDARD_TOGGLES_100 = [
    OverrideToggles.ACCENT_ARROWS_OVERRIDE_TOGGLE,
    ...STANDARD_TOGGLES,
    OverrideToggles.MOD_NUMROW_100_OVERRIDE_TOGGLE,
    OverrideToggles.STEPPED_CAPSLOCK_OVERRIDE_TOGGLE,
];

// Alice, HHKB 
const SPECIALTY_CHOICE_SETS = [
    // Note: currently unused since there is now a mixed mods kit
    // OverrideChoiceSets.MODIFIER_CHOICE_SET_NO_MIXED,
    OverrideChoiceSets.MODIFIER_CHOICE_SET,
    OverrideChoiceSets.ALPHA_LAYOUT_CHOICE_SET,
    OverrideChoiceSets.SPACEBAR_COLOUR_CHOICE_SET,
];

// Ergo, ortho, 40s
const ALPHA_ONLY_CHOICE_SETS = [
    OverrideChoiceSets.ALPHA_LAYOUT_CHOICE_SET,
];

const NUMROW_AND_ARROW_TOGGLES = [
    OverrideToggles.ACCENT_ARROWS_OVERRIDE_TOGGLE,
    OverrideToggles.ALPHA_NUMROW_OVERRIDE_TOGGLE,
];

const FOURTIES_TOGGLES = [
    OverrideToggles.ACCENT_ARROWS_OVERRIDE_TOGGLE,
    OverrideToggles.ACCENT_ESC_OVERRIDE_TOGGLE,
    OverrideToggles.ACCENT_BACKSPACE_40_OVERRIDE_TOGGLE,
];

function handleAlphaLayout(choiceSetValues: Map<OverrideChoiceSetName, string>, kits: Set<KitName>) {
    if (choiceSetValues.get(OverrideChoiceSetName.ALPHA_LAYOUT) !== 'Qwerty') {
        kits.add(KitName.COLEVRAK);
    }
}

function handlePhysicalLayout(choiceSetValues: Map<OverrideChoiceSetName, string>, kits: Set<KitName>) {
    // TODO: remove if iso in base :P 
    if (choiceSetValues.get(OverrideChoiceSetName.PHYSICAL_LAYOUT) !== 'ANSI') {
        kits.add(KitName.ISO);
    }
}

function handleAlphaNumrow(toggleValues: Map<OverrideToggleName, boolean>, kits: Set<KitName>) {
    // note: can't check for MOD_NUMROW here since not all standard keyboards have that tailor option atmj
    if (toggleValues.get(OverrideToggleName.ALPHA_NUMROW)) {
        kits.add(KitName.ALPHA_NUMROW);
    }
}

function handleModNumrow(toggleValues: Map<OverrideToggleName, boolean>, kits: Set<KitName>) {
    if (toggleValues.get(OverrideToggleName.MOD_NUMROW)) {
        kits.add(KitName.ALPHA_NUMROW);
    }
}

// TODO: should be called Spacebars
function handleYellowAccents(choiceSetValues: Map<OverrideChoiceSetName, string>, kits: Set<KitName>) {
    if (choiceSetValues.get(OverrideChoiceSetName.SPACEBAR_COLOUR) === OverrideDisplayName.YELLOW_SPACEBARS) {
        kits.add(KitName.YELLOW_ACCENTS);
    }
}

// hack: wouldn't need to be separate if we filtered irrelevant toggleValues in main tailor element
function handleYellowAccentsWithArrows(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>, kits: Set<KitName>) {
    handleYellowAccents(choiceSetValues, kits);
    if (toggleValues.get(OverrideToggleName.ACCENT_ARROWS)) {
        kits.add(KitName.YELLOW_ACCENTS);
    }
}

function getKitsStandard(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>): Set<KitName> {
    const kits: Set<KitName> = new Set(); 

    const modifiersChoiceName = choiceSetValues.get(OverrideChoiceSetName.MODIFIERS);
    const spacebarColourChoiceName = choiceSetValues.get(OverrideChoiceSetName.SPACEBAR_COLOUR);
    if (modifiersChoiceName === OverrideDisplayName.TEXT_MODS) {
        kits.add(KitName.ALPHAS);
        kits.add(KitName.TEXT_MODS);
    } else if (spacebarColourChoiceName === OverrideDisplayName.WHITE_SPACEBARS || toggleValues.get(OverrideToggleName.STEPPED_CAPSLOCK) || toggleValues.get(OverrideToggleName.ALPHA_PIPE)) {
        // Alpha spacebar, stepped capslock or alpha pipe means you need separate alphas + mods
        if (modifiersChoiceName === OverrideDisplayName.MIXED_MODS) {
            kits.add(KitName.ALPHAS);
            kits.add(KitName.MIXED_MODS);
        } else {
            kits.add(KitName.ALPHAS);
            kits.add(KitName.NOVELTY_MODS);
        }
    } else if (modifiersChoiceName === OverrideDisplayName.MIXED_MODS) {
        kits.add(KitName.MIXED_BASE);
    } else {
        kits.add(KitName.NOVELTY_BASE);
    }

    handleAlphaLayout(choiceSetValues, kits);
    handlePhysicalLayout(choiceSetValues, kits);
    handleYellowAccentsWithArrows(choiceSetValues, toggleValues, kits);

    handleAlphaNumrow(toggleValues, kits);

    return kits;
}

// mega hack: can be removed if we filtered irrelevant toggle values in main tailor element
function getKitsStandardNoAccentArrows(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>): Set<KitName> {
    const kits: Set<KitName> = new Set(); 

    const modifiersChoiceName = choiceSetValues.get(OverrideChoiceSetName.MODIFIERS);
    const spacebarColourChoiceName = choiceSetValues.get(OverrideChoiceSetName.SPACEBAR_COLOUR);
    if (modifiersChoiceName === OverrideDisplayName.TEXT_MODS) {
        kits.add(KitName.ALPHAS);
        kits.add(KitName.TEXT_MODS);
    } else if (spacebarColourChoiceName === OverrideDisplayName.WHITE_SPACEBARS || toggleValues.get(OverrideToggleName.STEPPED_CAPSLOCK) || toggleValues.get(OverrideToggleName.ALPHA_PIPE)) {
        // Alpha spacebar, stepped capslock or alpha pipe means you need separate alphas + mods
        if (modifiersChoiceName === OverrideDisplayName.MIXED_MODS) {
            kits.add(KitName.ALPHAS);
            kits.add(KitName.MIXED_MODS);
        } else {
            kits.add(KitName.ALPHAS);
            kits.add(KitName.NOVELTY_MODS);
        }
    } else if (modifiersChoiceName === OverrideDisplayName.MIXED_MODS) {
        kits.add(KitName.MIXED_BASE);
    } else {
        kits.add(KitName.NOVELTY_BASE);
    }

    handleAlphaLayout(choiceSetValues, kits);
    handlePhysicalLayout(choiceSetValues, kits);
    // No arrows!
    handleYellowAccents(choiceSetValues, kits);

    handleAlphaNumrow(toggleValues, kits);

    return kits;
}



// Alice - like standard but alpha spacebars don't require basekit, and there is no iso
// TODO: refactor to share more logic
function getKitsAlice(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>): Set<KitName> {
    const kits: Set<KitName> = new Set(); 

    const modifiersChoiceName = choiceSetValues.get(OverrideChoiceSetName.MODIFIERS);
    if (modifiersChoiceName === OverrideDisplayName.TEXT_MODS) {
        kits.add(KitName.ALPHAS);
        kits.add(KitName.TEXT_MODS);
    } else if (toggleValues.get(OverrideToggleName.STEPPED_CAPSLOCK) || toggleValues.get(OverrideToggleName.ALPHA_PIPE)) {
        // Alpha spacebar, stepped capslock or alpha pipe means you need separate alphas + mods
        if (modifiersChoiceName === OverrideDisplayName.MIXED_MODS) {
            kits.add(KitName.ALPHAS);
            kits.add(KitName.MIXED_MODS);
        } else {
            kits.add(KitName.ALPHAS);
            kits.add(KitName.NOVELTY_MODS);
        }
    } else if (modifiersChoiceName === OverrideDisplayName.MIXED_MODS) {
        kits.add(KitName.MIXED_BASE);
    } else {
        kits.add(KitName.NOVELTY_BASE);
    }

    handleAlphaLayout(choiceSetValues, kits);
    // No arrows!
    handleYellowAccents(choiceSetValues, kits);

    handleAlphaNumrow(toggleValues, kits);
    handleModNumrow(toggleValues, kits);

    kits.add(KitName.SPACEBARS);

    return kits;
}

// HHKB 
function getKitsSpecialty(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>): Set<KitName> {
    const kits: Set<KitName> = new Set(); 
    kits.add(KitName.ALPHAS);
    const modifiersChoiceName = choiceSetValues.get(OverrideChoiceSetName.MODIFIERS);
    if (modifiersChoiceName === OverrideDisplayName.TEXT_MODS) {
        kits.add(KitName.TEXT_MODS);
    } else if (modifiersChoiceName === OverrideDisplayName.MIXED_MODS) {
        kits.add(KitName.MIXED_MODS);
    } else {
        kits.add(KitName.NOVELTY_MODS);
    }

    handleAlphaLayout(choiceSetValues, kits);
    // No arrows!
    handleYellowAccents(choiceSetValues, kits);

    handleAlphaNumrow(toggleValues, kits);

    return kits;
}

function getKitsOrthoNumrowless(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) {
    const kits: Set<KitName> = new Set(); 
    kits.add(KitName.ALPHAS);
    kits.add(KitName.ORTHO);
    handleAlphaLayout(choiceSetValues, kits);
    handleYellowAccentsWithArrows(choiceSetValues, toggleValues, kits);

    return kits;
}

function getKitsOrtho(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) {
    const kits = getKitsOrthoNumrowless(choiceSetValues, toggleValues);

    handleAlphaNumrow(toggleValues, kits);

    return kits;
}

function getKitsLily(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) {
    const kits: Set<KitName> = new Set(); 
    kits.add(KitName.ALPHAS);
    kits.add(KitName.ORTHO);

    handleAlphaLayout(choiceSetValues, kits);
    handleAlphaNumrow(toggleValues, kits);

    if (choiceSetValues.get(OverrideChoiceSetName.THUMB_KEYS) === OverrideDisplayName.YELLOW_THUMB_KEYS_LILY) {
        kits.add(KitName.YELLOW_ACCENTS);
    }
    return kits;
}

function getKits40s(choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) {
    const kits: Set<KitName> = new Set(); 
    kits.add(KitName.ALPHAS);
    kits.add(KitName.FOURTIES);
    handleAlphaLayout(choiceSetValues, kits);
    handleYellowAccentsWithArrows(choiceSetValues, toggleValues, kits);

    return kits;
}

const KEYBOARD_LAYOUT_60: KeyboardLayout = {
    name: '60%',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_60,
    overrideChoiceSets: STANDARD_CHOICE_SETS,
    // can't use TKL toggles since no arrow keys
    overrideToggles: [...STANDARD_TOGGLES, OverrideToggles.STEPPED_CAPSLOCK_OVERRIDE_TOGGLE],
    getKits: getKitsStandardNoAccentArrows,
};

const KEYBOARD_LAYOUT_TKL: KeyboardLayout = {
    name: 'TKL',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_TKL,
    overrideChoiceSets: STANDARD_CHOICE_SETS,
    overrideToggles: STANDARD_TOGGLES_TKL_TRUE,
    getKits: (choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) => {
        const mostKits = getKitsStandard(choiceSetValues, toggleValues);
        handleModNumrow(toggleValues, mostKits);
        return mostKits;
    },
};

const KEYBOARD_LAYOUT_100: KeyboardLayout = {
    name: '100%',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_100,
    overrideChoiceSets: STANDARD_CHOICE_SETS,
    overrideToggles: STANDARD_TOGGLES_100,
    getKits: (choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) => {
        const mostKits = getKitsStandard(choiceSetValues, toggleValues);
        handleModNumrow(toggleValues, mostKits);
        mostKits.add(KitName.NUMPAD);
        return mostKits;
    },
};

const KEYBOARD_LAYOUT_65: KeyboardLayout = {
    name: '65%',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_65,
    overrideChoiceSets: STANDARD_CHOICE_SETS,
    overrideToggles: STANDARD_TOGGLES_TKL,
    getKits: getKitsStandard,
};

const KEYBOARD_LAYOUT_75: KeyboardLayout = {
    name: '75%',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_75,
    overrideChoiceSets: STANDARD_CHOICE_SETS,
    overrideToggles: STANDARD_TOGGLES_TKL,
    getKits: getKitsStandard,
};

// TODO: deal with tailor saying you need spacebars + yellow accents
const KEYBOARD_LAYOUT_ALICE: KeyboardLayout = {
    name: 'Alice',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_ALICE,
    // HACK: temporarily remove spacebar colour toggle cos of the spacebars/yellow accents sitch
    overrideChoiceSets: [...SPECIALTY_CHOICE_SETS.slice(0, SPECIALTY_CHOICE_SETS.length - 1), OverrideChoiceSets.MACROS_CHOICE_SET_ALICE], 
    overrideToggles: [...STANDARD_TOGGLES, OverrideToggles.MOD_NUMROW_ALICE_OVERRIDE_TOGGLE, OverrideToggles.STEPPED_CAPSLOCK_ALICE_OVERRIDE_TOGGLE],
    getKits: getKitsAlice,
};

const KEYBOARD_LAYOUT_HHKB: KeyboardLayout = {
    name: 'HHKB',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_HHKB,
    overrideChoiceSets: SPECIALTY_CHOICE_SETS,
    overrideToggles: [...STANDARD_TOGGLES, OverrideToggles.STEPPED_CONTROL_OVERRIDE_TOGGLE],
    getKits: getKitsSpecialty,
};

const KEYBOARD_LAYOUT_ERGODOX: KeyboardLayout = {
    name: 'Ergodox',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_ERGODOX,
    overrideChoiceSets: [OverrideChoiceSets.THUMB_CHOICE_SET_ERGO, ...ALPHA_ONLY_CHOICE_SETS],
    overrideToggles: NUMROW_AND_ARROW_TOGGLES,
    getKits:  (choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) => {
        const kits: Set<KitName> = new Set(); 
        kits.add(KitName.ALPHAS);
        kits.add(KitName.ERGO);
        handleAlphaLayout(choiceSetValues, kits);

        handleAlphaNumrow(toggleValues, kits);

        handleYellowAccentsWithArrows(choiceSetValues, toggleValues, kits);

        return kits;
    },
};

const KEYBOARD_LAYOUT_MOONLANDER: KeyboardLayout = {
    name: 'Moonlander',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_MOONLANDER,
    overrideChoiceSets: ALPHA_ONLY_CHOICE_SETS,
    overrideToggles: NUMROW_AND_ARROW_TOGGLES,
    getKits: getKitsOrtho,
};

const KEYBOARD_LAYOUT_LILY58: KeyboardLayout = {
    name: 'Lily58',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_LILY58,
    overrideChoiceSets: [OverrideChoiceSets.THUMB_CHOICE_SET_LILY, ...ALPHA_ONLY_CHOICE_SETS],
    overrideToggles: STANDARD_TOGGLES,
    getKits: getKitsLily,
};

const KEYBOARD_LAYOUT_PLANCK: KeyboardLayout = {
    name: 'Planck',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_PLANCK,
    overrideChoiceSets: [...ALPHA_ONLY_CHOICE_SETS, OverrideChoiceSets.SPACEBAR_COLOUR_CHOICE_SET],
    overrideToggles: [OverrideToggles.ACCENT_ARROWS_OVERRIDE_TOGGLE],
    getKits:  getKitsOrthoNumrowless,
};

const KEYBOARD_LAYOUT_PREONIC: KeyboardLayout = {
    name: 'Preonic',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_PREONIC,
    overrideChoiceSets: [...ALPHA_ONLY_CHOICE_SETS, OverrideChoiceSets.SPACEBAR_COLOUR_CHOICE_SET],
    overrideToggles: [OverrideToggles.ACCENT_ARROWS_OVERRIDE_TOGGLE, ...STANDARD_TOGGLES],
    getKits:  getKitsOrtho,
};

const KEYBOARD_LAYOUT_MINIVAN: KeyboardLayout = {
    name: 'Minivan',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_MINIVAN,
    overrideChoiceSets: [...ALPHA_ONLY_CHOICE_SETS, OverrideChoiceSets.SPACEBAR_COLOUR_CHOICE_SET, OverrideChoiceSets.PHYSICAL_LAYOUT_CHOICE_SET_MINIVAN],
    overrideToggles: FOURTIES_TOGGLES,
    getKits:  (choiceSetValues: Map<OverrideChoiceSetName, string>, toggleValues: Map<OverrideToggleName, boolean>) => {
        const kits = getKits40s(choiceSetValues, toggleValues);
        // hack for lock key / accent arrows situation
        if (
            choiceSetValues.get(OverrideChoiceSetName.PHYSICAL_LAYOUT) !== OverrideDisplayName.ARROW_KEYS &&
            choiceSetValues.get(OverrideChoiceSetName.SPACEBAR_COLOUR) !== OverrideDisplayName.YELLOW_SPACEBARS
        ) {
            kits.delete(KitName.YELLOW_ACCENTS);
        }

        return kits;
    }
};

const KEYBOARD_LAYOUT_UT472: KeyboardLayout = {
    name: 'UT47.2',
    defaultLayout: DefaultLayouts.DEFAULT_LAYOUT_UT472,
    overrideChoiceSets: [...ALPHA_ONLY_CHOICE_SETS, OverrideChoiceSets.SPACEBAR_COLOUR_CHOICE_SET],
    overrideToggles: FOURTIES_TOGGLES,
    getKits:  getKits40s,
};

export const KEYBOARD_LAYOUTS = [
    KEYBOARD_LAYOUT_TKL,
    KEYBOARD_LAYOUT_100,
    KEYBOARD_LAYOUT_75,
    KEYBOARD_LAYOUT_65,
    KEYBOARD_LAYOUT_60,
    KEYBOARD_LAYOUT_ALICE,
    KEYBOARD_LAYOUT_HHKB,
    KEYBOARD_LAYOUT_ERGODOX,
    KEYBOARD_LAYOUT_MOONLANDER,
    KEYBOARD_LAYOUT_LILY58,
    KEYBOARD_LAYOUT_PLANCK,
    KEYBOARD_LAYOUT_PREONIC,
    KEYBOARD_LAYOUT_MINIVAN,
    KEYBOARD_LAYOUT_UT472,
];