import React from 'react';
import './App.css';
import { Header, Link} from './shared';

export const CABLE_LINK = 'https://cookiecables.com/collections/kam-sewing-tin-cables';
export const BOX_LINK = 'https://p3dstore.com/collections/group-buys/products/sewing-tin-artisan-box-group-buy';
const BOX_FILE_REQUEST_LINK = 'https://forms.gle/ELU8fk6MNNN9wjXZA';

type CollaborationProps = {
    name: string;
    url: string;
    imageName: string;
}

function Collaboration({name, url, imageName}: CollaborationProps) {
  return (
    <div className="collaborator">
        <img src={`/collaborations/${imageName}.png`} alt={`doodle of cookies with ${name}`} />
        <br />
        <Link url={url}>{name}</Link>
    </div>
    );
}

const COLLABORATORS: Array<CollaborationProps> = [
    {name: 'Bowbie Keycaps', url: 'https://www.instagram.com/bowbie.keycaps/', imageName: 'doodle_bowbie'},
    {name: 'Cantoclicks', url: 'https://www.instagram.com/cantoclicks/', imageName: 'doodle_cantoclicks'},
    {name: 'Cookie Cables', url: CABLE_LINK, imageName: 'doodle_cookiecables'},
    {name: 'Crafted By Annie', url: 'https://craftedbyannie.com/', imageName: 'doodle_annie'},
    {name: 'klaykaps', url: 'https://klaykaps.com/', imageName: 'doodle_klaykaps'},
    {name: 'Macher Studio', url: 'https://www.macherstudio.com/', imageName: 'doodle_macher'},
    {name: 'meof caps', url: 'https://www.meof-studio.com/', imageName: 'doodle_meof'},
    {name: 'Miroticaps', url: 'https://linktr.ee/Miroticaps', imageName: 'doodle_miroticaps'},
    {name: 'Myth Caps', url: 'https://www.instagram.com/myth_caps/', imageName: 'doodle_mythcaps'},
    {name: 'n.Na works', url: 'https://www.instagram.com/nnaworks/', imageName: 'doodle_nnaworks'},
    {name: 'okeydokey studio', url: 'https://okeydokey.studio/', imageName: 'doodle_okeydokey'},
    {name: 'shirouu.kaps', url: 'https://www.instagram.com/shirouu.kaps', imageName: 'doodle_shirouu'},
]; 

export function CollaborationsSection() {
  return (
    <React.Fragment>
        <Header name="collaborations" />
        <p className="mb2">
             I'm excited and honoured to be collaborating with these makers on Sewing Tin cables, artisans, and more! 
        </p>
        
        <p className="collaborationsList mb2">
            {COLLABORATORS.map(collaborator => <Collaboration {...collaborator} key={collaborator.name} />)}
        </p>

        <div className="divider" />

        <div className="mb2">
          <div className="halfArea subArea subAreaPaddingRight otherCollabBox">
            <img src="/collaborations/cookiecables.jpg" className="otherCollabImage" />
            <i>USB and TRRS coiled cables by <Link url={CABLE_LINK}>Cookie Cables</Link></i>
          </div>
          <div className="halfArea subArea subAreaPaddingLeft otherCollabBox">
            <img src="/collaborations/artisanbox.jpg" className="otherCollabImage" />
            <i>Sewing Tin artisan box in partnership with <Link url={BOX_LINK}>P3D Store</Link></i>
          </div>
        </div>
        {/* <div className="mb2">
          <div className="halfArea subArea subAreaPaddingRight otherCollabBox">
            <img src="/collaborations/thok.jpg" className="otherCollabImage" />
            <i>Rotary encoder knobs by THOK</i>
          </div>
          <div className="halfArea subArea subAreaPaddingLeft otherCollabBox">
            <img src="/collaborations/artisanbox.jpg" className="otherCollabImage" />
            <i>Metal artisan in collaboration with Asero Foundry</i>
          </div>
        </div> */}
        <p className="mb2">
          The Sewing Tin artisan box 3DP files will also be available to those who join the keyset group buy: <Link url={BOX_FILE_REQUEST_LINK}>see here</Link> for more details and to request the files.
        </p>
        
        {/* <p className="mb2">
          Additionally, <Link url="https://p3dstore.com">P3D Store</Link> will be holding a small group buy for the matching <Link url="https://imgur.com/a/GAoWnpW">Sewing Tin artisan boxes</Link>. 
          These boxes have 3D printed bodies and engraved-acrylic lids. The 3D print files will be available on request for those who join the keyset group buy.
        </p> */}
      </React.Fragment>
  );
}
