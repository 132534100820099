import { Key } from "./keys";
import { Keycap } from "./keyset";
import { Layout } from "./layouts";
import { KeysetMap, MIXED_MODS_MAP, TEXT_MODS_MAP} from "./keyset_map";

// selectors (choose an option, first is default) 
export enum OverrideChoiceSetName {
    ALPHA_LAYOUT, // qwerty/dvorak/colemak - all keyboards
    PHYSICAL_LAYOUT, // ansi/iso/uk iso - 60 to 100%
    MODIFIERS, // novelty/mixed/text mods - 60 to 100%
    // BOTTOM_ROW, // bottom row layout - 40% to 100% (change layout)
    THUMB_KEYS, // ergo and lily thumb clusters
    MACRO_KEYS, // alice macro keys
    SPACEBAR_COLOUR, 
};

// toggles (on/off)
export enum OverrideToggleName {
    EXTRA_COOKIES,
    EXTRA_BUTTONS,
    // ALPHA_SPACEBARS,
    ALPHA_NUMROW,
    // TODO: slightly potato that this isnt the same as ALPHA_NUMROW
    MOD_NUMROW, 
    ALPHA_PIPE,
    ACCENT_ESC,
    ACCENT_BACKSPACE,
    STEPPED_CAPSLOCK,
    ACCENT_ARROWS,
}

export type OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName,
    // first is default option
    overrides: Array<Override>,
};

export type OverrideToggle = {
    // note: this is slightly redundant for toggles, since they have a display name in the Override
    // the toggleName is used for state, the display name is used for rendering
    // (unlike choices, where both are used for state)
    toggleName: OverrideToggleName,
    override: Override;
}

export enum OverrideDisplayName {
    QWERTY = 'Qwerty',
    DVORAK = 'Dvorak',
    COLEMAK = 'Colemak',
    ANSI = 'ANSI',
    ISO = 'Terminal ISO',
    UK_ISO = 'UK ISO',
    NOVELTY_MODS = 'Novelty mods',
    MIXED_MODS = 'Mixed mods',
    TEXT_MODS = 'Text mods',
    BLUE_SPACEBARS = 'Blue space',
    WHITE_SPACEBARS = 'White space',
    YELLOW_SPACEBARS = 'Yellow space',
    ALPHA_NUMROW = 'White numrow alphas',
    MOD_NUMROW = 'Blue numrow mods',
    ALPHA_PIPE = 'White pipe',
    ACCENT_ESC = 'Accent escape',
    ACCENT_BACKSPACE = 'Accent backspace',
    // Same toggle name, different display name for HHKB
    STEPPED_CAPSLOCK = 'Stepped caps lock',
    STEPPED_CONTROL = 'Stepped control',
    // Ergo THUMB_KEYS
    BLANK_THUMB_KEYS = 'Blank thumb keys',
    NOVELTY_THUMB_KEYS = 'Novelty thumb keys',
    // Lily THUMB_KEYS
    STORK_THUMB_KEYS = 'Stork scissors',
    STITCH_THUMB_KEYS = 'Stitches',
    PIN_THUMB_KEYS = 'Pins',
    BLANK_THUMB_KEYS_LILY = 'Blank',
    YELLOW_THUMB_KEYS_LILY = 'Yellow',
    // Minivan PHYSICAL_LAYOUT
    LOCK_KEY = 'Lock key',
    ARROW_KEYS = 'Arrow keys',
    // Alice MACRO_KEYS
    BLUE_MACROS = 'Blue macros',
    GRADIENT_MACROS = 'Gradient macros',
    ACCENT_ARROWS = 'Accent arrows',
};

export type Override = {
    displayName: OverrideDisplayName,
    layoutOverride?: {
        remove: Array<Key>,
        add: Layout,
    },
    keysetOverride?: KeysetMap,
};


const ALPHA_LAYOUT_CHOICE_SET: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.ALPHA_LAYOUT,
    overrides: [
        {displayName: OverrideDisplayName.QWERTY},
        { 
            displayName: OverrideDisplayName.DVORAK, 
            keysetOverride: new Map([
                [Key.ALPHA_DASH, Keycap.LEFT_BRACKET_Y], 
                [Key.ALPHA_EQUALS, Keycap.RIGHT_BRACKET_Y], 

                [Key.Q, Keycap.SINGLE_QUOTE], 
                [Key.W, Keycap.COMMA], 
                [Key.E, Keycap.PERIOD], 
                [Key.R, Keycap.P], 
                [Key.T, Keycap.Y], 
                [Key.Y, Keycap.F], 
                [Key.U, Keycap.G], 
                [Key.I, Keycap.C], 
                [Key.O, Keycap.R], 
                [Key.P, Keycap.L], 
                [Key.LEFT_BRACKET, Keycap.SLASH], 
                [Key.RIGHT_BRACKET, Keycap.ALPHA_EQUALS_W], 

                // [KEY.A, KEYSET_KEY.A], 
                [Key.S, Keycap.O], 
                [Key.D, Keycap.E], 
                [Key.F, Keycap.U_HOMING_BARRED], 
                [Key.G, Keycap.I], 
                [Key.H, Keycap.D], 
                [Key.J, Keycap.H_HOMING_BARRED], 
                [Key.K, Keycap.T], 
                [Key.L, Keycap.N], 
                [Key.SEMICOLON, Keycap.S], 
                [Key.SINGLE_QUOTE, Keycap.ALPHA_DASH_W], 

                [Key.Z, Keycap.SEMICOLON], 
                [Key.X, Keycap.Q], 
                [Key.C, Keycap.J], 
                [Key.V, Keycap.K], 
                [Key.B, Keycap.X], 
                [Key.N, Keycap.B], 
                // [KEY.M, KEYSET_KEY.M], 
                [Key.COMMA, Keycap.W], 
                [Key.PERIOD, Keycap.V], 
                [Key.SLASH, Keycap.Z], 
            ])
        },
        { 
            displayName: OverrideDisplayName.COLEMAK, 
            keysetOverride: new Map([
                // [KEY.Q, KEYSET_KEY.Q], 
                // [KEY.W, KEYSET_KEY.W], 
                [Key.E, Keycap.F], 
                [Key.R, Keycap.P], 
                [Key.T, Keycap.G], 
                [Key.Y, Keycap.J], 
                [Key.U, Keycap.L], 
                [Key.I, Keycap.U], 
                [Key.O, Keycap.Y], 
                [Key.P, Keycap.SEMICOLON], 

                // [KEY.A, KEYSET_KEY.A], 
                [Key.S, Keycap.R], 
                [Key.D, Keycap.S], 
                [Key.F, Keycap.T_HOMING_BARRED], 
                [Key.G, Keycap.D], 
                // [KEY.H, KEYSET_KEY.H], 
                [Key.J, Keycap.N_HOMING_BARRED], 
                [Key.K, Keycap.E], 
                [Key.L, Keycap.I], 
                [Key.SEMICOLON, Keycap.O], 

                // [KEY.Z, KEYSET_KEY.Z], 
                // [KEY.X, KEYSET_KEY.X], 
                // [KEY.C, KEYSET_KEY.C], 
                // [KEY.V, KEYSET_KEY.V], 
                // [KEY.B, KEYSET_KEY.B], 
                [Key.N, Keycap.K], 
                // [KEY.M, KEYSET_KEY.M], 
            ])
        }
    ]
}

const PHYSICAL_LAYOUT_CHOICE_SET: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.PHYSICAL_LAYOUT,
    overrides: [
        {displayName: OverrideDisplayName.ANSI},
        {
            displayName: OverrideDisplayName.ISO, 
            layoutOverride: {
                remove: [Key.PIPE_150, Key.ENTER_225, Key.SHIFT_225],
                add: [
                    {key: Key.ISO_ENTER, x: 13.5, y: 2.5},
                    {key: Key.ISO_PIPE, x: 12.75, y: 3.5},
                    {key: Key.SHIFT_125, x: 0, y: 4.5},
                    {key: Key.ISO_ANGLE_BRACKET, x: 1.25, y: 4.5},
                ],
            }
        },
        {
            displayName: OverrideDisplayName.UK_ISO, 
            layoutOverride: {
                // Note: this ends up renders the backtick on top of numrow esc
                // but it needs to be a layout override not a keyset override to play nicely with alt numrow
                // It's "solved" with a zIndex hack in keycapRenderer for this special case
                // we also don't remove the numrow_esc for this reason
                // If we want to get rid of the hack, we can just get rid of the numrow esc and special casing and let it be clobbered for iso 60/65%
                remove: [Key.PIPE_150, Key.ENTER_225, Key.SHIFT_225, Key.SINGLE_QUOTE, Key.BACKTICK, Key.ALPHA_2, Key.ALPHA_3],
                add: [
                    {key: Key.UK_ISO_BACKTICK, x: 0, y: 1.5},
                    {key: Key.UK_ISO_2, x: 2, y: 1.5},
                    {key: Key.UK_ISO_3, x: 3, y: 1.5},
                    {key: Key.ISO_ENTER, x: 13.5, y: 2.5},
                    {key: Key.UK_ISO_SINGLE_QUOTE, x: 11.75, y: 3.5},
                    {key: Key.UK_ISO_POUND, x: 12.75, y: 3.5},
                    {key: Key.SHIFT_125, x: 0, y: 4.5},
                    {key: Key.ISO_PIPE, x: 1.25, y: 4.5},
                ],
            }
        },
    ]
};

const MODIFIER_CHOICE_SET: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.MODIFIERS,
    overrides: [
        // DEFAULT_KEYSET_MAP has novelty mods by default.
        {displayName: OverrideDisplayName.NOVELTY_MODS}, 
        {
            displayName: OverrideDisplayName.MIXED_MODS,
            keysetOverride: MIXED_MODS_MAP,
        }, 
        {
            displayName: OverrideDisplayName.TEXT_MODS,
            keysetOverride: TEXT_MODS_MAP,
        }, 
    ]
};

// for layouts that can't use the base kits
// hackily under the same choiceName as MODIFIERS since they look the same 
// this means there has to be special handling for when MIXED is the current value, to default to NOVELTY_MODS instead. 
// this seemed better than having separate values or manually updating them on keyboard change 
const MODIFIER_CHOICE_SET_NO_MIXED: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.MODIFIERS,
    overrides: [
        // DEFAULT_KEYSET_MAP has novelty mods by default.
        {displayName: OverrideDisplayName.NOVELTY_MODS}, 
        {
            displayName: OverrideDisplayName.TEXT_MODS,
            keysetOverride: TEXT_MODS_MAP,
        }, 
    ],
};

// like the above hackily uses the same choiceName
const PHYSICAL_LAYOUT_CHOICE_SET_MINIVAN: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.PHYSICAL_LAYOUT,
    overrides: [
        {displayName: OverrideDisplayName.LOCK_KEY},
        {
            displayName: OverrideDisplayName.ARROW_KEYS, 
            layoutOverride: {
                remove: [Key.MENU_125, Key.CODE_150, Key.LOCK_175, Key.SLASH],
                add: [
                    // gotta put it back lol
                    {key: Key.CODE_150, x: 1.75, y: 5.5},
                    {key: Key.CODE_150, x: 8.75, y: 5.5},
                    {key: Key.ARROW_LEFT, x: 10.25, y: 5.5},
                    {key: Key.ARROW_UP, x: 11.25, y: 4.5},
                    {key: Key.ARROW_DOWN, x: 11.25, y: 5.5},
                    {key: Key.ARROW_RIGHT, x: 12.25, y: 5.5},
                ],
            }
        },
    ]
};

const THUMB_CHOICE_SET_ERGO: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.THUMB_KEYS,
    overrides: [
        {displayName: OverrideDisplayName.BLANK_THUMB_KEYS},
        {
            displayName: OverrideDisplayName.NOVELTY_THUMB_KEYS,
            keysetOverride: new Map([
                [Key.R5_200_A, Keycap.STITCH_A_B4_200], 
                [Key.R5_200_B, Keycap.STITCH_B_B4_200], 
                [Key.R5_200_C, Keycap.STITCH_C_B4_200], 
                [Key.R5_200_D, Keycap.STITCH_D_B4_200], 
            ]),
        },
    ],
};

const THUMB_CHOICE_SET_LILY: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.THUMB_KEYS,
    overrides: [
        {displayName: OverrideDisplayName.STORK_THUMB_KEYS},
        {
            displayName: OverrideDisplayName.STITCH_THUMB_KEYS,
            keysetOverride: new Map([
                [Key.R5_150_A, Keycap.STITCH_B4_150], 
            ]),
        },
        {
            displayName: OverrideDisplayName.PIN_THUMB_KEYS,
            keysetOverride: new Map([
                [Key.R5_150_A, Keycap.PIN_B4_150], 
            ]),
        },
        {
            displayName: OverrideDisplayName.BLANK_THUMB_KEYS_LILY,
            keysetOverride: new Map([
                [Key.R5_150_A, Keycap.SPACEBAR_150], 
            ]),
        },
        {
            displayName: OverrideDisplayName.YELLOW_THUMB_KEYS_LILY,
            keysetOverride: new Map([
                [Key.R5_150_A, Keycap.SPACEBAR_150_Y], 
            ]),
        },
    ],
};

const MACROS_CHOICE_SET_ALICE: OverrideChoiceSet = {
    choiceName: OverrideChoiceSetName.MACRO_KEYS,
    overrides: [
        {displayName: OverrideDisplayName.BLUE_MACROS},
        {
            displayName: OverrideDisplayName.GRADIENT_MACROS,
            // layout override so that it works nicely with novelty/text mods etc
            layoutOverride: {
                remove: [Key.DELETE, Key.PG_UP],
                add: [
                    {key: Key.DELETE_ALICE, x: -0.25, y: 0},
                    {key: Key.PG_UP_ALICE, x: -0.375, y: 1},
                ],
            }
        }
    ]
}

const SPACEBAR_COLOUR_CHOICE_SET: OverrideChoiceSet= {
    choiceName: OverrideChoiceSetName.SPACEBAR_COLOUR,
    overrides: [
        {displayName: OverrideDisplayName.BLUE_SPACEBARS},
        {
            displayName: OverrideDisplayName.WHITE_SPACEBARS,
            keysetOverride: new Map([
                [Key.SPACEBAR_100, Keycap.SPACEBAR_100_ALT],
                [Key.SPACEBAR_125, Keycap.SPACEBAR_125_ALT],
                [Key.SPACEBAR_175, Keycap.SPACEBAR_175_ALT],
                [Key.SPACEBAR_200, Keycap.SPACEBAR_200_ALT],
                [Key.SPACEBAR_225, Keycap.SPACEBAR_225_ALT],
                [Key.SPACEBAR_275, Keycap.SPACEBAR_275_ALT],
                [Key.SPACEBAR_300, Keycap.SPACEBAR_300_ALT],
                [Key.SPACEBAR_600, Keycap.SPACEBAR_600_ALT],
                [Key.SPACEBAR_625, Keycap.SPACEBAR_625_ALT],
                [Key.SPACEBAR_700, Keycap.SPACEBAR_700_ALT],
            ]),
        },
        {
            displayName: OverrideDisplayName.YELLOW_SPACEBARS,
            keysetOverride: new Map([
                // [Key.SPACEBAR_100, Keycap.SPACEBAR_100_ALT],
                // [Key.SPACEBAR_125, Keycap.SPACEBAR_125_ALT],
                // [Key.SPACEBAR_175, Keycap.SPACEBAR_175_ALT],
                [Key.SPACEBAR_200, Keycap.SPACEBAR_200_Y],
                [Key.SPACEBAR_225, Keycap.SPACEBAR_225_Y],
                [Key.SPACEBAR_275, Keycap.SPACEBAR_275_Y],
                // [Key.SPACEBAR_300, Keycap.SPACEBAR_300_ALT],
                // [Key.SPACEBAR_600, Keycap.SPACEBAR_600_ALT],
                [Key.SPACEBAR_625, Keycap.SPACEBAR_625_Y],
                [Key.SPACEBAR_700, Keycap.SPACEBAR_700_Y],
            ]),
        },
    ],
};


// const ALPHA_SPACEBARS_OVERRIDE_TOGGLE: OverrideToggle = {
//     toggleName: OverrideToggleName.ALPHA_SPACEBARS,
//     override: {
//         displayName: OverrideDisplayName.ALPHA_SPACEBARS,
//         keysetOverride: new Map([
//             [Key.SPACEBAR_100, Keycap.SPACEBAR_100_ALT],
//             [Key.SPACEBAR_125, Keycap.SPACEBAR_125_ALT],
//             [Key.SPACEBAR_175, Keycap.SPACEBAR_175_ALT],
//             [Key.SPACEBAR_200, Keycap.SPACEBAR_200_ALT],
//             [Key.SPACEBAR_225, Keycap.SPACEBAR_225_ALT],
//             [Key.SPACEBAR_275, Keycap.SPACEBAR_275_ALT],
//             [Key.SPACEBAR_300, Keycap.SPACEBAR_300_ALT],
//             [Key.SPACEBAR_600, Keycap.SPACEBAR_600_ALT],
//             [Key.SPACEBAR_625, Keycap.SPACEBAR_625_ALT],
//             [Key.SPACEBAR_700, Keycap.SPACEBAR_700_ALT],
//         ]),
//     },
// };

const ALPHA_NUMROW_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.ALPHA_NUMROW,
    override: {
        displayName: OverrideDisplayName.ALPHA_NUMROW,
        keysetOverride: new Map([
            [Key.BACKTICK, Keycap.BACKTICK_W],
            [Key.ALPHA_1, Keycap.ALPHA_1_W],
            [Key.ALPHA_2, Keycap.ALPHA_2_W],
            [Key.ALPHA_3, Keycap.ALPHA_3_W],
            [Key.ALPHA_4, Keycap.ALPHA_4_W],
            [Key.ALPHA_5, Keycap.ALPHA_5_W],
            [Key.ALPHA_6, Keycap.ALPHA_6_W],
            [Key.ALPHA_7, Keycap.ALPHA_7_W],
            [Key.ALPHA_8, Keycap.ALPHA_8_W],
            [Key.ALPHA_9, Keycap.ALPHA_9_W],
            [Key.ALPHA_0, Keycap.ALPHA_0_W],
            [Key.ALPHA_DASH, Keycap.ALPHA_DASH_W],
            [Key.ALPHA_EQUALS, Keycap.ALPHA_EQUALS_W],
            [Key.TSANGAN_PIPE, Keycap.PIPE_W],
            [Key.UK_ISO_BACKTICK, Keycap.UK_ISO_BACKTICK_W],
            [Key.UK_ISO_2, Keycap.UK_ISO_2_W],
            [Key.UK_ISO_3, Keycap.UK_ISO_3_W],
        ]),
    }
}

// once again this is a layout override to interact with mods nicely
// there are ten million of them because this is an awful hack 
// TODO: implement for 65/75/60/alice?
const MOD_NUMROW_TKL_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.MOD_NUMROW,
    override: {
        displayName: OverrideDisplayName.MOD_NUMROW,
        layoutOverride: {
            remove: [Key.BACKSPACE_200],
            add: [
                {key: Key.BACKSPACE_200_ALT, x: 13, y: 1.5},
            ],
        },
    },
};

const MOD_NUMROW_100_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.MOD_NUMROW,
    override: {
        displayName: OverrideDisplayName.MOD_NUMROW,
        layoutOverride: {
            remove: [...MOD_NUMROW_TKL_OVERRIDE_TOGGLE.override.layoutOverride!.remove, Key.NUM_LOCK],
            add: [
                ...MOD_NUMROW_TKL_OVERRIDE_TOGGLE.override.layoutOverride!.add,
                {key: Key.NUM_LOCK_ALT, x: 18.5, y: 1.5},
            ],
        },
        keysetOverride: new Map([
            [Key.DIVIDE, Keycap.DIVIDE_ALT],
            [Key.MULTIPLY, Keycap.MULTIPLY_ALT],
            [Key.NUMPAD_MINUS, Keycap.NUMPAD_MINUS_B1],
        ]),
    },
};

const MOD_NUMROW_ALICE_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.MOD_NUMROW,
    override: {
        displayName: OverrideDisplayName.MOD_NUMROW,
        layoutOverride: {
            remove: [Key.NUMROW_ESC, Key.BACKSPACE_200],
            add: [
                {key: Key.ESC, x: 1, y:0},
                {key: Key.BACKSPACE_200_ALT, x: 16, y: 0},
            ],
        },
    },
};

// note: only works for novelty mods
const ACCENT_ESC_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.ACCENT_ESC,
    override: {
        displayName: OverrideDisplayName.ACCENT_ESC,
        keysetOverride: new Map([
            [Key.ESC, Keycap.TIN_Y_100],
        ]),
    },
};

// note: only works for novelty mods
const ACCENT_BACKSPACE_40_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.ACCENT_BACKSPACE,
    override: {
        displayName: OverrideDisplayName.ACCENT_BACKSPACE,
        keysetOverride: new Map([
            [Key.DELETE_175, Keycap.STORK_Y_175],
            [Key.DELETE_150, Keycap.STORK_Y_150],
        ]),
    },
};

const ALPHA_PIPE_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.ALPHA_PIPE,
    override: {
        displayName: OverrideDisplayName.ALPHA_PIPE,
        keysetOverride: new Map([
            [Key.PIPE_150, Keycap.PIPE_W_150],
        ]),
    },
};

const STEPPED_CAPSLOCK_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.STEPPED_CAPSLOCK,
    override: {
        displayName: OverrideDisplayName.STEPPED_CAPSLOCK,
        // This is a layout override instead of a keyset override to play nicely with novelty/text mod override
        layoutOverride: {
            remove: [Key.CAPSLOCK_175],
            add: [
                {key: Key.STEPPED_CAPSLOCK_175, x: 0, y: 3.5},
            ],
        }
    },
};

// Same underlying value as above, different displayName for HHKB accuracy
const STEPPED_CONTROL_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.STEPPED_CAPSLOCK,
    override: {
        displayName: OverrideDisplayName.STEPPED_CONTROL,
        layoutOverride: STEPPED_CAPSLOCK_OVERRIDE_TOGGLE.override.layoutOverride,
    }
}

// Same underlying value as above, different layoutOverride for Alice 
const STEPPED_CAPSLOCK_ALICE_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.STEPPED_CAPSLOCK,
    override: {
        displayName: OverrideDisplayName.STEPPED_CAPSLOCK,
        layoutOverride: {
            remove: [Key.CAPSLOCK_175],
            add: [
                {key: Key.STEPPED_CAPSLOCK_175, x: 0.75, y: 2},
            ],
        }
    }
}

const ACCENT_ARROWS_OVERRIDE_TOGGLE: OverrideToggle = {
    toggleName: OverrideToggleName.ACCENT_ARROWS,
    override: {
        displayName: OverrideDisplayName.ACCENT_ARROWS,
        keysetOverride: new Map([
            [Key.ARROW_DOWN, Keycap.ARROW_DOWN_Y],
            [Key.ARROW_LEFT, Keycap.ARROW_LEFT_Y],
            [Key.ARROW_RIGHT, Keycap.ARROW_RIGHT_Y],
            [Key.ARROW_UP, Keycap.ARROW_UP_Y],
            [Key.ARROW_UP_ALT, Keycap.ARROW_UP_Y],
        ]),
    }
}

export const OverrideChoiceSets = {
    ALPHA_LAYOUT_CHOICE_SET,
    PHYSICAL_LAYOUT_CHOICE_SET,
    MODIFIER_CHOICE_SET,
    MODIFIER_CHOICE_SET_NO_MIXED,
    THUMB_CHOICE_SET_ERGO,
    THUMB_CHOICE_SET_LILY,
    PHYSICAL_LAYOUT_CHOICE_SET_MINIVAN,
    MACROS_CHOICE_SET_ALICE,
    SPACEBAR_COLOUR_CHOICE_SET,
}

export const OverrideToggles = {
    // ALPHA_SPACEBARS_OVERRIDE_TOGGLE,
    ALPHA_NUMROW_OVERRIDE_TOGGLE,
    ALPHA_PIPE_OVERRIDE_TOGGLE,
    ACCENT_ESC_OVERRIDE_TOGGLE,
    ACCENT_BACKSPACE_40_OVERRIDE_TOGGLE,
    STEPPED_CAPSLOCK_OVERRIDE_TOGGLE,
    STEPPED_CONTROL_OVERRIDE_TOGGLE,
    STEPPED_CAPSLOCK_ALICE_OVERRIDE_TOGGLE,
    MOD_NUMROW_TKL_OVERRIDE_TOGGLE,
    MOD_NUMROW_100_OVERRIDE_TOGGLE,
    MOD_NUMROW_ALICE_OVERRIDE_TOGGLE,
    ACCENT_ARROWS_OVERRIDE_TOGGLE,
}
