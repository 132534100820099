import React from 'react';

export function Header({name}: {name: string}) {
  return <h2 className='innerHeader' id={name}>{name}</h2>
}

export function Link({url, children}: {url: string, children: React.ReactNode}) {
  return <a href={url} target="_blank" rel="noopener noreferrer">{children}</a>
}

export function ColourWarning() {
    return (
      <p className="mb2">
        <strong>Note:</strong> the colours shown are only an approximation, and will vary depending on your browser, display, and lighting. 
      </p>
    );
}