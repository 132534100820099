import React, {useState, useEffect} from 'react';
import { KEYBOARD_LAYOUTS } from './data/keyboard_layouts';
import { KitName } from "./data/kits";
import { OverrideChoiceSets, OverrideToggles, OverrideChoiceSetName, OverrideToggleName, Override, OverrideDisplayName, OverrideChoiceSet, OverrideToggle } from './data/overrides';
import { KeyboardLayoutRenderer } from './keyboard_layout_renderer';
import { preloadAllImages } from './data/keycap_data';
import './tailor.css';
import '../shared.css';

// todo: move this stuff to utils or something
function getStartingChoiceSetValues(): Map<OverrideChoiceSetName, OverrideDisplayName> {
    // having this be a map to index might be better instead of using the display name...ehhh
    const choiceSetValues: Map<OverrideChoiceSetName, OverrideDisplayName>= new Map();
    for (const choiceSet of Object.values(OverrideChoiceSets)) {
        // first is default option
        choiceSetValues.set(choiceSet.choiceName, choiceSet.overrides[0].displayName);
    }
    return choiceSetValues;
}

function getStartingToggleValues(): Map<OverrideToggleName, boolean> {
    const toggleValues: Map<OverrideToggleName, boolean>= new Map();
    for (const toggle of Object.values(OverrideToggles)) {
        toggleValues.set(toggle.toggleName, false);
    }
    return toggleValues;
}

// inefficient lookups but w/e, O(1) :^)
// guess making a lookup something would be good
function getActiveChoiceSetOverride(activeOverrideDisplayName: OverrideDisplayName, choiceSet: OverrideChoiceSet): Override {
    const activeOverride = choiceSet.overrides.find(override => override.displayName === activeOverrideDisplayName)
    if (!activeOverride) {
        // missing: default to the first one (e.g. when switching from TKL mixed to HHKB)
        return choiceSet.overrides[0];
    }
    return activeOverride;
}

// TODO: move these into separate files

type TailorSectionProps = {
    name: string;
    children: React.ReactNode;
}
function TailorSection({name, children}: TailorSectionProps) {
    return (
        <div className='tailorSection'>
            <div className='tailorSectionName'>
                {name}
            </div>
            <div className='tailorSectionContent'>
                {children}
            </div>
        </div>
    )
}

type SelectableItemProps = {
    text: string;
    onClick: () => void;
    isCurrentlySelected: boolean;
    className?: string;
}
function SelectableItem({text, onClick, isCurrentlySelected, className}: SelectableItemProps) {
    // TODO: use classNames instead
    let classNames = 'paddedItem selectableItem';
    if (isCurrentlySelected) {
        classNames += ' selectableItemSelected';
    }
    if (classNames) {
        classNames += ' ' + className;
    }
    return <button className={classNames} onClick={onClick}>{text}</button> 
}

type LayoutSectionProps = {
    currentLayoutIndex: number;
    setCurrentLayoutIndex: (index: number) => void;
}
function LayoutSection({currentLayoutIndex, setCurrentLayoutIndex}: LayoutSectionProps) {
    return (
        <TailorSection name="Keyboard">
            {KEYBOARD_LAYOUTS.map((keyboardLayout, i) => {
                return (
                    <SelectableItem 
                        text={keyboardLayout.name} 
                        onClick={() => setCurrentLayoutIndex(i)}
                        isCurrentlySelected={i === currentLayoutIndex}
                        className="mr1 mb1"
                        key={`${keyboardLayout.name}-${i}`} 
                    />
                );
            })}
        </TailorSection>
    );
}

type ChoiceSetRendererProps = {
    choiceSet: OverrideChoiceSet,
    currentlySelectedChoice: OverrideDisplayName,
    onUpdateChoice: (displayName: OverrideDisplayName) => void,
}
function ChoiceSetRenderer({choiceSet, currentlySelectedChoice, onUpdateChoice}: ChoiceSetRendererProps) {
    return (
        <div className="choiceSetRenderer mr2 mb1">
            {choiceSet.overrides.map((override, i) => {
                return (
                    <SelectableItem 
                        text={override.displayName} 
                        onClick={() => onUpdateChoice(override.displayName)}
                        isCurrentlySelected={override.displayName === currentlySelectedChoice}
                        key={`${override.displayName}-${i}`} 
                    />
                );
            })}
        </div>
    )
}

type ToggleRendererProps = {
    displayName: string,
    currentValue: boolean,
    onToggle: () => void,
}
function ToggleRenderer({displayName, currentValue, onToggle}: ToggleRendererProps) {
    // TODO: use classNames instead
    let toggleBoxClassNames = 'toggleBox mr1';
    if (currentValue) {
        toggleBoxClassNames += ' toggleBoxChecked';
    }
    // TODO: native checkbox with label instead?
    return (
        <button className="paddedItem toggleRenderer mr2 mb1" onClick={onToggle}>
            <div className={toggleBoxClassNames} />
            {displayName}
        </button>
    );
}

type OptionsSectionProps = {
    choiceSetValues: Map<OverrideChoiceSetName, OverrideDisplayName>,
    setChoiceSetValues: (newValue: Map<OverrideChoiceSetName, OverrideDisplayName>) => void,
    layoutChoiceSets: Array<OverrideChoiceSet>,
    toggleValues: Map<OverrideToggleName, boolean>,
    setToggleValues: (newValue: Map<OverrideToggleName, boolean>) => void, 
    layoutToggles: Array<OverrideToggle>,
}
function OptionsSection({choiceSetValues, setChoiceSetValues, layoutChoiceSets, toggleValues, setToggleValues, layoutToggles}: OptionsSectionProps) {
    return (
        <TailorSection name="Options">
            {layoutChoiceSets.map((choiceSet, i) => {
                return (
                    <ChoiceSetRenderer 
                        choiceSet={choiceSet}
                        // note: not resilient to empty state 
                        currentlySelectedChoice={choiceSetValues.get(choiceSet.choiceName)!}
                        onUpdateChoice={(displayName: OverrideDisplayName) => {
                            const newChoiceSetValues = new Map(choiceSetValues);
                            newChoiceSetValues.set(choiceSet.choiceName, displayName);
                            setChoiceSetValues(newChoiceSetValues);
                        }}
                        key={`${choiceSet.choiceName}-${i}`}
                    />
                )
            })}
            {layoutToggles.map((toggle, i) => {
                return (
                    <ToggleRenderer 
                        displayName={toggle.override.displayName}
                        currentValue={toggleValues.get(toggle.toggleName)!}
                        onToggle={() => {
                            const newToggleValues = new Map(toggleValues);
                            newToggleValues.set(toggle.toggleName, !(newToggleValues.get(toggle.toggleName)!))
                            setToggleValues(newToggleValues);
                        }}
                        key={`${toggle.override}-${i}`}
                    />
                )
            })}
        </TailorSection>
    )
}


type KitsSectionProps = {
    requiredKits: Set<KitName>;
}
function KitsSection({requiredKits}: KitsSectionProps) {
    const kitDisplayNamesInOrder = [];
    for (const k of Object.values(KitName)) {
        // ??? 
        if (requiredKits.has(k as KitName)) {
            kitDisplayNamesInOrder.push(k);
        }
    }

    return (
        <TailorSection name="Kits needed">
            <div className="kitsNeededContent">
                {kitDisplayNamesInOrder.map((kitDisplayName, i) => {
                    return (
                        <div className="paddedItem inlineBlock mr1" key={`%${kitDisplayName}-${i}`}>{kitDisplayName}</div>
                    )
                })}
            </div>
        </TailorSection>
    )
}

export function Tailor() {
    const [currentLayoutIndex, setCurrentLayoutIndex] = useState(0);
    // Initialise to default state so we don't have to worry about falling back to defaults 
    const [choiceSetValues, setChoiceStateValues] = useState(getStartingChoiceSetValues());
    const [toggleValues, setToggleValues] = useState(getStartingToggleValues());

    const currentLayout = KEYBOARD_LAYOUTS[currentLayoutIndex];

    // Hack: for actually rendering options and calculating kits, populate a new map with
    // default values since its easier than computing whether the current value is valid or not
    // elsewhere. Technically this means getStartingChoiceSetValues is no longer strictly required.
    // (though would need to update OptionsSection) 
    const choiceSetValuesWithDefaults: Map<OverrideChoiceSetName, OverrideDisplayName> = new Map();

    const activeOverrides: Array<Override> = [];
    // Only add ones that are valid for current layout 
    for (const choiceSet of currentLayout.overrideChoiceSets) {
        const currentValue = choiceSetValues.get(choiceSet.choiceName);
        const activeOverride = getActiveChoiceSetOverride(currentValue!, choiceSet);
        activeOverrides.push(activeOverride);
        choiceSetValuesWithDefaults.set(choiceSet.choiceName, activeOverride.displayName);
    }
    for (const toggle of currentLayout.overrideToggles) {
        const currentValue = toggleValues.get(toggle.toggleName) 
        if (currentValue) {
            activeOverrides.push(toggle.override);
        }
    }
    // activeOverrides.push(OverrideChoiceSets.MODIFIER_CHOICE_SET.overrides[1])

    // Preload images only once.
    useEffect(() => {
        console.log('preloading images');
        preloadAllImages();
    }, []);

    return (
        <div className="mb1">
            <div 
                className="greyWrapper" 
                // match main padding
                style={{marginBottom: 32}}
            >
                <LayoutSection currentLayoutIndex={currentLayoutIndex} setCurrentLayoutIndex={setCurrentLayoutIndex} />
                <div className="tailorDivider" />
                <OptionsSection 
                    choiceSetValues={choiceSetValuesWithDefaults} 
                    setChoiceSetValues={setChoiceStateValues} 
                    layoutChoiceSets={currentLayout.overrideChoiceSets}
                    toggleValues={toggleValues}
                    setToggleValues={setToggleValues}
                    layoutToggles={currentLayout.overrideToggles}
                />
                <div className="tailorDivider" />
                <KitsSection requiredKits={currentLayout.getKits(choiceSetValuesWithDefaults, toggleValues)} />
            </div>
            <KeyboardLayoutRenderer 
                keyboardLayout={currentLayout} 
                overrides={activeOverrides}
            />
        </div>
    )
}